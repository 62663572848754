import { actionTypesTag } from "app/actions/tag";

export type State = Readonly<{
  status: boolean;
}>;

const initialState: State = {
   status: false,
};

const tagState = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypesTag.HIDE_TAG:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};

export default tagState;
