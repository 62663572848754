import { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "./SingleClient.module.scss";
import {
  PencilSimple,
  ArrowLeft,
  MagnifyingGlass,
  Plus,
  CaretDown,
  CircleWavyCheck,
  Archive,
} from "@phosphor-icons/react";

import HeaderColor from "ui/components/header-color/HeaderColor";
import ProjectsList from "clients/components/projects-list/ProjectsList";
import LogsList from "clients/components/logs-list/LogsList";
import ContactsList from "clients/components/contacts-list/ContactsList";
import SidePopup from "ui/components/side-popup/SidePopup";
import Dropdown from "app/components/dropdown/Dropdown";
import CreateClient from "clients/components/add-client/AddClient";

import { CustomersWithNbProjects } from "clients/interfaces/customer";
import { handleGetCustomer } from "clients/controllers/customers";
import { handleGetAllProjects } from "clients/controllers/projects";
import { Project } from "clients/interfaces/project";
import { Filter } from "app/interfaces/filter";
import useDebounce from "hooks/useDebounce";
import { useSelector } from "react-redux";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import LoadingIcon from "ui/icons/LoadingIcon";
import { RootState } from "app/redux/store";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { useWindowSize } from "@uidotdev/usehooks";

const SingleClient = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const { id } = useParams();
  const [active, setActive] = useState("projects");
  const [showEditClient, setShowEditClient] = useState(false);
  const [client, setClient] = useState<CustomersWithNbProjects>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [editClient, setEditClient] = useState(false);
  const [creatingProject, setCreatingProject] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [projectsVisibility, setProjectsVisibility] = useState("actives");
  const [loading, setLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);

  const debouncedSearchValue = useDebounce(searchValue, 300);

  const openDropdown = () => {
    setDropdown(true);
  };

  const handleShowCreateProject = () => {
    setShowCreateProject(true);
  };

  const searchProjects = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const changePage = (newActive: string) => {
    setActive(newActive);
  };

  const changeProjectsVisibility = (newActive: string) => {
    setProjectsVisibility(newActive);
    setDropdown(false);
  };

  const handleShowEditClient = () => {
    setShowEditClient(true);
  };

  const handleUpdateClient = (client: any) => {
    // console.log(client);
    setClient(client);
  };

  const renderLinks = useCallback(() => {
    if (active === "projects") {
      return (
        <>
          <p className={styles.activePage}>Projets</p>
          <button
            onClick={() => changePage("logs")}
            className={styles.inactivePage}
          >
            Identifiants
          </button>
          <button
            onClick={() => changePage("customers")}
            className={styles.inactivePage}
          >
            Contacts
          </button>
        </>
      );
    } else if (active === "logs") {
      return (
        <>
          <button
            onClick={() => changePage("projects")}
            className={styles.inactivePage}
          >
            Projets
          </button>
          <p className={styles.activePage}>Identifiants</p>
          <button
            onClick={() => changePage("contacts")}
            className={styles.inactivePage}
          >
            Contacts
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            onClick={() => changePage("projects")}
            className={styles.inactivePage}
          >
            Projets
          </button>
          <button
            onClick={() => changePage("logs")}
            className={styles.inactivePage}
          >
            Identifiants
          </button>
          <p className={styles.activePage}>Contacts</p>
        </>
      );
    }
  }, [active]);

  const renderContent = useMemo(() => {
    if (active === "projects" && client) {
      return (
        <ProjectsList
          customerId={id}
          projects={projects}
          setCreatingProject={setCreatingProject}
          showCreateProject={showCreateProject}
          setShowCreateProject={setShowCreateProject}
          projectsVisibility={projectsVisibility}
        >
          {client.nbProjects >= 0 && (
            <>
              <div className="m-search m-search--white">
                <input
                  type={"text"}
                  placeholder={
                    width && width > 768 ? "Rechercher un projet" : "Rechercher"
                  }
                  onChange={searchProjects}
                  value={searchValue}
                />
                <MagnifyingGlass weight="bold" />
              </div>
              <div className={styles.headerButtons}>
                <div className={styles.dropdownContainer}>
                  <button
                    data-cy="open-dropdown-projects-visibility"
                    onClick={openDropdown}
                    className="m-button m-button--white"
                  >
                    {projectsVisibility === "actives"
                      ? "Projets actifs"
                      : "Projets archivés"}
                    <CaretDown />
                  </button>
                  {dropdown && (
                    <div className={styles.dropdown}>
                      <Dropdown setIsOpen={setDropdown}>
                        <ul className={styles.projectList}>
                          <li>
                            <button
                              onClick={() =>
                                changeProjectsVisibility("actives")
                              }
                              className={styles.dropdownItem}
                            >
                              <CircleWavyCheck weight="fill" />
                              Projets actifs
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() =>
                                changeProjectsVisibility("inactives")
                              }
                              className={styles.dropdownItem}
                              data-cy="dropdown-projects-visibility-inactives"
                            >
                              <Archive weight="fill" />
                              Projets archivés
                            </button>
                          </li>
                        </ul>
                      </Dropdown>
                    </div>
                  )}
                </div>
                {isAllowed && (
                  <button
                    onClick={handleShowCreateProject}
                    className="m-button m-button--black"
                  >
                    <Plus />
                    {width && width > 768 ? "Ajouter un projet" : "Ajouter"}
                  </button>
                )}
              </div>
            </>
          )}
        </ProjectsList>
      );
    } else if (active === "logs") {
      return (
        client?.accounts && (
          <LogsList
            setEditClient={setEditClient}
            projectsList={projects}
            accounts={client.accounts}
            onUpdateClient={handleUpdateClient}
          />
        )
      );
    } else {
      return (
        client?.contacts && (
          <ContactsList
            setEditClient={setEditClient}
            contacts={client.contacts}
          />
        )
      );
    }
  }, [
    active,
    client,
    dropdown,
    id,
    projects,
    projectsVisibility,
    searchValue,
    showCreateProject,
    user,
  ]);

  useEffect(() => {
    handleGetCustomer(Number(id))
      .then((res) => {
        setClient(res.data);
        setShowEditClient(false);
        if (editClient) {
          setEditClient(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [id, dispatch]);

  useEffect(() => {
    let filterActive = undefined;
    if (projectsVisibility === "actives") {
      filterActive = true;
    } else if (projectsVisibility === "inactives") {
      filterActive = false;
    }
    const filter: Filter = {
      customerId: id,
      active: filterActive,
    };
    if (debouncedSearchValue) {
      filter.search = debouncedSearchValue;
      handleGetAllProjects(filter)
        .then((res) => {
          setProjects(res.data);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    } else {
      handleGetAllProjects(filter)
        .then((res) => {
          setProjects(res.data);
          if (creatingProject) {
            setCreatingProject(false);
          }
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [debouncedSearchValue, id, projectsVisibility, creatingProject, dispatch]);

  return (
    <ProfilLayout>
      <div className="layout-container">
        {loading ? (
          <div className={styles.loading}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {client && (
              <HeaderColor color={client.color}>
                <div className={styles.headerContainer}>
                  <div className={styles.headWrapper}>
                    <Link to="/clients" className={styles.back}>
                      <span className="sr-only">
                        Revenir à tous les clients
                      </span>
                      <ArrowLeft weight="bold" />
                    </Link>
                    <h1 className={styles.title}>{client.name}</h1>
                    {isAllowed && (
                      <button
                        onClick={handleShowEditClient}
                        className={styles.edit}
                      >
                        <PencilSimple weight="fill" />
                        {width && width > 768 ? (
                          <p>Modifier le client</p>
                        ) : null}
                      </button>
                    )}
                    {showEditClient && (
                      <SidePopup
                        setIsOpen={setShowEditClient}
                        dataLoaded={true}
                      >
                        <CreateClient
                          setShowClient={setShowEditClient}
                          setCreatingClient={setEditClient}
                          edit={true}
                          data={client}
                        />
                      </SidePopup>
                    )}
                  </div>
                  <div className={styles.subpages}>{renderLinks()}</div>
                </div>
              </HeaderColor>
            )}
            <div className="container">
              <div className={styles.content}>{renderContent}</div>
            </div>
          </>
        )}
      </div>
    </ProfilLayout>
  );
};

export default SingleClient;
