import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: false,
};

export const deleteStoryReload = createSlice({
  name: "delete",
  initialState,
  reducers: {
    setHandleStatusDelete: (state, action) => {
      const { status } = action.payload;
      return {
        ...state,
        status,
      };
    },
    resetHandleStatusDelete: () => initialState,
  },
});

export const { setHandleStatusDelete, resetHandleStatusDelete } =
  deleteStoryReload.actions;
export default deleteStoryReload.reducer;
