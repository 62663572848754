import { Plus } from "@phosphor-icons/react";
import styles from "./FileStory.module.scss";
import File from "../file/File";
import { StoryType } from "story/types";
import { CreateMediaInterface } from "profil/interfaces/media";
import { useState } from "react";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { handleDeleteMediaStory } from "story/controllers/story";

type Props = {
  story: StoryType;
  setStory: (story: StoryType) => void;
};

const FileStory = ({ story, setStory }: Props) => {
  const dispatch = useDispatch();
  const [mediaError, setMediaError] = useState<string | null>(null);

  const uploadMedia = async (event: any) => {
    try {
      setMediaError(null);
      const file = event.target.files[0];
      if (file.size > 4000000) {
        event.target.value = null;
        setMediaError("Le fichier est trop lourd");
        return;
      }
      const file_type = file.name.split(".").pop();
      const type = "profil";
      const name = file.name.split(".").shift();
      const subtype = file.type.split("/").shift() + "s";
      const format = [
        { size: "1920x1080" },
        { size: "205x110" },
        { size: "366x162" },
      ];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        const newMedia: CreateMediaInterface = {
          name: name,
          type: type,
          subtype: subtype === "applications" ? "documents" : subtype,
          fileBase64: base64,
          file_type: file_type,
          format: format,
        };
        if (story.medias) {
          setStory({ ...story, medias: [...story.medias, newMedia] });
        } else {
          setStory({ ...story, medias: [newMedia] });
        }
        event.target.value = null;
      };
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const handleGetMediaDelete = (media: any) => {
    const cloneStory: any = { ...story };

    const newMedias = cloneStory.medias.filter(
      (mediaData: any) => mediaData.id !== media.id
    );
    setStory({ ...story, medias: newMedias });
    const dataMedia = {
      media_id: media.id,
    };
    handleDeleteMediaStory(cloneStory.id, dataMedia);
  };

  return (
    <>
      {story.medias && story.medias.length > 0 ? (
        <ul className={styles.files}>
          {story.medias.map((media, index) => (
            <li key={media.name + index}>
              <File media={media} onDeleteMedia={handleGetMediaDelete} />
            </li>
          ))}
        </ul>
      ) : null}
      {mediaError ? <p className={styles.error}>{mediaError}</p> : null}
      <div className={styles.addFile}>
        <Plus weight="bold" />
        <p>Ajouter un fichier</p>
        <input type="file" onChange={uploadMedia} />
      </div>
    </>
  );
};

export default FileStory;
