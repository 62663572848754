import { ArrowRight } from "@phosphor-icons/react";
import { TimerType } from "timer/types";
import { formatDayHours } from "utils/formatDate";
import styles from "./DateTimer.module.scss";
import { useCallback, useRef, useState } from "react";
import useOutsideClick from "hooks/useOutsideClick";
import UpdateTimer from "../update-timer/UpdateTimer";

type Props = {
  timer: TimerType;
  setAllTimers?: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const DateTimer = ({ timer, setAllTimers }: Props) => {
  const [open, setOpen] = useState(false);
  const datepickerRef = useRef(null);

  const onClickOutside = useCallback(() => {
    setOpen(false)
  }, []);

  useOutsideClick(datepickerRef, onClickOutside);

  const start = formatDayHours(timer.start_at);
  const end = formatDayHours(timer.end_at);

  return (
    <div className={styles.container} ref={datepickerRef}>
      <button className={styles.button} onClick={() => setOpen(true)}>
        <span>{start}</span> <ArrowRight /> <span>{end}</span>
      </button>
      {open && (
        <div className={styles.dropdown} >
          <UpdateTimer
            startDate={timer.start_at}
            endDate={timer.end_at}
            timerId={timer.id}
            setIsOpen={setOpen}
            setAllTimers={setAllTimers}
          />
        </div>
      )}
    </div>
  );
};

export default DateTimer;
