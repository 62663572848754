import Dropdown from "app/components/dropdown/Dropdown";
import styles from "./Task.module.scss";
import { CaretDown, Play, Plus, Stop } from "@phosphor-icons/react";
import {
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { StoryTask, StoryType } from "story/types";
import TaskUsers from "./task-users/TaskUsers";
import { useDispatch } from "react-redux";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { showModal } from "app/actions/modal";
import { areArraysEqual } from "utils/checkArrays";
import useOutsideClick from "hooks/useOutsideClick";

const statusTypes = {
  todo: {
    color: "#E6ECF4",
    text: "A faire",
    textColor: "#5B5B6D",
  },
  doing: {
    color: "#5568F6",
    text: "En cours",
    textColor: "#FFFFFF",
  },
  done: {
    color: "#A5E125",
    text: "Terminé",
    textColor: "#0F0E13",
  },
};

type Props = {
  storyTask: StoryTask;
  setStory: (story: StoryType) => void;
  story: StoryType;
  closePopup: () => void;
  editing?: boolean;
};

const Task = ({ storyTask, setStory, story, closePopup, editing }: Props) => {
  const kipdevRef = useRef<HTMLTextAreaElement>(null);
  const statusRef = useRef<any>(null);

  const onClickOutside = useCallback(() => {
    setOpenUsersDropdown(false);
  }, []);

  useOutsideClick(statusRef, onClickOutside);
  const dispatch = useDispatch();
  const { currentTimer, setCurrentTimer, setTimerOpen, triggerTimer } =
    useContext(CurrentTimerContext);
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openUsersDropdown, setOpenUsersDropdown] = useState<boolean>(false);

  const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedName = e.target.value;

    // Create a new copy of the current storyTask with the updated name
    const updatedStoryTask: StoryTask | undefined = {
      ...storyTask,
      name: updatedName,
    };

    // Find the index of the current storyTask in the storyTasks array
    const taskIndex = story.storyTasks?.findIndex(
      (task) => task.order === storyTask?.order
    );

    if (taskIndex !== undefined && taskIndex >= 0) {
      // Create a new copy of the storyTasks array with the updated storyTask
      const updatedStoryTasks = [...story.storyTasks];
      updatedStoryTasks[taskIndex] = updatedStoryTask;

      // Create a new copy of the story object with the updated storyTasks
      const updatedStory = {
        ...story,
        storyTasks: updatedStoryTasks,
      };

      // Update the state with the new story object
      setStory(updatedStory);
    }
  };

  const onChangeComplexity = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros from the input value
    const updatedValue = e.target.value.replace(/^0+/, "");

    // Ensure the updated value is a valid number
    const updatedPoints = parseInt(updatedValue, 10);

    // Create a new copy of the current storyTask with the updated points
    const updatedStoryTask: StoryTask | undefined = {
      ...storyTask,
      points: isNaN(updatedPoints) ? 0 : updatedPoints,
    };

    // Find the index of the current storyTask in the storyTasks array
    const taskIndex = story.storyTasks?.findIndex(
      (task) => task.order === storyTask?.order
    );

    if (taskIndex !== undefined && taskIndex >= 0) {
      // Create a new copy of the storyTasks array with the updated storyTask
      const updatedStoryTasks = [...story.storyTasks];
      updatedStoryTasks[taskIndex] = updatedStoryTask;

      // Create a new copy of the story object with the updated storyTasks
      const updatedStory = {
        ...story,
        storyTasks: updatedStoryTasks,
      };

      // Update the state with the new story object
      setStory(updatedStory);
    }
  };

  const selectStatus = (status: "todo" | "doing" | "done") => {
    // Find the currentStoryTask in the storyTasks array
    const currentStoryTask: StoryTask | undefined = story.storyTasks.find(
      (task) => task.order === storyTask.order
    );

    if (!currentStoryTask) return;

    // Create a new copy of the currentStoryTask with the updated status
    const updatedStoryTask: StoryTask = {
      ...currentStoryTask,
      type: status,
    };

    // Find the index of the current storyTask in the storyTasks array
    const taskIndex = story.storyTasks.findIndex(
      (task) => task.order === storyTask.order
    );

    if (taskIndex !== undefined && taskIndex >= 0) {
      // Create a new copy of the storyTasks array with the updated storyTask
      const updatedStoryTasks = [...story.storyTasks];
      updatedStoryTasks[taskIndex] = updatedStoryTask;

      // Create a new copy of the story object with the updated storyTasks
      const updatedStory = {
        ...story,
        storyTasks: updatedStoryTasks,
      };

      // Update the state with the new story object
      setStory(updatedStory);
    }

    setOpenStatus(false);
  };

  const createTimer = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setTimerOpen(true);
      if (currentTimer?.end_at === null && currentTimer?.id) {
        setTimerOpen(true);
        const modalData = {
          status: true,
          message: `Vous avez déjà un timer en cours`,
          error: true,
        };
        dispatch(showModal(modalData));
      } else {
        const currentTimerData = {
          customer: story.customer,
          customer_id: story.customer?.id,
          project_id: story.project?.id,
          project: story.project,
          missions: story.missions,
          mission_ids: story.missions?.map((mission) => mission.id),
          name: `#${story.id}: ${story.name}`,
          start_at: "",
          sub_task: storyTask.name,
        };

        closePopup();

        setCurrentTimer(currentTimerData);
        await triggerTimer(currentTimerData);
      }
    },
    [
      closePopup,
      currentTimer?.end_at,
      currentTimer?.id,
      dispatch,
      setCurrentTimer,
      setTimerOpen,
      story,
      storyTask.name,
      triggerTimer,
    ]
  );

  const finishTimer = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (!currentTimer?.id) return;
      await triggerTimer(currentTimer);
    },
    [currentTimer, triggerTimer]
  );

  const renderPicture = () => {
    if (storyTask?.teamUserLink?.media) {
      return (
        <img
          src={`${process.env.REACT_APP_API}${storyTask.teamUserLink.media.url}`}
          alt=""
        />
      );
    } else {
      return <Plus weight="bold" />;
    }
  };

  const renderPlay = useMemo(() => {
    if (!editing) return null;
    let checkMissionsArray = true;
    if (currentTimer?.mission_ids) {
      if (!story.mission_ids && currentTimer.mission_ids.length > 0) {
        checkMissionsArray = false;
      } else {
        checkMissionsArray = areArraysEqual(
          currentTimer.mission_ids,
          story.mission_ids || []
        );
      }
    }

    if (
      currentTimer &&
      (currentTimer.name === story.name ||
        currentTimer.name === `#${story.id}: ${story.name}`) &&
      checkMissionsArray &&
      currentTimer.sub_task === storyTask.name
    ) {
      if (
        (currentTimer.project_id === null && story.project_id === null) ||
        currentTimer.project_id === Number(story.project_id)
      ) {
        if (
          (currentTimer.customer_id === null && story.customer_id === null) ||
          currentTimer.customer_id === Number(story.customer_id)
        ) {
          return (
            <button className={`${styles.pause}`} onClick={finishTimer}>
              <span className="sr-only">Arrêter le timer</span>
              <Stop weight="fill" />
            </button>
          );
        }
      }
    }
    if (!currentTimer?.id) {
      return (
        <button className={styles.play} onClick={createTimer} type="button">
          <span className="sr-only">Démarrer le timer</span>
          <Play weight="fill" />
        </button>
      );
    }
  }, [createTimer, currentTimer, finishTimer, story, storyTask.name, editing]);

  useEffect(() => {
    resizeTextarea();
  }, [storyTask.name]);

  const resizeTextarea = () => {
    const textArea = kipdevRef.current;
    if (!textArea) return;
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <textarea
          className={`${styles.title} ${
            storyTask.type === "done" && storyTask.name !== ""
              ? styles.titleLined
              : ""
          }`}
          placeholder="Nouvelle tâche"
          value={storyTask.name}
          onChange={onChangeName}
          rows={1}
          ref={kipdevRef}
        ></textarea>
        <div className={styles.info}>
          <div className={styles.usersDropdownContainer}>
            <button
              ref={statusRef}
              className={styles.image}
              onClick={() => setOpenUsersDropdown(true)}
              type="button"
            >
              {renderPicture()}
            </button>
            {openUsersDropdown && (
              <div className={styles.statuses}>
                <TaskUsers
                  storyTask={storyTask}
                  story={story}
                  setStory={setStory}
                  setIsOpen={setOpenUsersDropdown}
                />
              </div>
            )}
          </div>
          <div className={styles.statusContainer}>
            <button onClick={() => setOpenStatus(true)} type="button">
              <div
                className={styles.status}
                style={{
                  backgroundColor: statusTypes[storyTask.type].color,
                  color: statusTypes[storyTask.type].textColor,
                }}
              >
                {statusTypes[storyTask.type].text}
                <CaretDown
                  style={{ color: statusTypes[storyTask.type].textColor }}
                  weight="bold"
                />
              </div>
            </button>
            {openStatus && (
              <div className={styles.statuses}>
                <Dropdown
                  setIsOpen={setOpenStatus}
                  padding={false}
                  color={false}
                >
                  <ul className={styles.statusList}>
                    <li>
                      <button
                        type="button"
                        className={styles.actionItem}
                        style={
                          storyTask.type === "todo"
                            ? {
                                backgroundColor:
                                  statusTypes[storyTask.type].color,
                                color: "#000",
                              }
                            : {}
                        }
                        onClick={() => selectStatus("todo")}
                      >
                        A faire
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        style={
                          storyTask.type === "doing"
                            ? {
                                backgroundColor:
                                  statusTypes[storyTask.type].color,
                                color: statusTypes[storyTask.type].textColor,
                              }
                            : {}
                        }
                        onClick={() => selectStatus("doing")}
                        className={styles.actionItem}
                      >
                        En cours
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        style={
                          storyTask.type === "done"
                            ? {
                                backgroundColor:
                                  statusTypes[storyTask.type].color,
                                color: statusTypes[storyTask.type].textColor,
                              }
                            : {}
                        }
                        onClick={() => selectStatus("done")}
                        className={styles.actionItem}
                      >
                        Terminé
                      </button>
                    </li>
                  </ul>
                </Dropdown>
              </div>
            )}
          </div>
          <input
            type="number"
            placeholder="-"
            className={styles.complexity}
            value={storyTask.points !== 0 ? storyTask.points : ""}
            onChange={onChangeComplexity}
            style={
              storyTask.points
                ? {
                    width: `calc(${
                      storyTask.points.toString().length
                    } * 1ch + 22px)`,
                    textAlign: "center",
                  }
                : {}
            }
          />
        </div>
      </div>
      {renderPlay}
    </div>
  );
};

export default Task;
