import Popup from "ui/components/popup/Popup";
import { useCallback, useState } from "react";
import { Mission } from "clients/interfaces/mission";
import AddMissionProjectPopup from "clients/components/add-mission-project-popup/AddMissionProjectPopup";
import { useDispatch } from "react-redux";
import { setHandleStatusModal } from "app/reducers/generalModal";
import { useParams } from "react-router-dom";

type Props = {
  mission: Mission;
  setEditMissions: (value: boolean) => void;
  setModifyingProject: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddMissionProject = ({
  mission,
  setEditMissions,
  setModifyingProject,
}: Props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const { id }: any = useParams();

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const addMissionProject = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        selectedColor: mission.color,
        children: (
          <AddMissionProjectPopup
            mission={mission}
            setPopupOpen={setPopupOpen}
            setEditMissions={setEditMissions}
            setModifyingProject={setModifyingProject}
            id={id}
          />
        ),
        mW: 800,
        title: `Ajouter une mission - ${mission.name}`,
      })
    );
  }, []);

  return (
    <>
      <button
        className="m-button m-button--fit-content m-button--tronc"
        style={{ backgroundColor: mission.color }}
        onClick={addMissionProject}
      >
        <span>{mission.name}</span>
      </button>
    </>
  );
};

export default AddMissionProject;
