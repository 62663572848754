import React, { useState, useRef, useEffect } from "react";
import styles from "./TextInputTrunc.module.scss";

type Props = {
  value: string;
  color: string;
  maxWidth?: number;
  maxWidthInput?: number;
  placeholder?: string;

  onBlur?: (event: React.FocusEvent<HTMLSpanElement>) => void;
};

const TextInputTrunc = ({
  value,
  color,
  maxWidth,
  maxWidthInput,
  placeholder,

  onBlur,
}: Props) => {
  
  const [isEditMode, setIsEditMode] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const [text, setText] = useState(value);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<any>(null);
  

  const handleTagClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsEditMode(true);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLSpanElement>) => {
    setIsEditMode(false);
    onBlur && onBlur(event);
  };

  const handleSpanChange = (event: any) => {
    setText(event.target.value || "");
  };

  useEffect(() => {
    if (buttonRef.current) {
      setIsTextTruncated(
        maxWidth === buttonRef.current.clientWidth && !isEditMode
      );
      return;
    }
    setIsTextTruncated(false);
  }, [isEditMode, maxWidth, buttonRef]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  const renderTagContent = () => {
    if (isEditMode) {
      return (
        <input
          type={"text"}
          onBlur={handleInputBlur}
          onChange={handleSpanChange}
          value={text}
          className={styles.input}
          placeholder={placeholder}
          ref={inputRef}
        />
      );
    } else {
      return (
        <button
          className={isTextTruncated ? styles.tooltip : styles.tag}
          onClick={handleTagClick}
          style={{ color, maxWidth }}
          ref={buttonRef}
        >
          {(value) ? value : <span>Ajouter un texte</span>}
        </button>
      );
    }
  };

  const renderDivClasses = () => {
    if (isTextTruncated) {
      return `${styles.container} ${styles.containerData}`;
    } else {
      return styles.container;
    }
  };

  return (
    <div className={renderDivClasses()} data-text={value}>
      {renderTagContent()}
    </div>
  );
};

export default TextInputTrunc;
