import { TimerType } from "timer/types";
import { useContext, useState } from "react";
import styles from "./StackTimers.module.scss";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import { formatSecondsToHours } from "utils/formatDate";
import SingleTimerStacked from "../single-timer/SingleTimerStacked";
import { Play } from "phosphor-react";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { useDispatch } from "react-redux";
import { showModal } from "app/actions/modal";

type Props = {
  timers: TimerType[];
  timer: TimerType;
  setAllTimers: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const StackTimers = ({ timers, timer, setAllTimers }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const totalTimer = timers.reduce((total, timer) => total + timer.duration, 0);

  const { currentTimer, setCurrentTimer, triggerTimer, setTimerOpen } =
    useContext(CurrentTimerContext);

  const launchTimer = async (e: any) => {
    if (currentTimer?.end_at === null && currentTimer?.id) {
      setTimerOpen(true);
      const modalData = {
        status: true,
        message: `Vous avez déjà un timer en cours`,
        error: true,
      };
      dispatch(showModal(modalData));
    } else {
      const currentTimerData = {
        customer: timer.customer,
        customer_id: timer.customer?.id,
        project_id: timer.project?.id,
        project: timer.project,
        missions: timer.missions,
        mission_ids: timer.missions?.map((mission) => mission.id) || [],
        name: timer.name,
        start_at: "",
        story_id: timer.id,
      };

      setCurrentTimer(currentTimerData);
      await triggerTimer(currentTimerData);
    }
    e.stopPropagation();
    // console.log("coucou !");
  };

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.container} onClick={() => setIsOpen(!isOpen)}>
          <div className={styles.wrapperTitle}>
            <button
              className={styles.stackCount}
              // onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Etendre les timers</span>
              {timers.length}
            </button>
            <p className={styles.name}>{timers[0]?.name}</p>
        
          </div>
          <div className={styles.tags}>
            {timers[0].customer && (
              <ProjectTag
                content={timers[0].customer.name}
                contentColor={timers[0].customer.color}
                project={timers[0].project?.name}
                size="small"
              />
            )}
            {timers[0].missions?.map((mission) => (
              <div
                key={mission.id}
                className={styles.tagMission}
                style={{ backgroundColor: mission.color }}
              >
                <p className={styles.titleOnly}>{mission.name}</p>
              </div>
            ))}
          </div>
          <div className={styles.rightContainer}>
            <p className={styles.duration}>
              {formatSecondsToHours(totalTimer)}
            </p>
            <button className={styles.play} onClick={launchTimer}>
              <span className="sr-only">Lancer le timer</span>
              <Play weight="fill" />
            </button>
          </div>
        </div>
        {isOpen && (
          <>
            {timers.map((timer) => (
              <div className={styles.singleTimer} key={timer.id}>
                <SingleTimerStacked
                  timer={timer}
                  setAllTimers={setAllTimers}
                  color="grey"
                />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default StackTimers;
