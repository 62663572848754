import styles from "./CalendarFilters.module.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { XCircle } from "phosphor-react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { formatDate } from "utils/formatDate";
import { checkDatePeriod } from "utils/checkDatePeriod";
import { handleGetSprintDates } from "sprint/controllers";

registerLocale("fr", fr);

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setIsOpen?: (value: boolean) => void;
};

const CalendarFilters = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setIsOpen,
}: Props) => {
  const [datesSprint, setDatesSprint] = useState(null);

  const handleResetFilter = (e: any) => {
    e.stopPropagation();
    console.log("reset");
    setStartDate(null);
    setEndDate(null);
  };

  const handleSelectCurrentMonth = () => {
    const startdayofmonth = dayjs()
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    const enddayofmonth = dayjs().endOf("month").format("YYYY-MM-DD HH:mm:ss");

    setStartDate(new Date(startdayofmonth));
    setEndDate(new Date(enddayofmonth));

    if (setIsOpen) setIsOpen(false);
  };

  const handleSelectCurrentYear = () => {
    const startdayofyear = dayjs()
      .startOf("year")
      .format("YYYY-MM-DD HH:mm:ss");
    const enddayofyear = dayjs().endOf("year").format("YYYY-MM-DD HH:mm:ss");

    setStartDate(new Date(startdayofyear));
    setEndDate(new Date(enddayofyear));
    console.log(startdayofyear);
    if (setIsOpen) setIsOpen(false);
  };

  const handleSelectLastYear = () => {
    const startDayOfLastYear = dayjs()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY-MM-DD HH:mm:ss");
    const endDayOfLastYear = dayjs()
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD HH:mm:ss");
    setStartDate(new Date(startDayOfLastYear));
    setEndDate(new Date(endDayOfLastYear));

    if (setIsOpen) setIsOpen(false);
  };

  const handleSelectCurrentSprint = () => {
    if (datesSprint) {
      const currentDatesSprint: any = datesSprint[1];
      setStartDate(new Date(currentDatesSprint.start_date));
      setEndDate(new Date(currentDatesSprint.end_date));
    }
    if (setIsOpen) setIsOpen(false);
  };

  const handleSelectPreviousSprint = () => {
    if (datesSprint) {
      const previousDatesSprint: any = datesSprint[2];
      setStartDate(new Date(previousDatesSprint.start_date));
      setEndDate(new Date(previousDatesSprint.end_date));
    }
    if (setIsOpen) setIsOpen(false);
  };

  useEffect(() => {
    checkDatePeriod(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    handleGetSprintDates()
      .then((res) => {
        setDatesSprint(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.filtersWrapper}>
        <div
          className={
            checkDatePeriod(startDate, endDate, datesSprint) === "currentSprint"
              ? "m-button m-button--black"
              : "m-button m-button--grey"
          }
          onClick={handleSelectCurrentSprint}
        >
          <p>Sprint en cours</p>
          {checkDatePeriod(startDate, endDate, datesSprint) ===
            "currentSprint" && (
            <button type="button" onClick={handleResetFilter}>
              <XCircle weight="fill" />
            </button>
          )}
        </div>
        <div
          className={
            checkDatePeriod(startDate, endDate, datesSprint) ===
            "previousSprint"
              ? "m-button m-button--black"
              : "m-button m-button--grey"
          }
          onClick={handleSelectPreviousSprint}
        >
          <p>Sprint précédent</p>
          {checkDatePeriod(startDate, endDate, datesSprint) ===
            "previousSprint" && (
            <button type="button" onClick={handleResetFilter}>
              <XCircle weight="fill" />
            </button>
          )}
        </div>
        <div
          className={
            checkDatePeriod(startDate, endDate) === "month"
              ? "m-button m-button--black"
              : "m-button m-button--grey"
          }
          onClick={handleSelectCurrentMonth}
        >
          <p>Mois en cours</p>
          {checkDatePeriod(startDate, endDate) === "month" && (
            <button type="button" onClick={handleResetFilter}>
              <XCircle weight="fill" />
            </button>
          )}
        </div>
        <div
          className={
            checkDatePeriod(startDate, endDate) === "year"
              ? "m-button m-button--black"
              : "m-button m-button--grey"
          }
          onClick={handleSelectCurrentYear}
        >
          <p>Année en cours</p>
          {checkDatePeriod(startDate, endDate) === "year" && (
            <button type="button" onClick={handleResetFilter}>
              <XCircle weight="fill" />
            </button>
          )}
        </div>
        <div
          className={
            checkDatePeriod(startDate, endDate) === "lastyear"
              ? "m-button m-button--black"
              : "m-button m-button--grey"
          }
          onClick={handleSelectLastYear}
        >
          <p>Année dernière </p>
          {checkDatePeriod(startDate, endDate) === "lastyear" && (
            <button type="button" onClick={handleResetFilter}>
              <XCircle weight="fill" />
            </button>
          )}
        </div>
      </div>
      {/* )}
      /> */}
    </div>
  );
};

export default CalendarFilters;
