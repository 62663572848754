import styles from "./Sprint.module.scss";
import CtaIcon from "ui/components/cta-icon/CtaIcon";
import SprintIcon from "ui/icons/SprintIcon";
import {
  ArrowRight,
  Gear,
  SlidersHorizontal,
  XCircle,
} from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { RootState } from "app/redux/store";
import {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Popup from "ui/components/popup/Popup";
import CreateSprintPopup from "sprint/components/create-sprint-popup/CreateSprintPopup";
import KanbanItem from "sprint/components/kanban-item/KanbanItem";
import { SprintType } from "sprint/types";
import socket from "utils/socket";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { DragDropContext, DragUpdate, DropResult } from "react-beautiful-dnd";
import { handleUpdateStory } from "story/controllers/story";
import {
  handleGetCurrentSprint,
  handleGetSprintState,
} from "sprint/controllers";
import { StoryType } from "story/types";

import Filter from "sprint/components/filter/Filter";
import SprintSettingsPopup from "sprint/components/sprint-settings-popup/SprintSettingsPopup";
import FinishSprint from "sprint/components/finish-sprint/FinishSprint";
import { EditingCurrentSprintContext } from "sprint/contexts/SprintContext";
import { formatDate } from "utils/formatDate";
import ProgressBar from "ui/components/progress-bar/ProgressBar";
import LoadingIcon from "ui/icons/LoadingIcon";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import useLocalStorage from "hooks/useLocalStorage";
import { updateFormatCard } from "sprint/actions/sprints";
import useOutsideClick from "hooks/useOutsideClick";
import { hideTag } from "app/actions/tag";

import { setHandleStatusModal } from "app/reducers/generalModal";

const kanbanItems: {
  title: string;
  type: "todo" | "doing" | "review" | "done";
}[] = [
  { title: "A faire", type: "todo" },
  { title: "En cours", type: "doing" },
  { title: "Review", type: "review" },
  { title: "Terminé", type: "done" },
];

const Sprint = () => {
  const dispatch = useDispatch();
  const [typeCard] = useLocalStorage("typeCard");
  const [tagMissionHidden] = useLocalStorage("tagMissionHidden");

  useEffect(() => {
    dispatch(updateFormatCard(typeCard));
  }, [typeCard, dispatch]);

  useEffect(() => {
    if (tagMissionHidden !== null) {
      dispatch(hideTag(tagMissionHidden));
    }
  }, [tagMissionHidden, dispatch]);

  const user = useSelector((state: RootState) => state.user.user);
  const { editingCurrentSprint, setEditingCurrentSprint } = useContext(
    EditingCurrentSprintContext
  );
  const [createSprint, setCreateSprint] = useState(false);
  const [isCurrentSprint, setIsCurrentSprint] = useState(false);
  const [isSprints, setIsSprints] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [currentSprint, setCurrentSprint] = useState<SprintType | null>(null);
  const [blockSocket, setBlockSocket] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);
  const [filter, setFilter] = useState<{
    type: "user" | "customer";
    value: string;
    id: number;
  } | null>(null);
  const [currentHoveredKanban, setCurrentHoveredKanban] = useState<
    "todo" | "doing" | "review" | "done" | null
  >(null);

  const menuSettingRef = useRef(null);
  const usersRef = useRef(null);

  const onClickOutside = useCallback(() => {
    setActionsOpen(false);
  }, []);

  const onClickOutsideUsers = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  useOutsideClick(menuSettingRef, onClickOutside);

  useOutsideClick(usersRef, onClickOutsideUsers);

  useEffect(() => {
    const userFilter = localStorage.getItem(`user`);
    const customerFilter = localStorage.getItem(`customer`);

    if (userFilter) {
      setFilter(JSON.parse(userFilter));
    }
    if (customerFilter) {
      setFilter(JSON.parse(customerFilter));
    }
  }, [setFilter]);

  const resetFilter = () => {
    setFilter(null);
    localStorage.removeItem("user");
    localStorage.removeItem("customer");
  };

  useEffect(() => {
    handleGetSprintState()
      .then((response) => {
        const { currentSprint, sprints } = response.data;
        setIsCurrentSprint(currentSprint);
        setIsSprints(sprints);
        if (!currentSprint) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [dispatch]);

  useEffect(() => {
    if (editingCurrentSprint) {
      setLoading(true);
      handleGetSprintState()
        .then((response) => {
          const { currentSprint, sprints } = response.data;
          setIsCurrentSprint(currentSprint);
          setIsSprints(sprints);
          setEditingCurrentSprint(false);
          if (!currentSprint) {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  }, [editingCurrentSprint, setEditingCurrentSprint, dispatch]);

  const handleDropdownOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const startDate = currentSprint?.start_date
    ? formatDate(currentSprint?.start_date)
    : null;
  const endDate = currentSprint?.end_date
    ? formatDate(currentSprint?.end_date)
    : null;

  useEffect(() => {
    if (isCurrentSprint) {
      if (filter) {
        let filterData: { [key: string]: string | number } = {};
        if (filter.type === "customer") {
          filterData.customerId = filter.id;
        } else {
          filterData.userId = filter.id;
        }
        handleGetCurrentSprint(filterData)
          .then((res) => {
            setLoading(false);
            setCurrentSprint(res.data);
          })
          .catch((err) => {
            console.log(err);
            const toastData = {
              status: true,
              message:
                errorsAPI[err.response.data.message as keyof typeof errorsAPI],
              error: true,
            };
            dispatch(showModal(toastData));
          });
      } else {
        handleGetCurrentSprint()
          .then((response) => {
            setLoading(false);
            setCurrentSprint(response.data);
          })
          .catch((err) => {
            console.log(err);
            const toastData = {
              status: true,
              message:
                errorsAPI[err.response.data.message as keyof typeof errorsAPI],
              error: true,
            };
            dispatch(showModal(toastData));
          });
      }
    }
  }, [filter, isCurrentSprint, dispatch]);

  useEffect(() => {
    if (!blockSocket) {
      socket.on("updateSprint", async (data) => {
        if (user?.team_selected === data && isCurrentSprint) {
          let filterData: { [key: string]: string | number } = {};
          if (filter) {
            if (filter.type === "customer") {
              filterData.customerId = filter.id;
            } else {
              filterData.userId = filter.id;
            }
          }
          handleGetCurrentSprint(filterData)
            .then((response) => {
              setCurrentSprint(response.data);
            })
            .catch((err) => {
              setCurrentSprint(null);
              const toastData = {
                status: true,
                message:
                  errorsAPI[
                    err.response.data.message as keyof typeof errorsAPI
                  ],
                error: true,
              };
              dispatch(showModal(toastData));
            });
        }
      });

      return () => {
        socket.off("updateSprint");
      };
    } else {
      setTimeout(() => {
        setBlockSocket(false);
      }, 500);
    }
  }, [filter, isCurrentSprint, user?.team_selected, dispatch, blockSocket]);

  const closeCreateSprint = () => {
    setCreateSprint(false);
  };

  const openCreateSprint = () => {
    setCreateSprint(true);
  };

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      setCurrentHoveredKanban(null);
      setBlockSocket(true);
      const { destination, source, draggableId } = result;
      if (!destination) return;
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
        return;

      if (!currentSprint) return;

      const stories: StoryType[] = currentSprint.stories;

      if (!stories) return;
      const story = stories.find(
        (s: StoryType) => s.id === Number(draggableId)
      );
      if (!story) return;

      if (destination.droppableId === source.droppableId) {
        const newStory = { ...story, order: destination.index };

        const storiesType = stories.filter(
          (s: StoryType) =>
            s.type === destination.droppableId && s.id !== Number(draggableId)
        );

        const newStoriesWithOrder = storiesType.map(
          (s: StoryType, index: number) => {
            if (
              source.index < s.order &&
              s.type === destination.droppableId &&
              s.order <= destination.index
            ) {
              return {
                ...s,
                order: s.order - 1,
              };
            } else if (
              source.index > s.order &&
              s.type === destination.droppableId &&
              s.order >= destination.index
            ) {
              return {
                ...s,
                order: s.order + 1,
              };
            } else {
              return s;
            }
          }
        );

        const noStoriesType = stories.filter(
          (s: StoryType) => s.type !== destination.droppableId
        );

        newStoriesWithOrder.splice(destination.index, 0, newStory);

        setCurrentSprint({
          ...currentSprint,
          stories: [...noStoriesType, ...newStoriesWithOrder],
        });
      } else {
        const storiesType = stories.filter(
          (s: StoryType) =>
            s.type === destination.droppableId && s.id !== Number(draggableId)
        );

        story.order = destination.index;
        story.type = destination.droppableId as
          | "todo"
          | "doing"
          | "review"
          | "done";

        const newStoriesWithOrder = storiesType.map(
          (s: StoryType, index: number) => {
            if (
              source.index < s.order &&
              s.type === destination.droppableId &&
              s.order <= destination.index
            ) {
              return {
                ...s,
                order: s.order - 1,
              };
            }
            if (
              source.index > s.order &&
              s.type === destination.droppableId &&
              s.order >= destination.index
            ) {
              return {
                ...s,
                order: s.order + 1,
              };
            }
            return s;
          }
        );

        const noStoriesType = stories.filter(
          (s: StoryType) => s.type !== destination.droppableId
        );

        newStoriesWithOrder.splice(destination.index, 0, story);

        setCurrentSprint({
          ...currentSprint,
          stories: [...noStoriesType, ...newStoriesWithOrder],
        });
      }

      try {
        await handleUpdateStory(Number(draggableId), {
          type: destination.droppableId as "todo" | "doing" | "review" | "done",
          order: destination.index,
          sprint_id: currentSprint?.id,
        });
      } catch (err: any) {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      }
    },
    [currentSprint, dispatch]
  );

  const onDragUpdate = (update: DragUpdate) => {
    setCurrentHoveredKanban(
      update.destination?.droppableId as
        | "todo"
        | "doing"
        | "review"
        | "done"
        | null
    );
  };

  const handleOpenActions = (e: any) => {
    e.stopPropagation();
    setActionsOpen(!actionsOpen);
  };

  const handleCloseSettingsPopup = (e: any) => {
    e.stopPropagation();
    setSettingsOpen(false);
  };

  const createSprintModal = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: <CreateSprintPopup createActive={true} />,
        mW: 472,
        title: "Créer un sprint",
      })
    );
  }, []);

  const renderContent = useMemo(() => {
    if (!isCurrentSprint && !isSprints) {
      return (
        <>
          <div className={styles.content}>
            <CtaIcon
              icon={<SprintIcon />}
              title="Créez un nouveau sprint
            pour commencer"
              subtitle="Configurez votre prochain sprint de travail en indiquant une période et un nom pour commencer !"
              buttonLabel="Créer un sprint"
              onClickButton={createSprintModal}
            />
          </div>
        </>
      );
    } else if (isSprints && !currentSprint) {
      return (
        <div className={styles.content}>
          <CtaIcon
            icon={<SprintIcon />}
            title="Débutez un sprint
          pour gérer votre production"
            subtitle="Un ou plusieurs sprint(s) sont créé(s) 
          mais aucun n’est lancé !"
            buttonLabel="Voir ma planification"
            link="/planification"
          />
        </div>
      );
    } else {
      if (!currentSprint) return null;

      const currentSprintStories = currentSprint.stories;

      return (
        <>
          <div className={styles.kanbanContainer}>
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              <ul className={styles.kanban}>
                {kanbanItems.map((item) => (
                  <li className={styles.kanbanItem} key={item.type}>
                    <KanbanItem
                      title={item.title}
                      sprint_id={currentSprint.id}
                      createStoryType={item.type}
                      currentSprintStories={currentSprintStories.filter(
                        (s) => s.type === item.type
                      )}
                      isHovered={currentHoveredKanban === item.type}
                      typeCard={typeCard}
                    />
                  </li>
                ))}
              </ul>
            </DragDropContext>
          </div>
        </>
      );
    }
  }, [
    isCurrentSprint,
    isSprints,
    currentSprint,
    currentHoveredKanban,
    onDragEnd,
    typeCard,
    createSprint,
  ]);

  const sprintOptions = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <SprintSettingsPopup
            sprint_id={currentSprint?.id}
            start_date={currentSprint?.start_date}
            end_date={currentSprint?.end_date}
            name={currentSprint?.name}
          />
        ),
        mW: 800,
        title: "Réglages du sprint",
      })
    );
  }, []);

  return (
    <ProfilLayout>
      <div className="layout-container">
        {loading ? (
          <div className={styles.loading}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {currentSprint && (
              <div className={styles.progress}>
                <ProgressBar
                  color="#EEC42E"
                  completedPercent={
                    Math.floor(
                      (currentSprint.nbPointsDone * 100) /
                        currentSprint.nbPointsTotal
                    ) || 0
                  }
                  hoverText={`${Math.floor(
                    (currentSprint.nbPointsDone * 100) /
                      currentSprint.nbPointsTotal
                  )}% terminé`}
                  backgroundColor="#f1f4f9"
                />
              </div>
            )}
            <div className={styles.wrapper}>
              <div className={styles.header}>
                {currentSprint && (
                  <>
                    <div className={styles.wrapperTitleDate}>
                      <h1 className={styles.title}>
                        {currentSprint.name || "Mon sprint"}
                      </h1>
                      <p className={styles.date}>
                        {startDate} <ArrowRight /> {endDate}
                      </p>
                    </div>

                    <div className={styles.wrapperOptions}>
                      <div className={styles.headWrapperMobile}>
                        <div
                          ref={usersRef}
                          className={styles.dropdownContainer}
                        >
                          <div className={styles.filterButton}>
                            <button
                              onClick={handleDropdownOpen}
                              className={`m-button m-button--grey ${
                                styles.dropdownButton
                              } ${filter ? styles.filterActive : ""}`}
                            >
                              <SlidersHorizontal weight="bold" />
                              {filter ? filter.value : "Filtrer"}
                            </button>
                            {filter && (
                              <button
                                className={styles.resetFilter}
                                onClick={resetFilter}
                              >
                                <XCircle weight="fill" />
                              </button>
                            )}
                          </div>
                          {dropdownOpen && (
                            <div className={styles.dropdown}>
                              <Filter
                                setFilter={setFilter}
                                setIsOpen={setDropdownOpen}
                                currentSprint={currentSprint}
                              />
                            </div>
                          )}
                        </div>
                        <div className={styles.moreButton}>
                          <button
                            type={"button"}
                            className={"m-button m-button--black"}
                            onClick={handleOpenActions}
                          >
                            <span>...</span>Plus
                          </button>
                        </div>
                      </div>
                      <div
                        className={
                          actionsOpen ? styles.menuSettingsWrapper : ""
                        }
                      >
                        <div
                          ref={menuSettingRef}
                          className={
                            actionsOpen
                              ? styles.menuSettingsActive
                              : styles.menuSettings
                          }
                        >
                          <div className={styles.pointsDone}>
                            <SprintIcon />
                            <p>
                              {currentSprint.nbPointsDone}{" "}
                              {currentSprint.nbPointsDone > 1
                                ? "points réalisés"
                                : "point réalisé"}{" "}
                              sur {currentSprint.nbPointsTotal}
                            </p>
                          </div>
                          <div className={styles.wrapperMenuSettings}>
                            <button
                              className={styles.settings}
                              onClick={sprintOptions}
                            >
                              <Gear weight="fill" />
                              <span>Réglages du sprint</span>
                            </button>
                            <FinishSprint sprint={currentSprint} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {renderContent}
            </div>
          </>
        )}
      </div>
    </ProfilLayout>
  );
};

export default Sprint;
