import styles from "./SingleProject.module.scss";
import { Link } from "react-router-dom";
import {
  Archive,
  ArrowLeft,
  CaretDown,
  FileText,
  Plus,
  Infinity,
} from "@phosphor-icons/react";
import MissionCard from "clients/components/mission-card/MissionCard";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { handleGetCustomer } from "clients/controllers/customers";
import { handleGetProject } from "clients/controllers/projects";
import { Project } from "clients/interfaces/project";
import { Customer } from "clients/interfaces/customer";
import ProjectActions from "clients/components/project-actions/ProjectActions";
import SidePopup from "ui/components/side-popup/SidePopup";
import AddMission from "clients/components/add-mission/AddMission";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { useSelector } from "react-redux";
import { secondsToHours } from "utils/formatTime";
import ProjectTimers from "clients/components/project-timers/ProjectTimers";
import LoadingIcon from "ui/icons/LoadingIcon";
import GenerateReport from "clients/components/generate-report/GenerateReport";
import { Mission } from "clients/interfaces/mission";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import OptionsIcon from "ui/icons/OptionsIcon";
import ProgressBarMain from "ui/components/progress-bar/ProgressBarMain";
import useWindowSize from "hooks/useWindowSize";
import ReportHistory from "clients/components/report-history/ReportHistory";

const SingleProject = () => {
  const dispatch = useDispatch();
  const { customerId, id } = useParams();
  const user = useSelector((state: any) => state.user.user);
  const { width } = useWindowSize();
  const [modifyingProject, setModifyingProject] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCreateMission, setShowCreateMission] = useState(false);
  const [editMissions, setEditMissions] = useState(true);
  const [showGenerateReport, setShowGenerateReport] = useState(false);

  const [showReportHistory, setShowReportHistory] = useState(false);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<Project>();
  const [client, setClient] = useState<Customer>();
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);
  const handleDropdownOpen = () => {
    setDropdownOpen(true);
  };

  const handleShowCreateMission = () => {
    setShowCreateMission(true);
  };

  const handleHideCreateMission = () => {
    setShowCreateMission(false);
  };

  const handleShowGenerateReport = () => {
    setShowGenerateReport(true);
  };

  const handleChangeStatusHistory = (data: boolean) => {
    setShowReportHistory(data);
  };

  useEffect(() => {
    if (id) {
      handleGetProject(Number(id))
        .then((res) => {
          setProject(res.data);
          if (modifyingProject) {
            setModifyingProject(false);
          }
          if (editMissions) {
            setEditMissions(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [id, modifyingProject, editMissions, dispatch]);

  useEffect(() => {
    if (customerId) {
      handleGetCustomer(customerId)
        .then((res) => {
          setClient(res.data);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  }, [customerId, dispatch]);

  return (
    <ProfilLayout>
      <div className="layout-container">
        {loading ? (
          <div className={styles.loading}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {project && client && (
              <div
                className={styles.container}
                style={{ borderTop: `8px solid ${client.color}` }}
              >
                <div className="container">
                  <div className={styles.header}>
                    <div className={styles.headerLeft}>
                      <Link
                        to={`/clients/${customerId}`}
                        className={styles.back}
                      >
                        <span className="sr-only">
                          Revenir à tous les projets
                        </span>
                        <ArrowLeft weight="bold" />
                      </Link>
                      <div className={styles.titleContainer}>
                        <div className={styles.title}>
                          <h1>{project.name}</h1>
                          {!project.active && (
                            <span className={styles.inactive}>
                              <Archive weight="fill" />
                              Archivé
                            </span>
                          )}
                        </div>
                        <p>{client.name}</p>
                      </div>
                    </div>
                    <div className={styles.buttons}>
                      <button
                        onClick={handleDropdownOpen}
                        className="m-button m-button--white"
                      >
                        <OptionsIcon />
                        Autres actions
                        {width && width > 768 ? <CaretDown /> : null}
                      </button>
                      {dropdownOpen && id && customerId && (
                        <ProjectActions
                          project={project}
                          id={id}
                          customerId={customerId}
                          setDropdownOpen={setDropdownOpen}
                          onChangeStatusHistory={handleChangeStatusHistory}
                          setModifyingProject={setModifyingProject}
                        />
                      )}
                      <button
                        className="m-button m-button--black"
                        onClick={handleShowGenerateReport}
                      >
                        <FileText weight="fill" />
                        {width && width > 768
                          ? "Générer un rapport"
                          : "Rapport"}
                      </button>
                    </div>
                  </div>
                  <div className={styles.missions}>
                    <div className={styles.time}>
                      <h2 className={styles.timeTitle}>Temps total</h2>
                      <ProgressBarMain
                        color="#000000"
                        completedPercent={
                          (typeof project.hours === "number" &&
                            typeof project.used_time === "number" &&
                            (project.used_time * 100) /
                              (project.hours * 3600)) ||
                          0
                        }
                      />
                      <div className={styles.timeNumbers}>
                        <p>
                          {project.used_time &&
                            secondsToHours(project.used_time)}{" "}
                          consommées
                          {typeof project.hours === "number" &&
                          typeof project.used_time === "number" &&
                          project.hours * 3600 - project.used_time < 0 ? (
                            <span className={styles.red}>
                              +{" "}
                              {secondsToHours(
                                Math.abs(
                                  project.hours * 3600 - project.used_time
                                )
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        {project.hours === 0 || project.hours === null ? (
                          <Infinity size={20} color="#030507" weight="bold" />
                        ) : (
                          <p>{project.hours}h</p>
                        )}
                      </div>
                    </div>
                    <div className={styles.missionsHeader}>
                      <h2 className={styles.missionsHeaderTitle}>
                        Missions associées
                      </h2>
                      {isAllowed && (
                        <button
                          onClick={handleShowCreateMission}
                          className="m-button"
                        >
                          <Plus weight="bold" />

                          <p>
                            {width && width > 768
                              ? "Ajouter une mission"
                              : "Ajouter"}
                          </p>
                        </button>
                      )}
                    </div>
                    {project.missions && project.missions.length > 0 ? (
                      <>
                        <ul className={styles.missionsList}>
                          {project.missions &&
                            project.missions.map((mission: Mission) => (
                              <li
                                key={mission.id}
                                className={styles.missionsItem}
                              >
                                <MissionCard
                                  editMissions={editMissions}
                                  mission={mission}
                                  setEditMissions={setEditMissions}
                                  setModifyingProject={setModifyingProject}
                                />
                              </li>
                            ))}
                        </ul>
                      </>
                    ) : (
                      <p className={styles.noMission}>
                        Aucune mission n’a été créée sur ce projet
                      </p>
                    )}
                  </div>
                  <ProjectTimers />
                </div>
              </div>
            )}
          </>
        )}
        {showReportHistory && customerId && (
          <SidePopup setIsOpen={setShowReportHistory} dataLoaded={true}>
            <ReportHistory setReportHistory={setShowReportHistory} />
          </SidePopup>
        )}
        {showCreateMission && (
          <SidePopup setIsOpen={setShowCreateMission} dataLoaded={true}>
            <AddMission
              handleHideCreateMission={handleHideCreateMission}
              editMissions={editMissions}
              setEditMissions={setEditMissions}
              setModifyingProject={setModifyingProject}
            />
          </SidePopup>
        )}
        {showGenerateReport && project && (
          <SidePopup setIsOpen={setShowGenerateReport}>
            <GenerateReport
              setGenerateReport={setShowGenerateReport}
              missions={project.missions}
            />
          </SidePopup>
        )}
      </div>
    </ProfilLayout>
  );
};

export default SingleProject;
