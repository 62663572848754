import { UserType } from "users/types";
import styles from "./TaskUsers.module.scss";
import { useEffect, useState } from "react";
import { handleGetUsers } from "users/controllers/users";
import { User } from "@phosphor-icons/react";
import { StoryTask, StoryType } from "story/types";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  storyTask: StoryTask;
  story: StoryType;
  setStory: (story: StoryType) => void;
  setIsOpen: (isOpen: boolean) => void;
};

const TaskUsers = ({ storyTask, story, setStory, setIsOpen }: Props) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState<UserType[]>([]);

  useEffect(() => {
    handleGetUsers()
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [dispatch]);

  const renderPicture = (user: UserType) => {
    if (user.team_user_link?.media?.url) {
      return (
        <img
          src={`${process.env.REACT_APP_API}${user.team_user_link.media.url}`}
          alt=""
        />
      );
    } else {
      return <User />;
    }
  };

  const selectUser = (user: UserType) => {
    console.log(user);
    // Find the currentStoryTask in the storyTasks array
    const currentStoryTask: StoryTask | undefined = story.storyTasks.find(
      (task) => task.order === storyTask.order
    );

    if (!currentStoryTask) return;

    // Create a new copy of the currentStoryTask with the updated user
    const updatedStoryTask: StoryTask = {
      ...currentStoryTask,
      user_id: Number(user.id),
      teamUserLink: user.team_user_link,
    };

    // Find the index of the current storyTask in the storyTasks array
    const taskIndex = story.storyTasks.findIndex(
      (task) => task.order === storyTask.order
    );

    if (taskIndex !== undefined && taskIndex >= 0) {
      // Create a new copy of the storyTasks array with the updated storyTask
      const updatedStoryTasks = [...story.storyTasks];
      updatedStoryTasks[taskIndex] = updatedStoryTask;

      // Create a new copy of the story object with the updated storyTasks
      const updatedStory = {
        ...story,
        storyTasks: updatedStoryTasks,
      };

      // Update the state with the new story object
      setStory(updatedStory);
    }

    setIsOpen(false);
  };

  return (
    <ul className={styles.usersList}>
      {users
        .filter((user) => user.active !== 0)
        .map((user, index: number) => (
          <li key={user.id || index}>
            <button
              type="button"
              className={styles.actionItem}
              onClick={() => selectUser(user)}
            >
              <div className={styles.picture}>{renderPicture(user)}</div>
              {user.team_user_link?.username || user.email}
            </button>
          </li>
        ))}
    </ul>
  );
};

export default TaskUsers;
