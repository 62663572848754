import { useDispatch, useSelector } from "react-redux";
import { X } from "@phosphor-icons/react";

import styles from "./Modal.module.scss";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setHandleStatusModal } from "app/reducers/generalModal";

const Modal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    status,
    children,
    header,
    className,
    selectedColor,
    title,
    hideclose,
  } = useSelector((state: any) => state.generalModal);

  useEffect(() => {
    const handleCloseModal = () => {
      dispatch(setHandleStatusModal({ status: false }));
    };

    const handleRouteChange = () => {
      handleCloseModal();
    };

    const unlisten = () => {
      window.removeEventListener("popstate", handleRouteChange);
    };

    window.addEventListener("popstate", handleRouteChange);

    return unlisten;
  }, [dispatch, location.pathname]);

  if (!status) {
    return null;
  }

  const classNameBox = className
    ? styles.box + " " + styles[className]
    : styles.box;

  function handleClose() {
    dispatch(setHandleStatusModal({ status: false }));
  }

  return (
    <div className={styles.main}>
      <div className={classNameBox} onClick={(e) => e.stopPropagation()}>
        {header && (
          <div
            className="m-popup__header"
            style={{ backgroundColor: selectedColor }}
          >
            {title && <p className={styles.title}>{title}</p>}
            {!hideclose && (
              <button
                aria-label="Close Modal"
                type={"button"}
                onClick={handleClose}
                className="m-popup__header__close"
              >
                <X />
              </button>
            )}
          </div>
        )}

        <div className="m-popup__content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
