import { colors } from "app/constants/colors";
import { useEffect, useState } from "react";
import { Check, X } from "@phosphor-icons/react";
import styles from "./AddMissionPopup.module.scss";
import { CreateMission, Mission } from "clients/interfaces/mission";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { useSelector } from "react-redux";
import {
  handleCreateMission,
  handleUpdateMission,
} from "clients/controllers/missions";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { resetHandleStatusModal } from "app/reducers/generalModal";

type Props = {
  edit?: boolean;
  data?: Mission;
  handleCloseMissionPopup: () => void;
  setEditMissions: (value: boolean) => void;
};

const AddMissionPopup = ({
  edit,
  data,
  handleCloseMissionPopup,
  setEditMissions,
}: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const [name, setName] = useState(data?.name || "");
  const [error, setError] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState(data?.color || "#5568F6");

  const onChangeColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(e.target.value);
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (name === "") {
      setError(true);
    } else {
      const color = selectedColor;

      const mission: CreateMission = {
        name,
        color,
        team_id: user.team_selected,
      };
      if (edit && data?.id) {
        handleUpdateMission(data?.id, mission).then(() => {
          setEditMissions(true);
          dispatch(resetHandleStatusModal());
        });
      } else {
        handleCreateMission(mission)
          .then(() => {
            setEditMissions(true);
            dispatch(resetHandleStatusModal());
          })
          .catch((err) => {
            const toastData = {
              status: true,
              message:
                errorsAPI[err.response.data.message as keyof typeof errorsAPI],
              error: true,
            };
            dispatch(showModal(toastData));
            console.log(err);
          });
      }
    }
  };

  return (
    <ProfilLayout>
      <div
        className={styles.dynamicColorHeader}
        style={{ backgroundColor: selectedColor }}
      >
        <p className={styles.missionTitle}>Créer une nouvelle mission</p>
      </div>
      <div className={styles.mainWrapper} onSubmit={handleSubmit}>
        <div className={styles.form}>
          <div className={styles.input}>
            <label htmlFor="name" className="m-label">
              Nom de la mission <span>*</span>
            </label>
            <input
              id="name"
              type="text"
              placeholder="Nom de la mission"
              name="name"
              data-cy="name-customer"
              className={`${
                error ? "m-input m-input--error " : " m-input m-input--grey"
              } `}
              value={name}
              required={true}
              onChange={onChangeName}
            />
          </div>
          <p className="m-label">
            Couleur <span>*</span>
          </p>
          <div className={styles.input}>
            <ul className={styles.inputs}>
              {colors.map((color) => (
                <li key={color} className="m-radio">
                  <label className="sr-only" htmlFor={color}>
                    Couleur hexadécimale {color}
                  </label>
                  <input
                    type="radio"
                    value={color}
                    checked={color === selectedColor}
                    onChange={onChangeColor}
                    id={color}
                  />
                  <div
                    className="checkmark checkmark--black checkmark--border checkmark--big"
                    style={{ backgroundColor: color }}
                  >
                    <Check weight="bold" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <button
            data-cy="submit-create-customer"
            type="button"
            onClick={handleSubmit}
            className="m-button m-button--black"
          >
            {edit ? "Modifier la mission" : "Créer la mission"}
          </button>
        </div>
      </div>
    </ProfilLayout>
  );
};

export default AddMissionPopup;
