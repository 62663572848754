import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import { Trash, X } from "@phosphor-icons/react";
import { EditingSprintsContext } from "planification/context/EditingSprintsProvider";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { handleDeleteSprint } from "sprint/controllers";
import LoadingIcon from "ui/icons/LoadingIcon";
import { set } from "react-hook-form";

type Props = {
  closePopup: (e: React.MouseEvent) => void;
  sprintNb: string;
  storiesNb: number;
  sprintId: number;
};

const DeletePopup = ({ closePopup, sprintNb, storiesNb, sprintId }: Props) => {
  const dispatch = useDispatch();
  const { setEditingSprints } = useContext(EditingSprintsContext);
  const [loading, setLoading] = useState(false);

  const deleteSprint = async (e: React.MouseEvent) => {
    setLoading(true);
    try {
      await handleDeleteSprint(sprintId);
      setEditingSprints(true);
      setLoading(false);
      const toastData = {
        status: true,
        message: "Le sprint a bien été supprimé",
        error: false,
      };
      dispatch(showModal(toastData));
      closePopup(e);
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  return (
    <div>
      <p className="m-popup__content__text-grey">
        Confirmez-vous la suppression du <b>sprint n°{sprintNb}</b> ? Les{" "}
        <b>{storiesNb} stories</b> qu’il contient seront transférées dans le
        backlog.
      </p>
      <button className="m-button m-button--black" onClick={deleteSprint}>
        <Trash weight="fill" />
        {loading ? <LoadingIcon /> : "Supprimer le sprint"}
      </button>
    </div>
  );
};

export default DeletePopup;
