import { configureStore } from "@reduxjs/toolkit";
import userReducer from "users/reducers/index";
import modalReducer from "app/reducers/modal";
import mediaReducer from "app/reducers/media";
import backlogStoriesReducer from "sprint/reducers/backlogStories";
import sprintsReducer from "sprint/reducers/sprints";
import TypeCardReducer from "sprint/reducers/typeCard";
import TypeTagReducer from "app/reducers/tag";
import { modalSlice } from "app/reducers/generalModal";
import { deleteStoryReload } from "app/reducers/deleteStoryReload";

export const store = configureStore({
  reducer: {
    user: userReducer,
    toast: modalReducer,
    medias: mediaReducer,
    backlogStories: backlogStoriesReducer,
    sprints: sprintsReducer,
    typeCard: TypeCardReducer,
    tagCard: TypeTagReducer,
    generalModal: modalSlice.reducer,
    deleteStoryReload: deleteStoryReload.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
