import { XCircle } from "@phosphor-icons/react";
import styles from "./ProjectTag.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { useSelector } from "react-redux";
import { selectTagCardState } from "app/selector";
import { useDispatch } from "react-redux";
import { hideTag } from "app/actions/tag";

type Props = {
  content: string;
  contentColor: string;
  project?: string;
  size?: "small" | "large";
  onDelete?: Function | null;
  mainTimer?: Boolean | null;
};
const ProjectTag = ({
  content,
  contentColor,
  project,
  size = "small",
  onDelete,
  mainTimer,
}: Props) => {
  const dispatch = useDispatch();

  const { status } = useSelector(selectTagCardState);

  const [tagMissionHidden, setTagmissionHidden] =
    useLocalStorage("tagMissionHidden");

  const handleDelete = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (onDelete) {
        onDelete();
      }
    },
    [onDelete]
  );

  const handleChangeStatusTag = useCallback(
    (e: any) => {
      e.stopPropagation();

      setTagmissionHidden(!status);
      dispatch(hideTag(!status));
    },
    [status, setTagmissionHidden, dispatch]
  );

  const renderDelete = useMemo(() => {
    if (!onDelete) return null;
    return (
      <button
        type="button"
        className={styles.deleteButton}
        onClick={handleDelete}
      >
        <XCircle weight="fill" />
        <span className="sr-only">Supprimer</span>
      </button>
    );
  }, [handleDelete, onDelete]);

  if (project) {
    return (
      <div
        className={`${styles.container} ${size === "large" && styles.large}`}
      >
        <div
          className={`${
            !mainTimer ? styles.titleContainer : styles.titleContainerMainTimer
          }`}
          style={{ backgroundColor: contentColor }}
        >
          <p className={`${!mainTimer ? styles.title : styles.titleMainTimer}`}>
            {content}
          </p>
        </div>
        <div
          className={`${!mainTimer ? styles.project : styles.projectMainTimer}`}
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), ${contentColor}`,
          }}
        >
          <p className={styles.text}>{project}</p>
          {renderDelete}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={
          `${ !mainTimer? styles.titleOnlyContainer : styles.titleOnlyContainerMainTimer} 
           ${size === "large" && styles.large}
           ${(!status) ? styles.hide : ''}
          `
      
      }

        style={{ backgroundColor: contentColor }}
        onClick={handleChangeStatusTag}
      >
        <p className={styles.titleOnly}>{content}</p>

        {renderDelete}
      </div>
    );
  }
};
export default ProjectTag;
