import styles from "./AddContact.module.scss";
import { handleUpdateCustomer } from "clients/controllers/customers";
import { ContactInterface } from "clients/interfaces/contact";
import { useParams } from "react-router-dom";
import { ContactCustomer } from "clients/interfaces/customer";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { X } from "@phosphor-icons/react";

type Props = {
  setEditClient: (value: boolean) => void;
  contacts: ContactInterface[];
  edit?: boolean;
  data?: ContactInterface;
  closePopup: () => void;
};

const AddContact = ({
  setEditClient,
  contacts,
  edit,
  data,
  closePopup,
}: Props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value.replace(/[^0-9+\s]/g, "");
    event.target.value = phone;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget; // Get the form element

    const firsnameInput = form.elements.namedItem(
      "firstname"
    ) as HTMLInputElement;
    const lastnameInput = form.elements.namedItem("name") as HTMLInputElement;
    const functionInput = form.elements.namedItem(
      "function"
    ) as HTMLInputElement;
    const emailInput = form.elements.namedItem("email") as HTMLInputElement;
    const phoneInput = form.elements.namedItem("phone") as HTMLInputElement;

    const contact: ContactInterface = {
      firstName: firsnameInput.value,
      lastName: lastnameInput.value,
      function: functionInput.value,
      email: emailInput.value,
      phone: phoneInput.value !== "" ? phoneInput.value : undefined,
    };
    let contactData: ContactCustomer;
    if (!edit) {
      contactData = {
        contacts: [...contacts, contact],
      };
    } else {
      contactData = {
        contacts: contacts.map((contact: ContactInterface) => {
          if (
            contact.email === data?.email &&
            contact.phone === data?.phone &&
            contact.firstName === data?.firstName &&
            contact.lastName === data?.lastName &&
            contact.function === data?.function
          ) {
            return {
              firstName: firsnameInput.value,
              lastName: lastnameInput.value,
              function: functionInput.value,
              email: emailInput.value,
              phone: phoneInput.value !== "" ? phoneInput.value : undefined,
            };
          } else {
            return contact;
          }
        }),
      };
    }

    handleUpdateCustomer(Number(id), contactData)
      .then(() => {
        setEditClient(true);
        closePopup();
      })
      .catch((err) => {
        console.error(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  };

  const handleClose = () => {
    closePopup();
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit}>
        <div className={styles.header}>
          <p>Ajouter un contact</p>
          <div className={styles.buttons}>
            <button
              data-cy="submit-create-customer"
              type="submit"
              className="m-button m-button--black"
            >
              Enregistrer
            </button>
            <button
              className="m-button m-button--grey"
              type="button"
              onClick={handleClose}
            >
              <span className="sr-only">Fermer l'ajout de mission</span>
              <X weight="bold" />
            </button>
          </div>
        </div>
        <div className={styles.flexInput}>
          <div className={styles.input}>
            <label htmlFor="firstname" className="m-label">
              Prénom<span>*</span>
            </label>
            <input
              type="text"
              id="firstname"
              className="m-input m-input--grey"
              placeholder="Prénom"
              required
              defaultValue={data?.firstName}
            />
          </div>
          <div className={styles.input}>
            <label htmlFor="name" className="m-label">
              Nom<span>*</span>
            </label>
            <input
              type="text"
              id="name"
              className="m-input m-input--grey"
              required
              placeholder="Nom"
              defaultValue={data?.lastName}
            />
          </div>
        </div>
        <div className={styles.input}>
          <label htmlFor="function" className="m-label">
            Fonction
          </label>
          <input
            type="text"
            id="function"
            className="m-input m-input--grey"
            placeholder="Fonction"
            defaultValue={data?.function}
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="email" className="m-label">
            Adresse email<span>*</span>
          </label>
          <input
            type="email"
            id="email"
            className="m-input m-input--grey"
            required
            placeholder="Adresse email"
            defaultValue={data?.email}
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="phone" className="m-label">
            Numéro de téléphone
          </label>
          <input
            type="phone"
            id="phone"
            className="m-input m-input--grey"
            placeholder="Numéro de téléphone"
            defaultValue={data?.phone}
            onChange={handlePhoneChange}
          />
        </div>
      </form>
    </div>
  );
};

export default AddContact;
