import { StoryType } from "story/types";
import styles from "./MissionsStory.module.scss";
import { MagnifyingGlass, Plus, X } from "@phosphor-icons/react";
import { useState, useEffect, useCallback } from "react";
import { Mission } from "clients/interfaces/mission";
import Popup from "ui/components/popup/Popup";
import AddMissionPopup from "clients/components/add-mission/add-mission-popup/AddMissionPopup";
import { handleGetAllMissions } from "clients/controllers/missions";
import useDebounce from "hooks/useDebounce";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import LoadingIcon from "ui/icons/LoadingIcon";
import { setHandleStatusModal } from "app/reducers/generalModal";

type Props = {
  setStory: (story: StoryType) => void;
  close: () => void;
  story: StoryType;
  currentMission: Mission | null;
  setCurrentMission: (mission: Mission | null) => void;
};

const MissionsStory = ({
  setStory,
  close,
  story,
  currentMission,
  setCurrentMission,
}: Props) => {
  const dispatch = useDispatch();
  const [editMissions, setEditMissions] = useState<boolean>(true);
  const [createMissionPopup, setCreateMissionPopup] = useState(false);
  const [missions, setMissions] = useState<Mission[]>([]);
  const [searchMissionValue, setSearchMissionValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const debouncedSearchValue = useDebounce(searchMissionValue, 300);

  const searchMission = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMissionValue(e.target.value);
  };

  const handleCreateMissionPopup = () => {
    setCreateMissionPopup(true);
  };

  const handleCloseMissionPopup = () => {
    setCreateMissionPopup(false);
  };

  const handleSelectMission = (mission: Mission) => {
    if (currentMission) {
      setStory({
        ...story,
        missions: story.missions?.map((m) => {
          if (m.id === currentMission.id) {
            return mission;
          }
          return m;
        }),
      });
      setCurrentMission(null);
      close();
      return;
    }
    if (story.missions) {
      setStory({
        ...story,
        missions: [...story.missions, mission],
      });
    } else {
      setStory({
        ...story,
        missions: [mission],
      });
    }
    close();
  };

  useEffect(() => {
    if (editMissions) {
      const filter = {
        search: debouncedSearchValue,
      };
      handleGetAllMissions(filter)
        .then((res) => {
          setMissions(res.data);
          setEditMissions(false);
          setLoading(false);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [editMissions, debouncedSearchValue, setEditMissions, dispatch]);

  useEffect(() => {
    const filter = {
      search: debouncedSearchValue,
    };
    handleGetAllMissions(filter)
      .then((res) => {
        setMissions(res.data);
        setEditMissions(false);
        setLoading(false);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [debouncedSearchValue, setEditMissions, dispatch]);

  const addMission = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <AddMissionPopup
            setEditMissions={setEditMissions}
            handleCloseMissionPopup={handleCloseMissionPopup}
          />
        ),
        mW: 472,
        title: "Créer une nouvelle mission",
      })
    );
  }, []);

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Ajouter une mission</p>
        <button
          className={`m-button m-button--grey ${styles.close}`}
          onClick={close}
          type="button"
        >
          <span className="sr-only">Fermer l'ajout de mission</span>
          <X weight="bold" />
        </button>
      </div>
      {loading ? (
        <div className={styles.loadicon}>
          <LoadingIcon />
        </div>
      ) : (
        <div className={styles.box}>
          <div className="m-search m-search--white">
            <input
              type={"text"}
              placeholder="Rechercher une mission"
              onChange={searchMission}
              value={searchMissionValue}
            />
            <MagnifyingGlass weight="bold" />
          </div>
          <div className={styles.missions}>
            <button
              className="m-button m-button--black"
              onClick={addMission}
              type="button"
            >
              <Plus weight="bold" />
              Créer une mission
            </button>
            {missions.map((mission: Mission) => {
              if (story.missions?.find((m) => m.id === mission.id)) return null;
              return (
                <button
                  type="button"
                  className="m-button m-button--fit-content m-button--tronc"
                  style={{ backgroundColor: mission.color }}
                  key={mission.id}
                  onClick={() => handleSelectMission(mission)}
                >
                  <span>{mission.name}</span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MissionsStory;
