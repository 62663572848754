import { SprintType } from "sprint/types";
import styles from "./SingleSprint.module.scss";
import SprintOptions from "../sprint-options/SprintOptions";
import { useMemo, useState } from "react";
import { StoryType } from "story/types";
import CardStory from "story/components/card-story/CardStory";
import { Plus } from "@phosphor-icons/react";
import SidePopup from "ui/components/side-popup/SidePopup";
import CreateStory from "story/components/create-story/CreateStory";
import { StrictModeDroppable } from "app/components/strict-mode-droppable/StrictModeDroppable";
import { Draggable } from "react-beautiful-dnd";
import Popup from "ui/components/popup/Popup";
import CancelStoryPopup from "story/components/cancel-story-popup/CancelStoryPopup";

type Props = {
  sprint: SprintType;
  sprintOpen: boolean;
  setSprintOpen: (sprintId: number | null) => void;
  hovered: boolean;
  isSingle?: boolean;
  setCreatedStory?: (value: boolean) => void;
};

const SingleSprint = ({
  sprint,
  sprintOpen,
  setSprintOpen,
  hovered,
  isSingle,
  setCreatedStory,
}: Props) => {
  const [createStoryPopup, setCreateStoryPopup] = useState<boolean>(false);
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const isActive = sprint.active === 1;

  const toggleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (sprintOpen) {
      setSprintOpen(null);
    } else {
      setSprintOpen(sprint.id);
    }
  };

  const showCreateStoryPopup = () => {
    setCreateStoryPopup(true);
  };

  const hideCreateStoryPopup = () => {
    setCancelPopup(false);
    setCreateStoryPopup(false);
  };

  const renderStories = useMemo(() => {
    return sprint.stories.map((story: StoryType, index: number) => (
      <Draggable draggableId={story.id.toString()} index={index} key={story.id}>
        {(provided) => {
          return (
            <CardStory
              key={story.id}
              story={story}
              showPlay={false}
              sprint_id={sprint.id}
              provided={provided}
            />
          );
        }}
      </Draggable>
    ));
  }, [sprint.id, sprint.stories]);

  const sprintPoints = useMemo(() => {
    let points = 0;

    sprint.stories.forEach((story: StoryType) => {
      if (story.points) points += story.points;
      if (story.storyTasks) {
        story.storyTasks.forEach((task) => {
          if (task.points) points += task.points;
        });
      }
    });

    return points;
  }, [sprint.stories]);

  return (
    <li
      key={sprint.id}
      className={`${styles.sprint} ${sprintOpen ? styles.open : ""}`}
    >
      <div
        className={`${styles.container} ${sprintOpen ? styles.open : ""} ${
          hovered ? styles.hovered : ""
        }`}
      >
        <div className={styles.sprintInfo}>
          <button className={styles.openSprint} onClick={toggleOpen}>
            <div className="m-top-points">
              <p className="m-top-points__text">
                {sprint.name} - Sprint {sprint.number}
              </p>
              <span className="m-points m-points--fill">
                {sprintPoints || 0}
              </span>
              {isActive && <span className={styles.progress}>En cours</span>}
            </div>
          </button>
          <SprintOptions
            isActive={isActive}
            sprintId={sprint.id}
            sprintNb={sprint.number}
            storiesNb={sprint.stories.length}
            start_date={sprint.start_date}
            end_date={sprint.end_date}
            sprintName={sprint.name}
            isSingle={isSingle}
          />
        </div>
        {sprintOpen && (
          <>
            <StrictModeDroppable droppableId={sprint.id.toString()}>
              {(provided) => {
                return (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`${styles.stories} ${
                      isSingle ? styles.single : ""
                    }`}
                  >
                    {renderStories}
                    {provided.placeholder}
                  </ul>
                );
              }}
            </StrictModeDroppable>
            <button className={styles.addNew} onClick={showCreateStoryPopup}>
              <Plus weight="bold" />
              Ajouter une story
            </button>
            {createStoryPopup && (
              <SidePopup
                setIsOpen={setCreateStoryPopup}
                dataLoaded={true}
                modal={true}
                onClickOutside={() => setCancelPopup(true)}
              >
                <CreateStory
                  sprint_id={sprint.id}
                  type="todo"
                  modal={true}
                  closePopup={hideCreateStoryPopup}
                  setCreatedStory={setCreatedStory}
                />
                {cancelPopup && (
                  <Popup setIsOpen={setCancelPopup} width="496px">
                    <CancelStoryPopup
                      closePopup={hideCreateStoryPopup}
                      setIsOpen={setCancelPopup}
                    />
                  </Popup>
                )}
              </SidePopup>
            )}
          </>
        )}
      </div>
    </li>
  );
};

export default SingleSprint;
