import styles from "./Timer.module.scss";
import CtaIcon from "ui/components/cta-icon/CtaIcon";
import { Timer } from "@phosphor-icons/react";
import { TimerType } from "timer/types";
import { useContext, useEffect, useRef, useState } from "react";
import { handleGetTimerByUser, handleGetTimers } from "timer/controllers/timer";
import AllTimers from "timer/components/all-timers/AllTimers";
import TimerFilters from "timer/components/timer-filters/TimerFilters";
import { formatDateToDay } from "utils/formatDate";
import { EditingTimerContext } from "timer/context/EditingTimerProvider";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import LoadingIcon from "ui/icons/LoadingIcon";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { UserType } from "users/types";
import { RootState } from "app/redux/store";
import { handleAccountMe } from "account/controllers";
import { onUpdateUser } from "users/actions";
import { set } from "react-hook-form";

const TimerPage = () => {
  dayjs.extend(weekday);
  const { editingTimer, setEditingTimer } = useContext(EditingTimerContext);
  const { createdTimer, setCreatedTimer } = useContext(CurrentTimerContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(
    dayjs().weekday(-14).toDate()
  );
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(
    dayjs().toDate()
  );
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [userSelected, setUserSelected] = useState<UserType | null>(null);
  const [allTimers, setAllTimers] = useState<TimerType[]>([]);
  const [defaultLoading, setDefaultLoading] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleAccountMe()
      .then(async function (response: any) {
        dispatch(onUpdateUser(response.data.data));
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [dispatch]);

  useEffect(() => {
    let filter = {};
    if (startDateFilter) {
      let endDate;
      if (endDateFilter) {
        endDate = dayjs(endDateFilter).add(1, "day").toDate();
      } else {
        endDate = dayjs(startDateFilter).add(1, "day").toDate();
      }

      filter = {
        startDate: formatDateToDay(startDateFilter),
        endDate: formatDateToDay(endDate),
      };
      if (user?.role_name === "SUPER_ADMIN") {
        filter = {
          userId: userSelected ? userSelected.id : user.id,
          startDate: formatDateToDay(startDateFilter),
          endDate: formatDateToDay(endDate),
        };
        handleGetTimerByUser(filter)
          .then((res) => {
            setAllTimers(res.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
            setLoadingMore(false);
            setDefaultLoading(false);
          });
      } else {
        handleGetTimers(filter)
          .then((res) => {
            setAllTimers(res.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
            setLoadingMore(false);
            setDefaultLoading(false);
          });
      }
    }
  }, [startDateFilter, endDateFilter, userSelected, dispatch]);

  useEffect(() => {
    if (editingTimer || createdTimer) {
      let filter = {};
      if (startDateFilter && endDateFilter) {
        const endDate: any = dayjs(startDateFilter).add(1, "day").toDate();
        const endDateAddOneDay = dayjs(endDateFilter).add(1, "day").toDate();

        filter = {
          startDate: formatDateToDay(startDateFilter),
          endDate:
            dayjs(startDateFilter).format("YYYYMMDD") ===
            dayjs(endDateFilter).format("YYYYMMDD")
              ? formatDateToDay(endDate)
              : formatDateToDay(endDateAddOneDay),
        };
      } else if (startDateFilter && !endDateFilter) {
        const endDate: any = dayjs(startDateFilter).add(1, "day").toDate();

        filter = {
          startDate: formatDateToDay(startDateFilter),
          endDate: formatDateToDay(endDate),
        };
      }

      handleGetTimers(filter)
        .then((res) => {
          setAllTimers(res.data);
          setEditingTimer(false);
          setLoading(false);
          setCreatedTimer(false);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  }, [
    startDateFilter,
    endDateFilter,
    editingTimer,
    setEditingTimer,
    createdTimer,
    setCreatedTimer,
    dispatch,
  ]);

  const handleViewMore = () => {
    const startDate = dayjs(startDateFilter).subtract(30, "day").toDate();
    setStartDateFilter(startDate);

    setLoadingMore(true);
  };
  return (
    <div className="layout-container">
      <div className={styles.wrapper}>
        <div className="container" ref={containerRef}>
          <div className={styles.header}>
            <h1 className={styles.title}>Timer</h1>
            <div className={styles.filters}>
              <TimerFilters
                userSelected={userSelected}
                setUserSelected={setUserSelected}
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                admin={user?.team_user_link?.role_name === "SUPER_ADMIN"}
              />
            </div>
          </div>
          {defaultLoading ? (
            <div className={styles.defaultLoading}>
              <LoadingIcon />
            </div>
          ) : (
            <>
              {allTimers && allTimers.length > 0 ? (
                <div className={styles.mainContent}>
                  <AllTimers
                    allTimers={allTimers}
                    setAllTimers={setAllTimers}
                  />
                </div>
              ) : (
                <div className={styles.content}>
                  <CtaIcon
                    icon={<Timer weight="fill" />}
                    title="Gérez ici vos temps passés sur chaque story"
                    subtitle="Timez-vous automatiquement sur les stories sur lesquelles vous travaillez ou manuellement à l’aide de l’icône en bas à droite de votre écran"
                  />
                </div>
              )}
              {loading && (
                <div className={styles.loading}>
                  <LoadingIcon />
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.viewMore}>
          <button
            className="m-button m-button--black"
            type="button"
            onClick={handleViewMore}
          >
            {loadingMore && <LoadingIcon />}
            Charger plus
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimerPage;
