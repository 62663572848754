import styles from "./SprintOptions.module.scss";
import {
  ArrowUpRight,
  Lightning,
  LightningSlash,
  Trash,
} from "@phosphor-icons/react";
import Dropdown from "app/components/dropdown/Dropdown";
import { useCallback, useState } from "react";
import Popup from "ui/components/popup/Popup";
import DeletePopup from "./delete-popup/DeletePopup";
import FinishPopup from "./finish-popup/FinishPopup";
import StartPopup from "./start-popup/StartPopup";
import { PencilSimple } from "phosphor-react";
import SprintSettingsPopup from "../sprint-settings-popup/SprintSettingsPopup";
import OptionsIcon from "ui/icons/OptionsIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHandleStatusModal } from "app/reducers/generalModal";

type Props = {
  isActive: boolean;
  sprintNb: string;
  storiesNb: number;
  sprintId: number;
  start_date?: string;
  end_date?: string;
  sprintName: string;
  isSingle?: boolean;
};

const SprintOptions = ({
  isActive,
  sprintNb,
  storiesNb,
  sprintId,
  start_date,
  end_date,
  sprintName,
  isSingle,
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const openOptions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowOptions(true);
  };

  const openUpdateModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowUpdateModal(true);
  };

  const openDeleteModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteModal(false);
  };

  const openFinishModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowFinishModal(true);
  };

  const closeFinishModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowFinishModal(false);
  };

  const openStartModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowStartModal(true);
  };

  const closeStartModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowStartModal(false);
  };

  const startSprint = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: <StartPopup sprintNb={sprintNb} sprintId={sprintId} />,
        mW: 800,
        title: "Commencer le sprint",
      })
    );
  }, []);

  const finishSprint = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <FinishPopup
            closePopup={closeFinishModal}
            sprintNb={sprintNb}
            sprintId={sprintId}
          />
        ),

        mW: 496,
        title: "Terminer le sprint",
      })
    );
  }, []);

  const renderFinishSprint = () => {
    if (isActive) {
      return (
        <>
          <li>
            <button className={styles.dropdownItem} onClick={finishSprint}>
              <LightningSlash weight="fill" />
              Terminer le sprint
            </button>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li>
            <button className={styles.dropdownItem} onClick={startSprint}>
              <Lightning weight="fill" />
              Démarrer le sprint
            </button>
          </li>
        </>
      );
    }
  };

  const redirectSingle = () => {
    navigate(`/planification/sprint/${sprintId}`, { replace: true });
  };

  const displaySprintSettings = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <SprintSettingsPopup
            sprint_id={sprintId}
            start_date={start_date}
            end_date={end_date}
            name={sprintName}
          />
        ),
        mW: 496,
        title: "Réglages du sprint",
      })
    );
  }, []);

  const deleteSprint = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <DeletePopup
            closePopup={closeDeleteModal}
            sprintNb={sprintNb}
            storiesNb={storiesNb}
            sprintId={sprintId}
          />
        ),
        mW: 496,
        title: "Supprimer le sprint",
      })
    );
  }, []);

  return (
    <div className={styles.optionsContainer}>
      {isSingle && isSingle ? null : (
        <>
          <button type="button" onClick={redirectSingle}>
            <ArrowUpRight size={16} color="#5B5B6D" />
          </button>
          <button className={styles.options} onClick={openOptions}>
            <OptionsIcon />
          </button>
        </>
      )}
      {showOptions && (
        <div className={styles.dropdown}>
          <Dropdown setIsOpen={setShowOptions}>
            <ul>
              <li>
                <button
                  className={styles.dropdownItem}
                  onClick={displaySprintSettings}
                >
                  <PencilSimple weight="fill" />
                  Modifier le sprint
                </button>
              </li>
              <li>
                <button className={styles.dropdownItem} onClick={deleteSprint}>
                  <Trash weight="fill" />
                  Supprimer le sprint
                </button>
              </li>

              {renderFinishSprint()}
            </ul>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default SprintOptions;
