import styles from "./ContactsList.module.scss";
import { Plus } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import SidePopup from "ui/components/side-popup/SidePopup";
import AddContact from "../add-contact/AddContact";
import Contact from "../contact/Contact";
import { ContactInterface } from "clients/interfaces/contact";
import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";

type Props = {
  setEditClient: (value: boolean) => void;
  contacts: ContactInterface[];
};

const ContactsList = ({ setEditClient, contacts }: Props) => {
  const { width } = useWindowSize();

  const user = useSelector((state: any) => state.user.user);
  const [createLog, setCreateLog] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);

  const handleCreateLog = () => {
    setCreateLog(true);
  };

  const handleCloseCreateLog = () => {
    setCreateLog(false);
  };

  return (
    <>
      <div className={styles.header}>
        <h2>Contacts</h2>
        {isAllowed && (
          <button
            onClick={handleCreateLog}
            className={`m-button ${
              width && width > 768 ? "" : "m-button--black"
            }`}
          >
            <Plus weight="bold" />
            {width && width > 768 ? "Ajouter un contact" : "Ajouter"}
          </button>
        )}
      </div>
      <div className={styles.overflow}>
        {/* <div className={styles.contactsHead}>
          <p className={styles.nameHead}>Prénom et nom</p>
          <p className={styles.capacityHead}>Fonction</p>
          <p className={styles.mailHead}>Adresse email</p>
          <p className={styles.phoneHead}>Téléphone</p>
        </div> */}
        {contacts.length > 0 ? (
          <ul className={styles.list}>
            {contacts.map((contact, index) => (
              <li key={index}>
                <Contact
                  contacts={contacts}
                  setEditClient={setEditClient}
                  firstName={contact.firstName}
                  lastName={contact.lastName}
                  capacity={contact.function}
                  mail={contact.email}
                  phone={contact.phone}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.noLogs}>
            Aucun contact n'a été ajouté pour ce client.
          </p>
        )}
      </div>
      {createLog && (
        <SidePopup setIsOpen={setCreateLog} dataLoaded={true}>
          <AddContact
            contacts={contacts}
            setEditClient={setEditClient}
            closePopup={handleCloseCreateLog}
          />
        </SidePopup>
      )}
    </>
  );
};

export default ContactsList;
