import { MagnifyingGlass, Plus, X } from "@phosphor-icons/react";
import ReglagesIcon from "ui/icons/ReglagesIcon";
import styles from "./AddMission.module.scss";
import { useState, useEffect, useCallback } from "react";
import { handleGetAllMissions } from "clients/controllers/missions";
import Popup from "ui/components/popup/Popup";
import AddMissionPopup from "./add-mission-popup/AddMissionPopup";
import HandleMissions from "./handle-missions/HandleMissions";
import AddMissionProject from "./add-mission-project/AddMissionProject";
import { Mission } from "clients/interfaces/mission";
import useDebounce from "hooks/useDebounce";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { setHandleStatusModal } from "app/reducers/generalModal";

type Props = {
  editMissions: boolean;
  setEditMissions: (value: boolean) => void;
  handleHideCreateMission: () => void;
  setModifyingProject: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddMission = ({
  handleHideCreateMission,
  editMissions,
  setEditMissions,
  setModifyingProject,
}: Props) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [missions, setMissions] = useState<Mission[]>([]);
  const [createMissionPopup, setCreateMissionPopup] = useState(false);
  const [handleMissions, setHandleMissions] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  const debouncedSearchValue = useDebounce(searchValue, 300);

  const searchMission = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleCreateMissionPopup = () => {
    setCreateMissionPopup(true);
  };

  const handleCloseMissionPopup = () => {
    setCreateMissionPopup(false);
  };

  const changeHandleMissions = () => {
    setHandleMissions(!handleMissions);
  };

  useEffect(() => {
    if (editMissions) {
      const filter = {
        search: debouncedSearchValue,
      };
      handleGetAllMissions(filter)
        .then((res) => {
          setMissions(res.data);
          setEditMissions(false);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  }, [editMissions, debouncedSearchValue, setEditMissions, dispatch]);

  useEffect(() => {
    const filter = {
      search: debouncedSearchValue,
    };
    handleGetAllMissions(filter)
      .then((res) => {
        setMissions(res.data);
        setEditMissions(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [debouncedSearchValue, setEditMissions, dispatch]);

  const AddMission = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <AddMissionPopup
            setEditMissions={setEditMissions}
            handleCloseMissionPopup={handleCloseMissionPopup}
          />
        ),
        mW: 800,
        title: "Créer une nouvelle mission",
      })
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      {handleMissions ? (
        <HandleMissions
          setMissions={setMissions}
          changeHandleMissions={changeHandleMissions}
          setEditMissions={setEditMissions}
          missions={missions}
        />
      ) : (
        <>
          <div className={styles.head}>
            <p className={styles.title}>Ajouter une mission</p>
            <button
              className={`m-button m-button--grey ${styles.settings}`}
              onClick={changeHandleMissions}
            >
              <ReglagesIcon />
            </button>
            <button
              className={`m-button m-button--grey ${styles.close}`}
              onClick={handleHideCreateMission}
            >
              <X weight="bold" />
            </button>
          </div>
          <div className="m-search">
            <input
              type={"text"}
              placeholder="Rechercher"
              onChange={searchMission}
            />
            <MagnifyingGlass weight="bold" />
          </div>
          <div className={styles.missions}>
            <button className="m-button m-button--black" onClick={AddMission}>
              <Plus weight="bold" />
              Créer une mission
            </button>
            {missions.map((mission: Mission) => (
              <AddMissionProject
                mission={mission}
                setEditMissions={setEditMissions}
                setModifyingProject={setModifyingProject}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AddMission;
