import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: false,
  children: null,
  header: true,
  className: "",
  position: "center",
  title: "",
  mW: "",
  selectedColor: "",
  hideclose: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setHandleStatusModal: (state, action) => {
      console.log("action.payload", action.payload);
      const {
        status,
        children,
        header = true,
        className = "",
        selectedColor = "",
        position = "center",
        title = "",
        mW = 520,
        hideclose = false,
      } = action.payload;
      return {
        ...state,
        status,
        children,
        header,
        className,
        selectedColor,
        position,
        title,
        mW,
        hideclose,
      };
    },
    resetHandleStatusModal: () => initialState,
  },
});

export const { setHandleStatusModal, resetHandleStatusModal } =
  modalSlice.actions;
export default modalSlice.reducer;
