import { useCallback, useEffect, useMemo, useState } from "react";
import { errorsAPI } from "app/constants/errors";
import { useParams } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import { useDispatch } from "react-redux";
import ReportHistoryItem from "./report history item/ReportHistoryItem";
import styles from "./ReportHistory.module.scss";
import {
  handleDeleteHistoryReport,
  handleGetHistoryReport,
} from "clients/controllers/reports";

import LoadingIcon from "ui/icons/LoadingIcon";
import { showModal } from "app/actions/modal";
import { X } from "@phosphor-icons/react";

type Props = {
  setReportHistory: (value: boolean) => void;
};

const ReportHistory = ({ setReportHistory }: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [historyData, setHistoryData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;
    handleGetHistoryReport(id)
      .then((res) => {
        let reportHistoryData: any = [];
        res.data.forEach((item: any) => {
          if (!item?.missions || item.missions.length > 0) {
            const startDate = item?.start_at || null;
            const endDate = item?.end_at || null;
            const missions = item?.missions || [];
            const uniqueHash = item?.unique_hash || null;
            reportHistoryData.push({
              id: item.id,
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
              missions,
              uniqueHash,
            });
          }
        });

        setHistoryData(reportHistoryData);
        setLoading(false);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
        setLoading(false);
      });
  }, [dispatch, id]);

  const handleDelete = useCallback(
    (reportId: string) => {
      handleDeleteHistoryReport(reportId)
        .then((deletedId) => {
          const reportFiltered = historyData.filter(
            (x: any) => x.id !== reportId
          );
          setHistoryData(reportFiltered);
          const toastData = {
            status: true,
            message: "Le lien a bien été supprimé",
            error: false,
          };
          dispatch(showModal(toastData));
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    },
    [dispatch, historyData]
  );

  const handleClose = () => {
    setReportHistory(false);
  };

  const renderReports = useMemo(() => {
    return (
      <div>
        {historyData && historyData.length > 0 ? (
          historyData.map((item: any, index: any) => (
            <ReportHistoryItem
              item={item}
              index={index}
              deleteProps={() => handleDelete(item.id)}
              link={item.uniqueHash}
            />
          ))
        ) : (
          <p className={styles.empty}>Votre historique est vide.</p>
        )}
      </div>
    );
  }, [handleDelete, historyData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.header__buttons}>
          <div>
            <h3>Historique des rapports</h3>
            <p>Retrouvez les liens des rapports projets déjà générés</p>
          </div>
          <button
            className="m-button m-button--grey m-button--rounded"
            type="button"
            onClick={handleClose}
          >
            <span className="sr-only">Fermer l'ajout de mission</span>
            <X weight="bold" />
          </button>
        </div>
      </div>
      {loading ? (
        <div className={styles.loader}>
          <LoadingIcon />
        </div>
      ) : (
        <div>{renderReports}</div>
      )}
    </div>
  );
};

export default ReportHistory;
