import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import styles from "./Backlog.module.scss";
import { useEffect, useState } from "react";
import { StoryType } from "story/types";
import { useDispatch, useSelector } from "react-redux";
import { updateFormatCard } from "sprint/actions/sprints";
import useLocalStorage from "hooks/useLocalStorage";
import { handleGetStories, handleUpdateStory } from "story/controllers/story";
import { showModal } from "app/actions/modal";
import Backlog from "sprint/components/backlog/Backlog";
import { DragDropContext, DragUpdate, DropResult } from "react-beautiful-dnd";
import {
  ArrowLeft,
  FadersHorizontal,
  Gear,
  SlidersHorizontal,
} from "@phosphor-icons/react";
import Dropdown from "app/components/dropdown/Dropdown";
import Filter from "sprint/components/filter/Filter";
import SprintSettingsPopup from "sprint/components/sprint-settings-popup/SprintSettingsPopup";
import Popup from "ui/components/popup/Popup";
import { XCircle } from "@phosphor-icons/react";
import { errorsAPI } from "app/constants/errors";
import socket from "utils/socket";
import { RootState } from "app/redux/store";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "ui/icons/LoadingIcon";
import { resetHandleStatusDelete } from "app/reducers/deleteStoryReload";

const BacklogSingle = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [typeCard] = useLocalStorage("typeCard");
  const navigate = useNavigate();
  const [backlogData, setBacklogData] = useState<any>([]);
  const [backlogStories, setBacklogStories] = useState<StoryType[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadingBacklog, setLoadingBacklog] = useState(true);
  const [hovered, setHovered] = useState<string | null>(null);
  const [createdStory, setCreatedStory] = useState<boolean>(false);
  const isStoryDeleted = useSelector(
    (state: RootState) => state.deleteStoryReload.status
  );
  const [filter, setFilter] = useState<{
    type: "user" | "customer";
    value: string;
    id: number;
  } | null>(null);
  const [isSingle] = useState<boolean>(true);

  useEffect(() => {
    const userFilter = localStorage.getItem(`user`);
    const customerFilter = localStorage.getItem(`customer`);

    if (userFilter) {
      setFilter(JSON.parse(userFilter));
    }
    if (customerFilter) {
      setFilter(JSON.parse(customerFilter));
    }
  }, [setFilter]);

  useEffect(() => {
    dispatch(updateFormatCard(typeCard));
  }, [typeCard, dispatch]);

  useEffect(() => {
    const userFilter = localStorage.getItem(`user`);
    const customerFilter = localStorage.getItem(`customer`);
    if (userFilter || customerFilter) {
      let filterData: { [key: string]: string | number } = {};
      if (customerFilter) {
        filterData.customerId = customerFilter && JSON.parse(customerFilter).id;
      } else {
        filterData.userId = userFilter && JSON.parse(userFilter).id;
      }
      handleGetStories(filterData)
        .then((res) => {
          setCreatedStory(false);
          console.log(res);
          setBacklogData(res.data);
          setBacklogStories(res.data.stories);
          setLoadingBacklog(false);
          dispatch(resetHandleStatusDelete());
        })
        .catch((err) => {
          setLoadingBacklog(false);
          dispatch(resetHandleStatusDelete());
          const toastData = {
            status: true,
            message: "Le backlog n'a pas pu être récupéré",
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    } else {
      handleGetStories()
        .then((res) => {
          setCreatedStory(false);
          console.log(res);
          setBacklogData(res.data);
          setBacklogStories(res.data.stories);
          setLoadingBacklog(false);
          dispatch(resetHandleStatusDelete());
        })
        .catch((err) => {
          setLoadingBacklog(false);
          const toastData = {
            status: true,
            message: "Le backlog n'a pas pu être récupéré",
            error: true,
          };
          dispatch(resetHandleStatusDelete());
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [dispatch, filter, createdStory, isStoryDeleted]);

  useEffect(() => {
    socket.on("updateSprint", async (data) => {});

    return () => {
      socket.off("updateSprint");
    };
  }, []);

  const onDragEnd = async (result: DropResult) => {
    setHovered(null);
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let type = "todo";

    if (
      destination.droppableId === "backlog" &&
      source.droppableId === "backlog"
    ) {
      const copyBacklogStories = [...backlogStories];

      const draggedStoryIndex = copyBacklogStories.findIndex(
        (story) => story.id === Number(draggableId)
      );

      if (draggedStoryIndex !== -1) {
        const [draggedStory] = copyBacklogStories.splice(draggedStoryIndex, 1);
        copyBacklogStories.splice(destination.index, 0, draggedStory);

        setBacklogStories(copyBacklogStories);
      }
    } else {
      return null;
    }

    try {
      await handleUpdateStory(Number(draggableId), {
        sprint_id:
          destination.droppableId === "backlog"
            ? 0
            : Number(destination.droppableId),
        planif_order: destination.index,
        type: type as "todo" | "doing" | "review" | "done",
        order: 0,
      });
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const onDragUpdate = (update: DragUpdate) => {
    setHovered(update.destination?.droppableId || null);
  };

  const backButton = () => {
    navigate("/planification", { replace: true });
  };

  const handleDropdownOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const resetFilter = () => {
    setFilter(null);
    localStorage.removeItem("user");
    localStorage.removeItem("customer");
  };

  return (
    <ProfilLayout>
      <div className="layout-container">
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.top}>
              <button
                onClick={backButton}
                type="button"
                className="m-button m-button--black"
              >
                <ArrowLeft size={18} color="#fff" />
              </button>
              <h1>Backlog</h1>
            </div>
            <div className={styles.buttons}>
              <div className={styles.dropdownContainer}>
                <div className={styles.filterButton}>
                  <button
                    onClick={handleDropdownOpen}
                    className={`m-button m-button--grey ${
                      styles.dropdownButton
                    } ${filter ? styles.filterActive : ""}`}
                  >
                    <SlidersHorizontal weight="bold" />
                    {filter ? filter.value : "Filtrer"}
                  </button>
                  {filter && (
                    <button
                      className={styles.resetFilter}
                      onClick={resetFilter}
                    >
                      <XCircle weight="fill" />
                    </button>
                  )}
                </div>
                {dropdownOpen && (
                  <div className={styles.dropdown}>
                    <Dropdown setIsOpen={setDropdownOpen}>
                      <Filter
                        setFilter={setFilter}
                        setIsOpen={setDropdownOpen}
                        currentSprint={backlogData}
                      />
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.storiesWrapper}>
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              {loadingBacklog ? (
                <div className={styles.right}>
                  <div className={styles.loader}>
                    <LoadingIcon />
                  </div>
                </div>
              ) : (
                <Backlog
                  single={isSingle}
                  backlogStories={backlogStories}
                  typeCard={typeCard}
                  setCreatedStory={setCreatedStory}
                />
              )}
            </DragDropContext>
          </div>
        </div>
      </div>
    </ProfilLayout>
  );
};

export default BacklogSingle;
