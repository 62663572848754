import { User } from "@phosphor-icons/react";
import styles from "./UserStorySingle.module.scss";
import { TeamUserLink } from "users/types";

type Props = {
  teamUserLink: TeamUserLink;
  grey?: boolean;
};

const UserStorySingle = ({ teamUserLink, grey = false }: Props) => {
  const renderPicture = () => {
    if (teamUserLink?.media) {
      return (
        <img
          src={`${process.env.REACT_APP_API}medias/profil/images/${teamUserLink.media.url_id}-96x96.jpg`}
          alt=""
        />
      );
    } else {
      return <User />;
    }
  };

  return (
    <div className={`${styles.container} ${grey ? styles.grey : ""}`}>
      <div className={styles.picture}>{renderPicture()}</div>
      <p className={styles.name}>
        {teamUserLink.username || teamUserLink.user_email}
      </p>
    </div>
  );
};

export default UserStorySingle;
