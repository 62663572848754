import styles from "./TimerFilters.module.scss";
import PlanningIcon from "ui/icons/PlanningIcon";
import { useState, useMemo } from "react";
import Dropdown from "app/components/dropdown/Dropdown";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import { formatDate } from "utils/formatDate";
import { ArrowRight, X, XCircle } from "@phosphor-icons/react";
import useWindowSize from "hooks/useWindowSize";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { UserType } from "users/types";
import ProfilIcon from "ui/icons/ProfilIcon";
import UsersDropdown from "clients/components/project-timers/users-dropdown/UsersDropdown";

type Props = {
  userSelected?: UserType | null;
  setUserSelected?: (value: UserType | null) => void;
  startDateFilter: any | null;
  setStartDateFilter: (value: Date | null) => void;
  endDateFilter: any | null;
  setEndDateFilter: (value: Date | null) => void;
  admin?: boolean;
};

const TimerFilters = ({
  startDateFilter,
  setStartDateFilter,
  endDateFilter,
  setEndDateFilter,
  userSelected,
  setUserSelected,
  admin,
}: Props) => {
  dayjs.extend(weekday);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const { width } = useWindowSize();
  const [personsDropdownOpen, setPersonsDropdownOpen] = useState(false);

  const renderCondition = () => {
    if (
      dayjs(startDateFilter).format("YYYY-MM-DD") ===
        dayjs().weekday(-14).format("YYYY-MM-DD") &&
      dayjs(endDateFilter).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderDates = useMemo(() => {
    if (renderCondition()) return <p>Filter par date</p>;

    if (startDateFilter && endDateFilter) {
      const startDate = formatDate(startDateFilter);
      const endDate = formatDate(endDateFilter);

      return (
        <>
          <p className={styles.startDate}>{startDate || "Choisir une date"}</p>
          <ArrowRight className={styles.arrow} />
          <p className={styles.endDate}>{endDate || "Choisir une date"}</p>
        </>
      );
    } else if (startDateFilter && !endDateFilter) {
      const startDate = formatDate(startDateFilter);
      return (
        <>
          <p className={styles.startDate}>{startDate || "Choisir une date"}</p>
        </>
      );
    }
  }, [endDateFilter, startDateFilter]);

  const resetFilters = () => {
    setStartDateFilter(dayjs().weekday(-14).toDate());
    setEndDateFilter(dayjs().toDate());
  };

  const handlePersonsDropdownOpen = () => {
    setPersonsDropdownOpen(true);
  };

  const onUserClick = (user: UserType) => {
    if (setUserSelected) {
      setUserSelected(user);
      setPersonsDropdownOpen(false);
    }
  };

  const resetUserFilter = () => {
    if (setUserSelected) {
      setUserSelected(null);
    }
  };

  return (
    <div className={styles.container}>
      <div className={`m-button m-button--grey ${styles.buttonContainer}`}>
        <button
          className={startDateFilter ? styles.button : styles.noDate}
          onClick={() => setCalendarOpen(true)}
        >
          <PlanningIcon className={styles.planningIcon} />
          {renderDates}
        </button>

        <>
          {startDateFilter && !renderCondition() && (
            <button className={styles.resetFilters} onClick={resetFilters}>
              <X weight="bold" />
            </button>
          )}
        </>
      </div>
      {calendarOpen && (
        <div className={styles.calendar}>
          <Dropdown padding={false} setIsOpen={setCalendarOpen}>
            <CalendarDouble
              startDate={startDateFilter}
              setStartDate={setStartDateFilter}
              endDate={endDateFilter}
              setEndDate={setEndDateFilter}
              setIsOpen={setCalendarOpen}
            />
          </Dropdown>
        </div>
      )}
      {admin ? (
        <div className={styles.personsDropdown}>
          <div className={styles.persons}>
            <button
              onClick={handlePersonsDropdownOpen}
              className={styles.selectPerson}
              style={userSelected ? { padding: "0 36px 0 16px" } : {}}
            >
              <ProfilIcon />
              <p>{userSelected?.team_user_link?.username || "Tout le monde"}</p>
            </button>
            {userSelected?.email && (
              <button
                onClick={resetUserFilter}
                className={styles.resetMissionFilter}
              >
                <span className="sr-only">Enlever le filtre d'utilisateur</span>
                <XCircle weight="fill" />
              </button>
            )}
          </div>
          {personsDropdownOpen && (
            <div className={styles.personsDropdownContent}>
              <Dropdown setIsOpen={setPersonsDropdownOpen}>
                <UsersDropdown onClick={onUserClick} />
              </Dropdown>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TimerFilters;
