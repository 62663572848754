import GetIcon from "ui/icons/GetIcon";
import styles from "./File.module.scss";
import { Trash } from "@phosphor-icons/react";

type Props = {
  media: any;
  onDeleteMedia: Function;
};

const File = ({ media, onDeleteMedia }: Props) => {
  const downloadFile = () => {
    const link = document.createElement("a");
    link.download = media.name;
    link.href = `${media.fileBase64}`;
    link.click();
  };

  const downloadUrl = (url: string, name: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = name + "." + url.split(".").pop();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;
        link.click();
      });
  };

  const renderDownload = () => {
    if (!media.url) {
      return (
        <button onClick={downloadFile} type="button">
          <GetIcon />
        </button>
      );
    } else {
      const file = `${process.env.REACT_APP_API}${media.url}`;
      return (
        <button
          onClick={() => downloadUrl(file, media.name)}
          type="button"
          className={styles.button}
        >
          <span className="sr-only">Télécharger le fichier</span>
          <GetIcon />
        </button>
      );
    }
  };

  const handleDeleteMedia = () => {
    onDeleteMedia(media);
  };

  return (
    <div className={styles.wrapper}>
      <p>{media.name}</p>
      <div className={styles.actions}>
        {renderDownload()}
        {media && media.id ? (
          <button
            type={"button"}
            onClick={() => handleDeleteMedia()}
            className={styles.delete}
          >
            <Trash />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default File;
