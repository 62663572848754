export const actionTypesTag = {
  HIDE_TAG: "HIDE_TAG",
};

export const hideTag = (tag: any) => {
  return {
    type: actionTypesTag.HIDE_TAG,
    payload: tag,
  };
};
