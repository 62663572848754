import styles from "./MissionCard.module.scss";
import { ArrowRight, PencilSimple, Infinity } from "@phosphor-icons/react";
import ProgressBar from "ui/components/progress-bar/ProgressBar";
import { useCallback, useEffect, useState } from "react";
import Popup from "ui/components/popup/Popup";
import { Mission } from "clients/interfaces/mission";
import AddMissionProjectPopup from "../add-mission-project-popup/AddMissionProjectPopup";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils/formatDate";
import { secondsToHours } from "utils/formatTime";
import { RootState } from "app/redux/store";
import useWindowSize from "hooks/useWindowSize";
import { setHandleStatusModal } from "app/reducers/generalModal";
import { useParams } from "react-router-dom";

type Props = {
  mission: Mission;
  editMissions?: any;
  setEditMissions?: any;
  setModifyingProject?: any;
};

const MissionCard = ({
  mission,
  setEditMissions,
  setModifyingProject,
}: Props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const [modifiedPopupOpen, setModifiedPopupOpen] = useState(false);
  const { width } = useWindowSize();
  const [isAllowed, setIsAllowed] = useState(false);
  const dispatch = useDispatch();
  const { id }: any = useParams();

  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);

  const handlePopupOpen = () => {
    setModifiedPopupOpen(true);
  };
  const modifyMissionProject = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        selectedColor: mission.color,
        children: (
          <AddMissionProjectPopup
            setPopupOpen={setModifiedPopupOpen}
            mission={mission}
            edit
            data={mission.missionProjectLink}
            setEditMissions={setEditMissions}
            setModifyingProject={setModifyingProject}
            id={id}
          />
        ),
        mW: 800,
        title: mission.name,
      })
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        style={{ backgroundColor: mission?.color }}
        data-testid="color-container"
        className={styles.header}
      >
        <p className={styles.title}>{mission?.name}</p>
        {setEditMissions && setModifyingProject && (
          <>
            {isAllowed && (
              <button
                className={styles.modifButton}
                onClick={modifyMissionProject}
              >
                <PencilSimple weight="fill" />
                <span className="sr-only">Modifier la mission</span>
              </button>
            )}
          </>
        )}
      </div>
      <div className={styles.content}>
        {mission.missionProjectLink &&
          typeof mission.missionProjectLink.time === "number" && (
            <>
              <div className={styles.progress}>
                <ProgressBar
                  color={mission?.color}
                  completedPercent={
                    (mission.missionProjectLink.time &&
                      (mission.missionProjectLink.time * 100) /
                        (mission.missionProjectLink.hours * 3600)) ||
                    0
                  }
                />
              </div>
              <div className={styles.hours}>
                <p>
                  {secondsToHours(
                    (mission.missionProjectLink.time &&
                      mission.missionProjectLink.time) ||
                      0
                  )}{" "}
                  consommées
                  {mission.missionProjectLink.hours !== 0 &&
                  mission.missionProjectLink.hours * 3600 -
                    mission.missionProjectLink.time <
                    0 ? (
                    <span className={styles.red}>
                      +{" "}
                      {secondsToHours(
                        Math.abs(
                          mission.missionProjectLink.hours * 3600 -
                            mission.missionProjectLink.time
                        )
                      )}
                    </span>
                  ) : null}
                </p>
                {width && width > 768 ? (
                  mission.missionProjectLink.hours !== 0 ? (
                    <p>sur {mission.missionProjectLink.hours}h00</p>
                  ) : (
                    <Infinity size={20} color="#030507" weight="bold" />
                  )
                ) : null}
              </div>
              {mission.missionProjectLink.start_date &&
                mission.missionProjectLink.end_date && (
                  <div className={styles.date}>
                    <p>{formatDate(mission.missionProjectLink.start_date)}</p>
                    <ArrowRight />
                    <p>{formatDate(mission.missionProjectLink.end_date)}</p>
                  </div>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default MissionCard;
