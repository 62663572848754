import { LightningSlash, X } from "@phosphor-icons/react";
import { setHandleStatusModal } from "app/reducers/generalModal";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import FinishSprintModal from "sprint/components/finish-sprint/finish-sprint-modal/FinishSprintModal";
import Popup from "ui/components/popup/Popup";

type Props = {
  closePopup: (e: React.MouseEvent) => void;
  sprintNb: string;
  sprintId: number;
};

const FinishPopup = ({ closePopup, sprintNb, sprintId }: Props) => {
  const [finishSprintPopup, setFinishSprintPopup] = useState<boolean>(false);
  const dispatch = useDispatch();

  const openFinishSprintPopup = () => {
    setFinishSprintPopup(true);
  };

  const finishSprint = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: <FinishSprintModal setIsOpen={setFinishSprintPopup} />,
        mW: 496,
        headerr: false,
        title: "Terminer le sprint",
      })
    );
  }, []);

  return (
    <>
      <p className="m-popup__content__text-grey">
        Voulez-vous vraiment terminer le <b>sprint n°{sprintNb}</b> ?
      </p>
      <button className="m-button m-button--black" onClick={finishSprint}>
        <LightningSlash weight="fill" />
        Terminer le sprint
      </button>
    </>
  );
};

export default FinishPopup;
