import styles from "./LogsList.module.scss";
import { Plus } from "@phosphor-icons/react";
import Log from "../log/Log";
import { useEffect, useState } from "react";
import SidePopup from "ui/components/side-popup/SidePopup";
import AddLog from "../add-log/AddLog";
import { AccountInterface } from "clients/interfaces/account";
import { Project } from "clients/interfaces/project";

import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";

type Props = {
  setEditClient: (value: boolean) => void;
  accounts: AccountInterface[];
  projectsList: Project[];
  onUpdateClient?: Function;
};

const LogsList = ({
  setEditClient,
  accounts,
  projectsList,
  onUpdateClient,
}: Props) => {
  const { width } = useWindowSize();
  const user = useSelector((state: any) => state.user.user);
  const [createLog, setCreateLog] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  console.log(accounts);

  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);
  const handleCreateLog = () => {
    setCreateLog(true);
  };
  const handleCloseCreateLog = () => {
    setCreateLog(false);
  };

  const handleUpdateClient = (client: any) => {
    if (onUpdateClient) {
      onUpdateClient(client);
    }
  };

  return (
    <>
      <div className={styles.header}>
        <h2>
          {width && width > 768 ? "Identifiants du client" : "Identifiants"}
        </h2>
        {isAllowed && (
          <button
            onClick={handleCreateLog}
            className={`m-button ${
              width && width > 768 ? "" : "m-button--black"
            }`}
          >
            <Plus weight="bold" />
            {width && width > 768 ? "Ajouter un identifiant" : "Ajouter"}
          </button>
        )}
      </div>
      <div className={styles.overflow}>
        {/* <div className={styles.logsHead}>
          <p className={styles.accountHead}>Compte</p>
          <p className={styles.logHead}>Identifiant</p>
          <p className={styles.passwordHead}>Mot de passe</p>
          <p className={styles.projectHead}>Projets</p>
          <div className={styles.endButtons}></div>
        </div> */}
        {accounts.length > 0 ? (
          <ul className={styles.list}>
            {accounts.map((account, index) => (
              <li key={index}>
                <Log
                  accountName={account.account}
                  identifier={account.username}
                  password={account.password}
                  link={account.link}
                  accounts={accounts}
                  setEditClient={setEditClient}
                  project_id={account.project_id}
                  projectsList={projectsList}
                  onUpdateClient={handleUpdateClient}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.noLogs}>
            Aucun identifiant n'a été ajouté pour ce client.
          </p>
        )}
      </div>
      {createLog && (
        <SidePopup setIsOpen={setCreateLog} dataLoaded={true}>
          <AddLog
            accounts={accounts}
            setEditClient={setEditClient}
            closePopup={handleCloseCreateLog}
            projectsList={projectsList}
            onUpdateClient={handleUpdateClient}
          />
        </SidePopup>
      )}
    </>
  );
};

export default LogsList;
