import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import styles from "./Sprint.module.scss";
import { useEffect, useState } from "react";
import { StoryType } from "story/types";
import { useDispatch, useSelector } from "react-redux";
import { updateFormatCard } from "sprint/actions/sprints";
import useLocalStorage from "hooks/useLocalStorage";
import { handleGetStories, handleUpdateStory } from "story/controllers/story";
import { showModal } from "app/actions/modal";
import Backlog from "sprint/components/backlog/Backlog";
import { DragDropContext, DragUpdate, DropResult } from "react-beautiful-dnd";
import {
  ArrowLeft,
  FadersHorizontal,
  Gear,
  SlidersHorizontal,
} from "@phosphor-icons/react";
import Dropdown from "app/components/dropdown/Dropdown";
import Filter from "sprint/components/filter/Filter";
import SprintSettingsPopup from "sprint/components/sprint-settings-popup/SprintSettingsPopup";
import Popup from "ui/components/popup/Popup";
import { XCircle } from "@phosphor-icons/react";
import { errorsAPI } from "app/constants/errors";
import socket from "utils/socket";
import { RootState } from "app/redux/store";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleGetCurrentSprintByID,
  handleGetSprints,
} from "sprint/controllers";
import { SprintType } from "sprint/types";
import LoadingIcon from "ui/icons/LoadingIcon";
import SprintsList from "sprint/components/sprints-list/SprintsList";
import SingleSprint from "sprint/components/single-sprint/SingleSprint";
import { resetHandleStatusDelete } from "app/reducers/deleteStoryReload";

const SprintSingle = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [typeCard] = useLocalStorage("typeCard");
  const navigate = useNavigate();
  const { sprintId } = useParams<{ sprintId: string }>();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sprints, setSprints] = useState<any>([]);
  const [createSprint, setCreateSprint] = useState(false);
  const [loadingSprint, setLoadingSprint] = useState(true);
  const [hovered, setHovered] = useState<string | null>(null);
  const [isSingle, setIsSingle] = useState<boolean>(true);
  const [createdStory, setCreatedStory] = useState<boolean>(false);
  const isStoryDeleted = useSelector(
    (state: RootState) => state.deleteStoryReload.status
  );

  const [filter, setFilter] = useState<{
    type: "user" | "customer";
    value: string;
    id: number;
  } | null>(null);

  useEffect(() => {
    const userFilter = localStorage.getItem(`user`);
    const customerFilter = localStorage.getItem(`customer`);

    if (userFilter) {
      setFilter(JSON.parse(userFilter));
    }
    if (customerFilter) {
      setFilter(JSON.parse(customerFilter));
    }
  }, [setFilter]);

  const [sprintOpen, setSprintOpen] = useState<number | null>(
    sprintId ? Number(sprintId) : null
  );

  useEffect(() => {
    dispatch(updateFormatCard(typeCard));
  }, [typeCard, dispatch]);

  const fetchDeleted = (sprintId: any) => {
    const userFilter = localStorage.getItem(`user`);
    const customerFilter = localStorage.getItem(`customer`);
    let filterData: { [key: string]: string | number } = {};
    if (userFilter || customerFilter) {
      if (customerFilter) {
        filterData.customerId = customerFilter && JSON.parse(customerFilter).id;
      } else {
        filterData.userId = userFilter && JSON.parse(userFilter).id;
      }
    }

    handleGetCurrentSprintByID(sprintId, filterData)
      .then((res) => {
        setCreatedStory(false);
        setSprints(res.data);
        setLoadingSprint(false);
        dispatch(resetHandleStatusDelete());
      })
      .catch((err) => {
        setLoadingSprint(false);
        const toastData = {
          status: true,
          message: "Les sprints n'ont pas pu être récupérés",
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDeleted(sprintId);
  }, [dispatch, filter, createdStory]);

  useEffect(() => {
    if (isStoryDeleted) {
      fetchDeleted(sprintId);
    }
  }, [isStoryDeleted]);

  useEffect(() => {
    socket.on("updateSprint", async (data) => {});
    return () => {
      socket.off("updateSprint");
    };
  }, []);

  const onDragEnd = async (result: DropResult) => {
    console.log(result);
    setHovered(null);
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let type = "todo";

    const sourceSprint = sprints;
    const destinationSprint = sprints;

    if (!sourceSprint || !destinationSprint) return;

    const sourceStories = sprints.stories;
    const destinationStories = sprints.stories;

    const draggedStoryIndex = sourceSprint.stories.findIndex(
      (story: StoryType) => story.id === Number(draggableId)
    );

    if (draggedStoryIndex !== -1) {
      const updatedSourceStories = [...sourceSprint.stories];
      const updatedDestinationStories = [...destinationSprint.stories];

      const [draggedStory] = updatedSourceStories.splice(draggedStoryIndex, 1);

      if (source.droppableId === destination.droppableId) {
        updatedSourceStories.splice(destination.index, 0, draggedStory);

        const updatedSprints = {
          ...sprints,
          stories: updatedSourceStories,
        };
        setSprints(updatedSprints);
      } else {
        return null;
      }

      try {
        await handleUpdateStory(Number(draggableId), {
          sprint_id:
            destination.droppableId === "backlog"
              ? 0
              : Number(destination.droppableId),
          planif_order: destination.index,
          type: type as "todo" | "doing" | "review" | "done",
          order: 0,
        });
      } catch (err: any) {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      }
    }
  };

  const onDragUpdate = (update: DragUpdate) => {
    setHovered(update.destination?.droppableId || null);
  };

  const backButton = () => {
    navigate("/planification", { replace: true });
  };

  const handleDropdownOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const resetFilter = () => {
    setFilter(null);
    localStorage.removeItem("user");
    localStorage.removeItem("customer");
  };

  return (
    <ProfilLayout>
      <div className="layout-container">
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.top}>
              <button
                onClick={backButton}
                type="button"
                className="m-button m-button--black"
              >
                <ArrowLeft size={18} color="#fff" />
              </button>
              <h1>
                {sprints.name && sprints.name
                  ? `${sprints.name}`
                  : `Sprint ${sprintId}`}
              </h1>
            </div>
            <div className={styles.buttons}>
              <div className={styles.dropdownContainer}>
                <div className={styles.filterButton}>
                  <button
                    onClick={handleDropdownOpen}
                    className={`m-button m-button--grey ${
                      styles.dropdownButton
                    } ${filter ? styles.filterActive : ""}`}
                  >
                    <SlidersHorizontal weight="bold" />
                    {filter ? filter.value : "Filtrer"}
                  </button>
                  {filter && (
                    <button
                      className={styles.resetFilter}
                      onClick={resetFilter}
                    >
                      <XCircle weight="fill" />
                    </button>
                  )}
                </div>
                {dropdownOpen && (
                  <div className={styles.dropdown}>
                    <Dropdown setIsOpen={setDropdownOpen}>
                      <Filter
                        setFilter={setFilter}
                        setIsOpen={setDropdownOpen}
                        currentSprint={sprints}
                      />
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.storiesWrapper}>
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              {loadingSprint ? (
                <div className={styles.right}>
                  <div className={styles.loader}>
                    <LoadingIcon />
                  </div>
                </div>
              ) : (
                <SingleSprint
                  hovered={Number(hovered) === sprints.id}
                  key={sprints.id}
                  sprint={sprints}
                  sprintOpen={sprintOpen === sprints.id}
                  setSprintOpen={setSprintOpen}
                  isSingle={isSingle}
                  setCreatedStory={setCreatedStory}
                />
              )}
            </DragDropContext>
          </div>
        </div>
      </div>
    </ProfilLayout>
  );
};

export default SprintSingle;
