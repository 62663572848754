import { Link } from "react-router-dom";
import styles from "./Historique.module.scss";
import { ArrowLeft, ArrowRight, CaretDown } from "@phosphor-icons/react";
import { SprintType } from "sprint/types";
import { useEffect, useMemo, useState } from "react";
import {
  handleGetAllSprintIds,
  handleGetCurrentSprintByID,
  handleGetSprints,
} from "sprint/controllers";
import Dropdown from "app/components/dropdown/Dropdown";
import CardStory from "story/components/card-story/CardStory";
import { StoryType } from "story/types";
import { formatDate } from "utils/formatDate";
import LoadingIcon from "ui/icons/LoadingIcon";
import { useDispatch } from "react-redux";
import { resetHandleStatusModal } from "app/reducers/generalModal";

const Historique = () => {
  const [sprintSelected, setSprintSelected] = useState<SprintType | null>(null);
  const [sprints, setSprints] = useState<SprintType[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [sprintIds, setSprintIds] = useState<any[]>([]);
  const [sprintSelectedId, setSprintSelectedId] = useState<any | null>(null);
  const dispatch = useDispatch();
  const [selectSprintDropdownOpen, setSelectSprintDropdownOpen] =
    useState(false);

  const handleDropdownOpen = () => {
    setSelectSprintDropdownOpen(true);
  };

  const startDate = formatDate(sprintSelected?.start_date || "");
  const endDate = formatDate(sprintSelected?.end_date || "");

  useEffect(() => {
    setLoading(true);
    try {
      console.log(sprintSelectedId, "test");
      handleGetCurrentSprintByID(sprintSelectedId).then((sprint) => {
        setSprintSelected(sprint.data);
        setLoading(false);
        console.log(sprint);
      });
    } catch (err) {
      console.error(err);
    }
  }, [sprintIds, sprintSelectedId]);

  useEffect(() => {
    dispatch(resetHandleStatusModal());
    try {
      handleGetAllSprintIds().then((sprintsIds) => {
        setSprintIds(sprintsIds.data);
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  const renderStories = useMemo(() => {
    return (
      <ul className={styles.stories}>
        {loading ? (
          <div className={styles.LoadingIcon}>
            <LoadingIcon />
          </div>
        ) : (
          sprintSelected?.stories &&
          sprintSelected.stories.map((story: StoryType) => (
            <CardStory
              key={story.id}
              story={story}
              showPlay={false}
              canEdit={true}
            />
          ))
        )}
      </ul>
    );
  }, [loading, sprintSelected]);

  return (
    <div className="layout-container">
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.header}>
            <Link to="/sprint" className={`m-button m-button--black`}>
              <span className="sr-only">Revenir au sprint</span>
              <ArrowLeft weight="bold" />
            </Link>
            <h1 className={styles.title}>Historique des sprints</h1>
          </div>
          <div className={styles.container}>
            <div className={styles.sprintHead}>
              <div className={styles.dropdownContainer}>
                <button
                  onClick={handleDropdownOpen}
                  className="m-button m-button--white"
                >
                  {sprintSelectedId === null
                    ? `Selectionne un sprint pour afficher les stories`
                    : `Sprint ${sprintSelectedId}`}

                  <CaretDown />
                </button>
                {selectSprintDropdownOpen && (
                  <div className={styles.dropdown}>
                    <Dropdown setIsOpen={setSelectSprintDropdownOpen}>
                      <ul className={styles.sprintsList}>
                        {sprintIds
                          .slice()
                          .reverse()
                          .map((sprintId) => {
                            return (
                              <li key={sprintId}>
                                <button
                                  className={styles.actionItem}
                                  onClick={() => {
                                    setSprintSelectedId(sprintId);
                                    setSelectSprintDropdownOpen(false);
                                  }}
                                >
                                  Sprint {sprintId}
                                </button>
                              </li>
                            );
                          })}
                      </ul>
                    </Dropdown>
                  </div>
                )}
              </div>
              {sprintSelected !== null && (
                <p className={styles.date}>
                  {startDate} <ArrowRight weight="bold" /> {endDate}
                </p>
              )}
            </div>
            <ul className={styles.statsList}>
              <li className={`${styles.statsItem} ${styles.statsItem__double}`}>
                <p className={styles.statsData}>
                  {sprintSelected?.total_points || 0}
                </p>
                <p className={styles.statsInfo}>points planifiés</p>
              </li>
              <li className={`${styles.statsItem} ${styles.statsItem__double}`}>
                <p className={styles.statsData}>
                  {sprintSelected?.total_points_finish || 0}
                </p>
                <p className={styles.statsInfo}>points réalisés</p>
              </li>
              <li className={styles.statsItem}>
                <p className={styles.statsData}>
                  {sprintSelected?.story_finish || 0}
                </p>
                <p className={styles.statsInfo}>stories terminées</p>
              </li>
            </ul>
            {renderStories}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historique;
