import dayjs from "dayjs";

export const checkDatePeriod = (start: any, end: any, datesSprint?: any) => {
  let startFormatted;
  let endtFormatted;

  if (start) {
    startFormatted = dayjs(start).format("YYYY-MM-DD");
  }
  if (end) {
    endtFormatted = dayjs(end).format("YYYY-MM-DD");
  }

  let startDayOfCurrentSprint;
  let endDayOfCurrentSprint;
  let startDayOfPreviousSprint;
  let endDayOfPreviousSprint;

  if (datesSprint) {
    startDayOfCurrentSprint = dayjs(datesSprint[1].start_date).format(
      "YYYY-MM-DD"
    );
    endDayOfCurrentSprint = dayjs(datesSprint[1].end_date).format("YYYY-MM-DD");
    startDayOfPreviousSprint = dayjs(datesSprint[2].start_date).format(
      "YYYY-MM-DD"
    );
    endDayOfPreviousSprint = dayjs(datesSprint[2].end_date).format(
      "YYYY-MM-DD"
    );
  }

  const startdayofmonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const enddayofmonth = dayjs().endOf("month").format("YYYY-MM-DD");

  const startdayofyear = dayjs().startOf("year").format("YYYY-MM-DD");
  const enddayofyear = dayjs().endOf("year").format("YYYY-MM-DD");

  const startDayOfLastYear = dayjs()
    .subtract(1, "year")
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDayOfLastYear = dayjs()
    .subtract(1, "year")
    .endOf("year")
    .format("YYYY-MM-DD");

  switch (true) {
    case startFormatted === startdayofmonth && endtFormatted === enddayofmonth:
      return "month";
    case startFormatted === startdayofyear && endtFormatted === enddayofyear:
      return "year";
    case startFormatted === startDayOfLastYear &&
      endtFormatted === endDayOfLastYear:
      return "lastyear";
    case startFormatted === startDayOfCurrentSprint &&
      endtFormatted === endDayOfCurrentSprint:
      return "currentSprint";
    case startFormatted === startDayOfPreviousSprint &&
      endtFormatted === endDayOfPreviousSprint:
      return "previousSprint";
    default:
      // Si aucune des conditions ci-dessus n'est remplie
      return "autre";
  }
};
