import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Member from "settings/components/member/Member";
import HeaderColor from "ui/components/header-color/HeaderColor";
import {
  handleGetUsers,
  handleCreateTeamUserLink,
} from "users/controllers/users";
import styles from "./Settings.module.scss";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

const Settings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const [users, setUsers] = useState([]);
  const [modifyUsers, setModifyUsers] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isRoleAllowed, setIsRoleAllowed] = useState(false);

  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);
  useEffect(() => {
    if (user.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);

  const handleAddUser = (e: any) => {
    e.preventDefault();
    const user = {
      user_id: e.target.email.value,
      role_name: "USER",
      active: true,
    };
    handleCreateTeamUserLink(user)
      .then((res) => {
        e.target.email.value = "";
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  };

  useEffect(() => {
    if (modifyUsers) {
      handleGetUsers()
        .then((res) => {
          setUsers(res.data);
          setModifyUsers(false);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log("error");
        });
    }
  }, [modifyUsers, dispatch]);

  const renderLinks = () => {
    return (
      <>
        <p className={styles.activePage}>Espace de travail</p>
      </>
    );
  };

  return (
    <ProfilLayout>
      <div className="layout-container">
        <HeaderColor color="#151419">
          <div className={styles.headerContainer}>
            <div className={styles.headWrapper}>
              <h1 className={styles.title}>Réglages</h1>
            </div>
            <div className={styles.subpages}>{renderLinks()}</div>
          </div>
        </HeaderColor>
        <div className={styles.wrapper}>
          <div className="container">
            <h2 className={styles.subtitle}>Membres de l'équipe</h2>
            {isAllowed && (
              <form onSubmit={handleAddUser}>
                <div className={styles.inputContainer}>
                  <input
                    id="email"
                    type="text"
                    className={styles.mailInput}
                    placeholder="Saisissez l’adresse email des membres que vous souhaitez inviter à l’espace de production"
                  />
                  <button className="m-button m-button--black">Envoyer</button>
                </div>
              </form>
            )}
            <div className={styles.memberHead}>
              <p className={styles.name}>Prénom et nom</p>
              <p className={styles.username}>Nom d’affichage</p>
              <p className={styles.job}>Métier</p>
              <p className={styles.mail}>Adresse email</p>
              {isRoleAllowed && <p className={styles.role}>Rôle</p>}
            </div>
            <ul className={styles.members}>
              {users
                .filter(
                  (user: { id: string; active: number }) => user.active !== 0
                )
                .map((user: { id: string; active: number }) => (
                  <li key={user.id}>
                    <Member userProps={user} setModifyUsers={setModifyUsers} />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </ProfilLayout>
  );
};

export default Settings;
