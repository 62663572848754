import { X } from "@phosphor-icons/react";
import styles from "./FinishSprintModal.module.scss";
import FenekIcon from "ui/icons/FenekIcon";
import SprintIcon from "ui/icons/SprintIcon";
import PlanificationIcon from "ui/icons/PlanificationIcon";
import { SprintType } from "sprint/types";
import { useState, useEffect, useContext } from "react";
import { StoryType } from "story/types";
import {
  handleGetCurrentSprint,
  handleGetSprintState,
  handleGetSprints,
  handleUpdateSprint,
} from "sprint/controllers";
import { EditingCurrentSprintContext } from "sprint/contexts/SprintContext";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { errorsAPI } from "app/constants/errors";
import Dropdown from "app/components/dropdown/Dropdown";
import { CaretDown } from "phosphor-react";
import LoadingIcon from "ui/icons/LoadingIcon";
import { resetHandleStatusModal } from "app/reducers/generalModal";

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  sprint?: SprintType;
  callback?: (e: React.MouseEvent) => Promise<void>;
};

const FinishSprintModal = ({ setIsOpen, sprint, callback }: Props) => {
  const dispatch = useDispatch();
  const { setEditingCurrentSprint } = useContext(EditingCurrentSprintContext);
  const [toNextSprint, setToNextSprint] = useState(true);
  const [canToNextSprint, setCanToNextSprint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSprint, setCurrentSprint] = useState<SprintType | null>(
    sprint || null
  );
  const [sprints, setSprints] = useState<SprintType[]>([]);
  const [selectedNewSprint, setSelectedNewSprint] = useState<SprintType | null>(
    null
  );
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    handleGetSprints({ active: 2, simple: true })
      .then((sprints) => {
        setSprints(sprints.data);
        setSelectedNewSprint(sprints.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (sprint) return;
    handleGetCurrentSprint()
      .then((res) => {
        setCurrentSprint(res.data);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [sprint, dispatch]);

  const totalPoints = currentSprint?.stories.reduce(
    (acc: number, story: StoryType) => acc + (story.points || 0),
    0
  );

  const totalPointsDone = currentSprint?.stories.reduce(
    (acc: number, story: StoryType) => {
      if (story.type === "done") return acc + (story.points || 0);
      return acc;
    },
    0
  );

  const totalStoriesDone = currentSprint?.stories.reduce(
    (acc: number, story: StoryType) => {
      if (story.type === "done") return acc + 1;
      return acc;
    },
    0
  );

  useEffect(() => {
    handleGetSprintState().then((res) => {
      if (res.data.sprints > 1) {
        setCanToNextSprint(true);
        setToNextSprint(true);
      } else {
        setCanToNextSprint(false);
        setToNextSprint(false);
      }
    });
  }, []);

  const finishSprint = async (e: React.MouseEvent) => {
    setLoading(true);
    try {
      if (!currentSprint) return;
      const data = {
        active: 0,
        total_points: totalPoints,
        total_points_finish: totalPointsDone,
        story_finish: totalStoriesDone,
        moveTo:
          toNextSprint && selectedNewSprint ? selectedNewSprint.id : "backlog",
      };
      await handleUpdateSprint(currentSprint.id, data);
      setLoading(false);
      setEditingCurrentSprint(true);
      const toastData = {
        status: true,
        message: "Le sprint a bien été terminé",
        error: false,
      };
      dispatch(resetHandleStatusModal());
      dispatch(showModal(toastData));
      if (callback) {
        await callback(e);
      }
    } catch (err: any) {
      setLoading(false);
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(resetHandleStatusModal());
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const selectNewSprint = (sprint: SprintType) => {
    setSelectedNewSprint(sprint);
    setDropdown(false);
  };

  return (
    <div className={styles.endingPopup}>
      <button
        className={`m-popup__header__close ${styles.close}`}
        onClick={() => dispatch(resetHandleStatusModal())}
      >
        <X weight="bold" />
      </button>
      <div className={styles.fenek}>
        <FenekIcon />
      </div>
      <p className={styles.title}>
        Wouhouuu !<br /> Le sprint n°{currentSprint?.number} est terminé
      </p>
      <div className={styles.stats}>
        <div className={styles.statsItem}>
          <div className={styles.statIcon}>
            <SprintIcon />
          </div>
          <p className={styles.statNumber}>{totalPointsDone}</p>
          <p className={styles.statTitle}>points réalisés</p>
          <p className={styles.statSubtitle}>(sur {totalPoints} planifiés)</p>
        </div>
        <div className={styles.statsItem}>
          <div className={styles.statIcon}>
            <PlanificationIcon />
          </div>
          <p className={styles.statNumber}>{totalStoriesDone}</p>
          <p className={styles.statTitle}>stories terminées</p>
          <p className={styles.statSubtitle}>
            (sur {currentSprint?.stories.length} planifiées)
          </p>
        </div>
      </div>
      {currentSprint && totalStoriesDone !== undefined && (
        <p className={styles.label}>
          Que faire des {currentSprint.stories.length - totalStoriesDone}{" "}
          stories non terminées ?
        </p>
      )}
      <div className={styles.choice}>
        {canToNextSprint && (
          <button
            className={toNextSprint ? styles.choiceActive : ""}
            onClick={() => setToNextSprint(true)}
          >
            Transférer dans sprint
          </button>
        )}
        <button
          className={!toNextSprint ? styles.choiceActive : ""}
          onClick={() => setToNextSprint(false)}
        >
          Mettre dans le backlog
        </button>
      </div>
      {toNextSprint && (
        <>
          <div className={styles.selectSprintContainer}>
            <button
              className={styles.selectSprint}
              onClick={() => setDropdown(true)}
            >
              <SprintIcon /> Sprint {selectedNewSprint?.number} <CaretDown />
            </button>
            {dropdown && (
              <div className={styles.selectSprintDropdown}>
                <Dropdown setIsOpen={setDropdown}>
                  <ul>
                    {sprints && (
                      <>
                        {sprints.map((sprint) => {
                          return (
                            <li>
                              <button
                                onClick={() => selectNewSprint(sprint)}
                                className={styles.actionItem}
                              >
                                Sprint {sprint.number}
                              </button>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </Dropdown>
              </div>
            )}
          </div>
        </>
      )}
      <button
        className={`m-button m-button--black m-button--full ${styles.validate}`}
        onClick={finishSprint}
      >
        {loading ? <LoadingIcon /> : "Valider"}
      </button>
    </div>
  );
};

export default FinishSprintModal;
