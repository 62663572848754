import { StoryTask, StoryType } from "story/types";
import styles from "./TasksStory.module.scss";
import Task from "../task/Task";
import { StrictModeDroppable } from "app/components/strict-mode-droppable/StrictModeDroppable";
import {
  DragDropContext,
  Draggable,
  DropResult,
  DragStart,
  DraggableLocation,
  DragUpdate,
} from "react-beautiful-dnd";
import { DotsSixVertical, Trash, X } from "@phosphor-icons/react";
import { useState } from "react";
import DeleteButtonIcon from "ui/icons/DeleteButtonIcon";
import Popup from "ui/components/popup/Popup";

type Props = {
  story: StoryType;
  setStory: (story: StoryType) => void;
  setTasksToDelete: (tasksToDelete: number[]) => void;
  tasksToDelete: number[];
  closePopup: () => void;
  editing?: boolean;
};

const TasksStory = ({
  story,
  setStory,
  setTasksToDelete,
  tasksToDelete,
  closePopup,
  editing,
}: Props) => {
  const [deletePopup, setDeletePopup] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedDeleteTask, setSelectedDeleteTask] = useState<StoryTask>();
  const [sourceState, setSourceState] = useState<DraggableLocation>();
  const [draggedOverDelete, setDraggedOverDelete] = useState<number | null>(
    null
  );

  const handleCloseDeleteMissionPopup = () => {
    setDeletePopup(false);
  };

  const onDragEnd = (result: DropResult) => {
    setShowDelete(false);
    const { destination, source, draggableId } = result;

    // If the draggable element is dropped outside a droppable area or in its original position
    if (
      !destination ||
      (destination.index === source.index &&
        destination.droppableId === source.droppableId)
    ) {
      return;
    }

    // Find the dragged storyTask based on the draggableId
    const draggedStoryTask = story.storyTasks.find(
      (task) =>
        task.id?.toString() === draggableId ||
        task.temp_id?.toString() === draggableId
    );

    if (!draggedStoryTask) {
      return;
    }

    if (destination.droppableId === `delete-${story.id}`) {
      setSelectedDeleteTask(draggedStoryTask);
      setSourceState(source);
      setDeletePopup(true);
      setDraggedOverDelete(null);
      return;
    }

    // Create a copy of the storyTasks array
    const updatedStoryTasks = [...story.storyTasks];

    // Remove the dragged task from its original position
    updatedStoryTasks.splice(source.index, 1);

    // Insert the dragged task into the new position
    updatedStoryTasks.splice(destination.index, 0, draggedStoryTask);

    // Update the order property of each task within the updatedStoryTasks array
    updatedStoryTasks.forEach((task, index) => {
      task.order = index;
    });

    // Create a new copy of the story object with the updated storyTasks
    const updatedStory = {
      ...story,
      storyTasks: updatedStoryTasks,
    };

    // Update the state with the new story object
    setStory(updatedStory);
  };

  const deleteTask = () => {
    if (!sourceState) return;
    const updatedStoryTasks = [...story.storyTasks];

    // Remove the dragged task from its original position
    updatedStoryTasks.splice(sourceState.index, 1);

    // Update the order property of each task within the updatedStoryTasks array
    updatedStoryTasks.forEach((task, index) => {
      task.order = index;
    });

    // Create a new copy of the story object with the updated storyTasks
    const updatedStory = {
      ...story,
      storyTasks: updatedStoryTasks,
    };

    // Update the state with the new story object
    setStory(updatedStory);

    if (selectedDeleteTask?.id) {
      setTasksToDelete([...tasksToDelete, selectedDeleteTask.id]);
    }

    setSelectedDeleteTask(undefined);
    setDeletePopup(false);
    setSourceState(undefined);
  };

  const onDragStart = (e: DragStart) => {
    setShowDelete(true);
  };

  const onDragUpdate = (e: DragUpdate) => {
    const { destination, draggableId } = e;

    if (destination?.droppableId === `delete-${story.id}`) {
      setDraggedOverDelete(parseInt(draggableId));
    } else {
      setDraggedOverDelete(null);
    }
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      <div className={styles.top}>
        <StrictModeDroppable droppableId={`delete-${story.id}`}>
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`${styles.delete} ${
                  snapshot.isDraggingOver ? styles.shaking : ""
                }`}
              >
                <div
                  className={styles.deleteZone}
                  style={showDelete ? { opacity: 1 } : { opacity: 0 }}
                >
                  <span className="sr-only">Supprimer la story</span>
                  <DeleteButtonIcon
                    color={snapshot.isDraggingOver ? "red" : "grey"}
                  />
                </div>
                {provided.placeholder}
              </div>
            );
          }}
        </StrictModeDroppable>
      </div>
      <StrictModeDroppable droppableId={`tasks-${story.id}`}>
        {(provided, snapshot) => {
          return (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${styles.tasks} ${
                snapshot.isDraggingOver ? styles.draggingDroppable : ""
              }`}
            >
              {story.storyTasks.map((storyTask, index) => {
                let indexTask = story.storyTasks.length - index;

                return (
                  <Draggable
                    draggableId={
                      storyTask.id?.toString() ||
                      storyTask.temp_id?.toString() ||
                      `task-${story?.id}-${storyTask.order}`
                    }
                    index={index}
                    key={
                      storyTask.id?.toString() ||
                      storyTask.temp_id?.toString() ||
                      `task-${story?.id}-${storyTask.order}`
                    }
                  >
                    {(provided, snapshot) => {
                      return (
                        <li
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          className={`${styles.task} ${
                            snapshot.isDragging
                              ? styles.dragging
                              : styles[`indexTask__${indexTask}`]
                          } ${
                            draggedOverDelete === storyTask.id
                              ? styles.deleteTask
                              : ""
                          }`}
                          key={
                            storyTask.id?.toString() ||
                            storyTask.temp_id?.toString() ||
                            `task-${story?.id}-${storyTask.order}`
                          }
                        >
                          <span
                            className={styles.drag}
                            {...provided.dragHandleProps}
                          >
                            <DotsSixVertical
                              className={styles.dots}
                              weight="bold"
                            />
                          </span>
                          <Task
                            storyTask={storyTask}
                            setStory={setStory}
                            story={story}
                            closePopup={closePopup}
                            editing={editing}
                          />
                        </li>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          );
        }}
      </StrictModeDroppable>
      {deletePopup && (
        <Popup setIsOpen={setDeletePopup} width="472px">
          <div className="m-popup__header">
            <p>Supprimer la sous-tâche</p>
            <button
              className="m-popup__header__close"
              onClick={handleCloseDeleteMissionPopup}
            >
              <X weight="bold" />
            </button>
          </div>
          <div className="m-popup__content">
            <p className="m-popup__content__text-grey">
              Confirmez-vous la suppression de la sous-tâche ? Cette action est
              irréversible.
            </p>
            <button onClick={deleteTask} className="m-button m-button--black">
              <Trash weight="fill" />
              Supprimer
            </button>
          </div>
        </Popup>
      )}
    </DragDropContext>
  );
};

export default TasksStory;
