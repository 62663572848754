import { Lightning, X } from "@phosphor-icons/react";
import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import { resetHandleStatusModal } from "app/reducers/generalModal";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FinishSprintModal from "sprint/components/finish-sprint/finish-sprint-modal/FinishSprintModal";
import { handleGetSprintState, handleUpdateSprint } from "sprint/controllers";
import Popup from "ui/components/popup/Popup";
import LoadingIcon from "ui/icons/LoadingIcon";

type Props = {
  sprintNb: string;
  sprintId: number;
};

const StartPopup = ({ sprintNb, sprintId }: Props) => {
  const dispatch = useDispatch();
  const [finishSprintPopup, setFinishSprintPopup] = useState<boolean>(false);
  const [isCurrentSprint, setIsCurrentSprint] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    handleGetSprintState()
      .then((res) => {
        setIsCurrentSprint(res.data.currentSprint);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [dispatch]);

  const openFinishSprintPopup = () => {
    setFinishSprintPopup(true);
  };

  const startSprint = async (e: React.MouseEvent) => {
    await handleUpdateSprint(sprintId, { active: 1 });
    dispatch(resetHandleStatusModal());
  };

  const renderStartSprintButton = () => {
    if (isCurrentSprint) {
      return (
        <button
          className="m-button m-button--black"
          onClick={openFinishSprintPopup}
        >
          <Lightning weight="fill" />
          Démarrer le sprint
        </button>
      );
    } else {
      return (
        <button className="m-button m-button--black" onClick={startSprint}>
          <Lightning weight="fill" />
          {loading ? <LoadingIcon /> : "Démarrer le sprint"}
        </button>
      );
    }
  };

  return (
    <>
      <p className="m-popup__content__text-grey">
        Voulez-vous vraiment démarrer le <b>sprint n°{sprintNb}</b> ? Le sprint
        en cours sera clôturé.
      </p>
      {renderStartSprintButton()}
      {finishSprintPopup && (
        <Popup setIsOpen={setFinishSprintPopup} width="496px">
          <FinishSprintModal
            setIsOpen={setFinishSprintPopup}
            callback={startSprint}
          />
        </Popup>
      )}
    </>
  );
};

export default StartPopup;
