import axios from "axios";
import { getTokenFromCookie } from "middlewares/authorizations";

/** headers REQUEST **/

const apiurl = process.env.REACT_APP_API;

export const getRequest = (url: string, formData?: object) => {
  const token = getTokenFromCookie();
  const headers = {
    Authorization: "Bearer " + token,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  const finalUrl = apiurl + url;

  if (formData) {
    return axios.get(finalUrl, { headers: headers, params: formData });
  } else {
    return axios.get(finalUrl, { headers: headers });
  }
};

export const postRequestWithoutToken = (url: string, formData: object) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const finalUrl = apiurl + url;
  return axios.post(finalUrl, formData, { headers: headers });
};

/** REQUETE POST avec header  **/

export const postRequest = (url: string, formData: any) => {
  const token = getTokenFromCookie();
  const headers = {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
  };
  const config = {
    headers: headers,
  };
  const finalUrl = apiurl + url;
  return axios.post(finalUrl, formData, config);
};

/** REQUETE PUT avec header  **/

export const putRequest = (url: string, formData: object) => {
  const token = getTokenFromCookie();
  const headers = {
    Authorization: "Bearer " + token,
    Accept: "application/json ",
    "Content-Type": "application/json",
  };
  const finalUrl = apiurl + url;
  return axios.put(finalUrl, formData, { headers: headers });
};

/** REQUETE DELETE avec header  **/

export const deleteRequest = (url: string) => {
  const token = getTokenFromCookie();
  const headers = {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
  };
  const finalUrl = apiurl + url;
  return axios.delete(finalUrl, { headers: headers });
};

export const getExternalRequest = (url: string) => {
  return axios.get(url);
};

export const deleteRequestWithData = (url: string, formData: object) => {
  const token = getTokenFromCookie();
  const headers = {
    Authorization: "Bearer " + token,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const finalUrl = apiurl + url;
  return axios.delete(finalUrl, { data: formData, headers: headers });
};
