import { CreateMediaStoryLink, CreateStoryType } from "story/types";
import {
  deleteRequest,
  deleteRequestWithData,
  getRequest,
  postRequest,
  putRequest,
} from "utils/useApi";

export const handleGetStories = async (query?: object) => {
  return getRequest(`story/backlog`, query).then((response) => {
    return response.data;
  });
};

export const handleGetStoryById = (id: number) => {
  return getRequest(`story/${id}`).then((response) => {
    return response.data;
  });
};

export const handleCreateStory = (data: CreateStoryType) => {
  return postRequest("story", data).then((response) => {
    return response.data;
  });
};

export const handleUpdateStory = (
  id: number,
  data: Partial<CreateStoryType>
) => {
  return putRequest(`story/${id}`, data).then((response) => {
    return response.data;
  });
};

export const handleCreateMediaStoryLink = (data: CreateMediaStoryLink) => {
  return postRequest("media-story-link", data).then((response) => {
    return response.data;
  });
};

export const handleDeleteStory = (id: number) => {
  return deleteRequest(`story/${id}`).then((response) => {
    return response.data;
  });
};

export const handleDeleteMediaStory = (id: number, data: any) => {
  return deleteRequestWithData(`story/${id}/media-story-link`, data).then(
    (response) => {
      return response.data;
    }
  );
};
