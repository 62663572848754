import { useCallback, useState } from "react";
import styles from "./FinishSprint.module.scss";
import Popup from "ui/components/popup/Popup";
import { X } from "@phosphor-icons/react";
import { SprintType } from "sprint/types";
import FinishSprintModal from "./finish-sprint-modal/FinishSprintModal";
import { useDispatch } from "react-redux";
import { setHandleStatusModal } from "app/reducers/generalModal";

type Props = {
  sprint: SprintType;
};

const FinishSprint = ({ sprint }: Props) => {
  const [finishPopup, setFinishPopup] = useState(false);
  const [endingSprintPopup, setEndingSprintPopup] = useState(false);
  const dispatch = useDispatch();

  const handleFinishPopup = (event: any) => {
    setFinishPopup(true);
  };

  const handleFinishClosePopup = (event: any) => {
    event.stopPropagation();
    setFinishPopup(false);
  };

  const sprintEnd = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <>
            <p className="m-popup__content__text-grey">
              Souhaitez-vous vraiment terminer le{" "}
              <b>sprint n°{sprint.number}</b> ?
            </p>
            <div className={styles.buttons}>
              <button
                className="m-button m-button--black"
                onClick={sprintEndConfirmed}
              >
                Terminer le sprint
              </button>
            </div>
          </>
        ),
        mW: 800,
        title: "Terminer le sprint",
      })
    );
  }, []);

  const sprintEndConfirmed = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <FinishSprintModal setIsOpen={setEndingSprintPopup} sprint={sprint} />
        ),
        header: false,
        mW: 496,
        title: "test ",
      })
    );
  }, []);

  return (
    <>
      <div className={styles.btnFinish}>
        <button className="m-button m-button--black" onClick={sprintEnd}>
          Terminer le sprint {sprint.number}
        </button>
      </div>
    </>
  );
};

export default FinishSprint;
