import { handleUpdateCustomer } from "clients/controllers/customers";
import { AccountInterface } from "clients/interfaces/account";
import { AccountCustomer } from "clients/interfaces/customer";
import { useParams } from "react-router-dom";
import styles from "./AddLog.module.scss";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { CaretDown, X } from "@phosphor-icons/react";
import { Project } from "clients/interfaces/project";
import { useEffect, useMemo, useState } from "react";

type Props = {
  setEditClient: (value: boolean) => void;
  accounts: AccountInterface[];
  closePopup: () => void;
  edit?: boolean;
  data?: AccountInterface;
  projectsList: Project[];
  onUpdateClient?: Function;
};

const AddLog = ({
  setEditClient,
  accounts,
  closePopup,
  edit,
  data,
  projectsList,
  onUpdateClient,
}: Props) => {
  const [projectName, setProjectName] = useState<string | null>(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;

    const accountInput = form.elements.namedItem("account") as HTMLInputElement;
    const usernameInput = form.elements.namedItem(
      "username"
    ) as HTMLInputElement;
    const passwordInput = form.elements.namedItem(
      "password"
    ) as HTMLInputElement;
    const linkInput = form.elements.namedItem("link") as HTMLInputElement;
    const projectInput = form.elements.namedItem(
      "project"
    ) as HTMLSelectElement;

    const account: AccountInterface = {
      account: accountInput.value,
      username: usernameInput.value,
      password: passwordInput.value,
      link: linkInput.value !== "" ? linkInput.value : undefined,
      project_id: Number(projectInput.value),
    };
    let accountData: AccountCustomer;
    if (!edit && !data) {
      accountData = {
        accounts: [...accounts, account],
      };
    } else {
      accountData = {
        accounts: accounts.map((account: AccountInterface) => {
          if (
            account.link === data?.link &&
            account.project_id === data?.project_id &&
            account.password === data?.password &&
            account.username === data?.username &&
            account.account === data?.account
          ) {
            return {
              account: accountInput.value,
              username: usernameInput.value,
              password: passwordInput.value,
              link: linkInput.value !== "" ? linkInput.value : undefined,
              project_id: Number(projectInput.value),
            };
          } else {
            return account;
          }
        }),
      };
    }

    handleUpdateCustomer(Number(id), accountData)
      .then((response) => {
        setEditClient(true);
        closePopup();
        if (onUpdateClient) {
          onUpdateClient(response.data);
        }
      })
      .catch((err) => {
        console.error(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  };

  const handleClose = () => {
    closePopup();
  };

  useEffect(() => {
    const findProjectById = (id: number) => {
      const project = projectsList.find((p) => p.id === id);
      return project ? project.name : null;
    };

    const projectName = findProjectById(data?.project_id);
    setProjectName(projectName);
  }, [data?.project_id, projectsList]);

  const renderDefaultOption = useMemo(() => {
    if (data?.project_id) {
      return <option value={data?.project_id}>{projectName}</option>;
    } else {
      return <option value="">Choisir</option>;
    }
  }, [data?.project_id, projectName]);

  const renderProjectOptions = useMemo(() => {
    return projectsList.map((projectsList) => (
      <option key={projectsList.id} value={projectsList.id}>
        {projectsList.name}
      </option>
    ));
  }, [projectsList]);

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit}>
        <div className={styles.header}>
          <p>Ajouter un identifiant</p>
          <div className={styles.buttons}>
            <button
              data-cy="submit-create-customer"
              type="submit"
              className="m-button m-button--black"
            >
              Enregistrer
            </button>
            <button
              className="m-button m-button--grey m-button--rounded"
              type="button"
              onClick={handleClose}
            >
              <span className="sr-only">Fermer l'ajout de mission</span>
              <X weight="bold" />
            </button>
          </div>
        </div>
        <div className={styles.input}>
          <label htmlFor="account" className="m-label">
            Compte<span>*</span>
          </label>
          <input
            type="text"
            id="account"
            className="m-input m-input--grey"
            placeholder="Nom du compte"
            defaultValue={data?.account}
            required
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="username" className="m-label">
            Identifiant<span>*</span>
          </label>
          <input
            type="text"
            id="username"
            className="m-input m-input--grey"
            placeholder="Identifiant de connexion"
            defaultValue={data?.username}
            required
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="password" className="m-label">
            Mot de passe<span>*</span>
          </label>
          <input
            type="text"
            id="password"
            className="m-input m-input--grey"
            placeholder="Mot de passe"
            defaultValue={data?.password}
            required
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="link" className="m-label">
            Lien
          </label>
          <input
            type="url"
            id="link"
            className="m-input m-input--grey"
            placeholder="Lien d’accès"
            defaultValue={data?.link}
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="link" className="m-label">
            Projet <span>*</span>
          </label>
          <div className={styles.select}>
            <select className={styles.select__selector} required id="project">
              {renderDefaultOption}
              {renderProjectOptions}
            </select>
            <div className={styles.select__icon}>
              <CaretDown size={20} color="#030507" weight="bold" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddLog;
