import { TimerType } from "timer/types";
import styles from "./SingleTimer.module.scss";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import DateTimer from "../date-timer/DateTimer";
import { formatSecondsToHours } from "utils/formatDate";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { Play, Plus, PlusCircle, Trash, X } from "@phosphor-icons/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Popup from "ui/components/popup/Popup";
import Dropdown from "app/components/dropdown/Dropdown";
import { handleDeleteTimer, handleUpdateTimer } from "timer/controllers/timer";
import { EditingTimerContext } from "timer/context/EditingTimerProvider";
import TextInputTrunc from "app/components/text-input-trunc/TextInputTrunc";
import SelectMissions from "../select-missions/SelectMissions";
import SelectCustomer from "../select-customer/SelectCustomer";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import OptionsIcon from "ui/icons/OptionsIcon";
import useOutsideClick from "hooks/useOutsideClick";

type Props = {
  timer: TimerType;
  color?: "grey" | "white";
  setAllTimers?: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const SingleTimer = ({ timer, color = "white", setAllTimers }: Props) => {
  const dispatch = useDispatch();

  const { setEditingTimer } = useContext(EditingTimerContext);
  const { currentTimer, setCurrentTimer, triggerTimer, setTimerOpen } =
    useContext(CurrentTimerContext);
  const [openOption, setOpenOption] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [addCustomerPopup, setAddCustomerPopup] = useState(false);
  const [addMissionPopup, setAddMissionPopup] = useState(false);
  const [showSubtaskPlaceholder, setShowSubtaskPlaceholder] = useState(false);
  const [timerCustomer, setTimerCustomer] = useState(timer.customer);
  const [timerProject, setTimerProject] = useState(timer.project);
  const [timerMissions, setTimerMissions] = useState(timer.missions);

  const duration = formatSecondsToHours(timer.duration);

  const onClickOutside = useCallback(() => {
    setOpenOption(false);
    setDeletePopupOpen(false);
  }, []);

  const optionsPopup = useRef(null);

  useOutsideClick(optionsPopup, onClickOutside);

  const openAddCustomerPopup = () => {
    setAddCustomerPopup(true);
  };

  const openAddMissionPopup = () => {
    setAddMissionPopup(true);
  };

  const deleteTimer = async () => {
    try {
      await handleDeleteTimer(timer.id);
      setDeletePopupOpen(false);
      setEditingTimer(true);
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const launchTimer = async () => {
    if (currentTimer?.end_at === null && currentTimer?.id) {
      setTimerOpen(true);
      const modalData = {
        status: true,
        message: `Vous avez déjà un timer en cours`,
        error: true,
      };
      dispatch(showModal(modalData));
    } else {
      const currentTimerData = {
        customer: timer.customer,
        customer_id: timer.customer?.id,
        project_id: timer.project?.id,
        project: timer.project,
        missions: timer.missions,
        mission_ids: timer.missions?.map((mission) => mission.id) || [],
        name: timer.name,
        start_at: "",
        story_id: timer.id,
      };

      setCurrentTimer(currentTimerData);
      await triggerTimer(currentTimerData);
    }
  };

  const handleBlurName = async (e: any) => {
    const name = e.target.value;
    if (name === timer.name || typeof name !== "string") return;

    try {
      await handleUpdateTimer(timer.id, { name: name });
      if (setAllTimers) {
        setAllTimers((allTimers) =>
          allTimers.map((t) => {
            return t.id === timer.id ? { ...t, name: name } : t;
          })
        );
      }
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const handleBlurSubtask = async (e: React.ChangeEvent<HTMLSpanElement>) => {
    const sub_task = e.currentTarget.textContent;
    if (sub_task === timer.sub_task || typeof sub_task !== "string") return;
    try {
      await handleUpdateTimer(timer.id, {
        sub_task: sub_task,
      });
      if (setAllTimers) {
        setAllTimers((allTimers) =>
          allTimers.map((t) =>
            t.id === timer.id ? { ...t, sub_task: sub_task } : t
          )
        );
      }
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const handleOpenOptions = (e: any) => {
    setOpenOption(true);
  };

  const handleConfirmDeleteModal = (e: any) => {
    e.stopPropagation();
    setDeletePopupOpen(!deletePopupOpen);
  };

  return (
    <>
      <div
        className={`${styles.container} ${
          color === "grey" ? styles.greyContainer : ""
        }`}
      >
        <div className={styles.nameContainer}>
          <TextInputTrunc
            color="#000"
            value={timer.name}
            placeholder="Tapez le nom de la story"
            onBlur={handleBlurName}
          />
          {(timer?.sub_task || showSubtaskPlaceholder) && (
            <div className={styles.subtaskContainer} data-text={timer.sub_task}>
              <TextInputTrunc
                color="#8C8FA6"
                value={timer.sub_task}
                placeholder="Tapez le nom de la sous-tâche"
                onBlur={handleBlurSubtask}
              />
            </div>
          )}
        </div>
        <div className={styles.tags}>
          <div className={styles.addMissionContainer}>
            <button
              className={styles.addMissionButton}
              onClick={openAddCustomerPopup}
            >
              {timerCustomer?.id ? (
                <ProjectTag
                  content={timerCustomer.name}
                  contentColor={timerCustomer.color}
                  project={timerProject?.name}
                  size="small"
                />
              ) : (
                <span className={styles.addCustomer}>
                  <Plus weight="bold" />
                  <p>Ajouter un client/projet</p>
                </span>
              )}
            </button>
            {addCustomerPopup && (
              <div className={styles.addMissionDropdown}>
                <Dropdown setIsOpen={setAddCustomerPopup}>
                  <SelectCustomer
                    timer={timer}
                    timerCustomer={timerCustomer}
                    setTimerCustomer={setTimerCustomer}
                    timerProject={timerProject}
                    setTimerProject={setTimerProject}
                    setAllTimers={setAllTimers}
                  />
                </Dropdown>
              </div>
            )}
          </div>
          <div className={styles.addMissionContainer}>
            <button
              className={styles.addMissionButton}
              onClick={openAddMissionPopup}
            >
              {timerMissions && timerMissions.length > 0 ? (
                timerMissions.map((mission) => (
                  <div
                    key={mission.id}
                    className={styles.tagMission}
                    style={{ backgroundColor: mission.color }}
                  >
                    <p className={styles.titleOnly}>{mission.name}</p>
                  </div>
                ))
              ) : (
                <span className={styles.addMission}>
                  <Plus weight="bold" />
                  <p>Ajouter une mission</p>
                </span>
              )}
            </button>
            {addMissionPopup && (
              <div className={styles.addMissionDropdown}>
                <Dropdown setIsOpen={setAddMissionPopup}>
                  <SelectMissions
                    timer={timer}
                    timerMissions={timerMissions}
                    setTimerMissions={setTimerMissions}
                    setAllTimers={setAllTimers}
                  />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.time}>
            <div
              className={`${styles.dateTimer} ${
                color === "grey" ? styles.dateTimerGrey : ""
              }`}
            >
              <DateTimer timer={timer} setAllTimers={setAllTimers} />
            </div>
            <p
              className={`${styles.duration} ${
                color === "grey" ? styles.durationGrey : ""
              }`}
            >
              {duration}
            </p>
          </div>
          <div className={styles.options}>
            <div className={styles.options__align}>
              <button className={styles.play} onClick={launchTimer}>
                <span className="sr-only">Lancer le timer</span>
                <Play weight="fill" />
              </button>
              <button
                onClick={handleOpenOptions}
                className={styles.optionsButton}
                ref={optionsPopup}
              >
                <span className="sr-only">Ouvrir les options</span>
                <OptionsIcon />
              </button>
            </div>
          </div>

          {openOption && (
            <div className={styles.optionsDropdownWrapper}>
              <div className={styles.optionsDropdown}>
                {!timer.sub_task && (
                  <button
                    className={styles.actionItem}
                    onClick={() => setShowSubtaskPlaceholder(true)}
                  >
                    <PlusCircle weight="fill" />
                    Ajouter une sous-tâche
                  </button>
                )}
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.actionItem}
                    onClick={handleConfirmDeleteModal}
                  >
                    <Trash weight="fill" />
                    Supprimer l’entrée
                  </button>
                </div>
                {deletePopupOpen && (
                  <div className={styles.confirmationDelete}>
                    <button
                      className="m-button-small m-button-small--grey"
                      onClick={handleConfirmDeleteModal}
                    >
                      Annuler
                    </button>
                    <button
                      className="m-button-small m-button-small--red"
                      onClick={deleteTimer}
                    >
                      <Trash weight="fill" />
                      Confirmer
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleTimer;
