import styles from "./ProjectActions.module.scss";
import { useNavigate } from "react-router-dom";
import {
  Archive,
  ArrowUpRight,
  File,
  PencilSimple,
  Table,
  Trash,
  X,
} from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import Dropdown from "app/components/dropdown/Dropdown";

import Popup from "ui/components/popup/Popup";
import SidePopup from "ui/components/side-popup/SidePopup";
import AddProject from "clients/components/add-project/AddProject";
import {
  handleDeleteProject,
  handleUpdateProjectActive,
} from "clients/controllers/projects";
import { Project } from "clients/interfaces/project";
import { useSelector } from "react-redux";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { Link } from "phosphor-react";
import ReportHistory from "../report-history/ReportHistory";

type Props = {
  project: Project;
  id: string;
  customerId: string;
  setDropdownOpen: (value: boolean) => void;
  setModifyingProject: (value: boolean) => void;
  onChangeStatusHistory?: Function;
};

const ProjectActions = ({
  project,
  id,
  customerId,
  setDropdownOpen,
  setModifyingProject,
  onChangeStatusHistory,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const [showModifyProject, setShowModifyProject] = useState(false);
  // const [showReportHistory, setShowReportHistory] = useState(false);
  const [showArchiveProject, setShowArchiveProject] = useState(false);
  const [showDeleteProject, setShowDeleteProject] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);
  const openModify = () => {
    setShowModifyProject(true);
  };
  const openModifyRepportHistory = () => {
    if (onChangeStatusHistory) {
      onChangeStatusHistory(true);
    }
  };

  const changeArchiveOpen = () => {
    setShowArchiveProject(!showArchiveProject);
  };

  const changeDeleteOpen = () => {
    setShowDeleteProject(!showDeleteProject);
  };

  const archiveProject = () => {
    const data = project?.active ? { active: false } : { active: true };
    if (id) {
      handleUpdateProjectActive(Number(id), data)
        .then((res) => {
          navigate(`/clients/${customerId}`);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
    console.log(data);
  };

  const deleteProject = () => {
    if (id) {
      handleDeleteProject(Number(id))
        .then((res) => {
          navigate(`/clients/${customerId}`);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  };

  const renderElements = () => {
    if (isAllowed) {
      return (
        <>
          <li>
            <button onClick={openModify} className={styles.actionItem}>
              <PencilSimple weight="fill" className={styles.actionIcon} />
              Modifier le projet
            </button>
          </li>
          {showModifyProject && customerId && (
            <SidePopup setIsOpen={setShowModifyProject} dataLoaded={true}>
              <AddProject
                edit={true}
                data={project}
                customerId={customerId}
                setShowCreateProject={setShowModifyProject}
                setCreatingProject={setModifyingProject}
              />
            </SidePopup>
          )}
          <li>
            <button
              onClick={openModifyRepportHistory}
              className={styles.actionItem}
            >
              <Link className={styles.actionIcon} weight="bold" />
              Historique des rapports
            </button>
          </li>

          <li>
            <button onClick={changeArchiveOpen} className={styles.actionItem}>
              <Archive weight="fill" className={styles.actionIcon} />
              {project.active ? "Archiver le projet" : "Réactiver le projet"}
            </button>
          </li>
          {showArchiveProject && (
            <Popup width="496" setIsOpen={setShowArchiveProject}>
              <div className="m-popup__header">
                <p>
                  {project.active
                    ? "Archiver le projet"
                    : "Réactiver le projet"}
                </p>
                <button
                  className="m-popup__header__close"
                  onClick={changeArchiveOpen}
                >
                  <X weight="bold" />
                </button>
              </div>
              <div className="m-popup__content">
                {project.active ? (
                  <p className="m-popup__content__text-grey">
                    Souhaitez-vous archiver ce projet ?<br /> Vous pourrez le
                    réactiver à tout moment.
                  </p>
                ) : (
                  <p className="m-popup__content__text-grey">
                    Souhaitez-vous réactiver ce projet ?
                  </p>
                )}
                <button
                  onClick={archiveProject}
                  className="m-button m-button--black"
                >
                  <Archive weight="fill" />
                  {project.active
                    ? "Archiver le projet"
                    : "Réactiver le projet"}
                </button>
              </div>
            </Popup>
          )}
          <li>
            <button onClick={changeDeleteOpen} className={styles.actionItem}>
              <Trash weight="fill" className={styles.actionIcon} />
              Supprimer le projet
            </button>
          </li>
          {showDeleteProject && (
            <Popup width="496" setIsOpen={setShowDeleteProject}>
              <div className="m-popup__header">
                <p>Supprimer le mission</p>
                <button
                  className="m-popup__header__close"
                  onClick={changeDeleteOpen}
                >
                  <X weight="bold" />
                </button>
              </div>
              <div className="m-popup__content">
                <p className="m-popup__content__text-grey">
                  Confirmez-vous la suppression de la mission de ce projet ?
                  Cette action est irréversible.
                </p>
                <button
                  onClick={deleteProject}
                  className="m-button m-button--black"
                >
                  <Trash weight="fill" />
                  Supprimer le projet
                </button>
              </div>
            </Popup>
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.otherActions}>
      <Dropdown setIsOpen={setDropdownOpen}>
        <ul className={styles.dropdownList}>
          {project.quotation_ref && (
            <li>
              <a
                href={project.quotation_ref}
                target="_blank"
                className={styles.actionItem}
                rel="noreferrer"
              >
                <File weight="fill" className={styles.actionIcon} />
                Voir le devis
                <ArrowUpRight weight="bold" className={styles.actionArrow} />
              </a>
            </li>
          )}

          {project.planning_link && (
            <li>
              <a
                href={project.planning_link}
                target="_blank"
                className={styles.actionItem}
                rel="noreferrer"
              >
                <Table weight="fill" className={styles.actionIcon} />
                Accéder au planning détaillé
                <ArrowUpRight weight="bold" className={styles.actionArrow} />
              </a>
            </li>
          )}

          {renderElements()}
        </ul>
      </Dropdown>
    </div>
  );
};

export default ProjectActions;
