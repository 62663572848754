import { TimerType } from "timer/types";
import styles from "./AllTimers.module.scss";
import SingleTimer from "../single-timer/SingleTimer";
import {
  formatDateToDay,
  formatFullDate,
  formatSecondsToHours,
} from "utils/formatDate";
import React, { useCallback, useMemo } from "react";
import StackTimers from "../stack-timers/StackTimers";

type Props = {
  allTimers: TimerType[];
  setAllTimers: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const AllTimers = ({ allTimers, setAllTimers }: Props) => {
  // console.log("allTimers");
  // console.log(allTimers);
  const formatTimers = useCallback(() => {
    let formattedTimers: {
      [key: string]: { totalTime: number; timers: TimerType[][] };
    } = {};

    allTimers.forEach((timer) => {
      const mission_ids = timer.mission_ids.sort((a, b) => a - b);
      const stack_id = `${timer.name}-${timer.sub_task || ""}-${
        timer.customer_id
      }-${timer.project_id}-${mission_ids.sort().join("")}`;
      timer.stack_id = stack_id;
      const formatDate = formatDateToDay(timer.start_at);
      if (
        formattedTimers[formatDate]?.timers.some((timerArray) =>
          timerArray.some((t) => t.stack_id === stack_id)
        )
      ) {
        formattedTimers[formatDate].timers.forEach((timerArray) => {
          if (timerArray.some((t) => t.stack_id === stack_id)) {
            formattedTimers[formatDate].totalTime += timer.duration;
            timerArray.push(timer);
          }
        });
      } else {
        formattedTimers[formatDate] = {
          totalTime:
            (formattedTimers[formatDate]?.totalTime || 0) + timer.duration,
          timers: [...(formattedTimers[formatDate]?.timers || []), [timer]],
        };
      }
    });

    return formattedTimers;
  }, [allTimers]);

  const formattedTimers = useMemo(() => formatTimers(), [formatTimers]);

  return (
    <div className={styles.container}>
      {Object.entries(formattedTimers).map(([date, data]) => {
        return (
          <div key={date} className={styles.day}>
            <div className={styles.dayInfo}>
              <h3 className={styles.dayTitle}>{formatFullDate(date)}</h3>
              <p className={styles.dayTotalTime}>
                {formatSecondsToHours(data.totalTime)}
              </p>
            </div>
            {data.timers.map((timers) => {
              if (timers.length === 1) {
                return (
                  <div className={styles.timerContainer} key={timers[0].id}>
                    <SingleTimer
                      timer={timers[0]}
                      setAllTimers={setAllTimers}
                    />
                  </div>
                );
              } else {
                return (
                  <div className={styles.timerContainer} key={timers[0].id}>
                    <StackTimers
                      timers={timers}
                      timer={timers[0]}
                      setAllTimers={setAllTimers}
                    />
                  </div>
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(AllTimers);
