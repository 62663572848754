import styles from "./CardStory.module.scss";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import { Play, Stop, User } from "@phosphor-icons/react";
import { StoryType } from "story/types";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import SidePopup from "ui/components/side-popup/SidePopup";
import CreateStory from "../create-story/CreateStory";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { areArraysEqual } from "utils/checkArrays";
import { TeamUserLink } from "users/types";
import useLocalStorage from "hooks/useLocalStorage";

type Props = {
  story: StoryType;
  showPlay?: boolean;
  sprint_id?: number;
  provided?: any;
  canEdit?: boolean;
  type?: "todo" | "doing" | "review" | "done";
  typeCard?: string | null;
};

const CardStory = ({
  story,
  showPlay = true,
  sprint_id,
  canEdit = true,
  provided,
  type = "todo",
  typeCard,
}: Props) => {
  const dispatch = useDispatch();
  // const [tagMissionHidden] = useLocalStorage("tagMissionHidden");
  const [open, setOpen] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const { currentTimer, setCurrentTimer, setTimerOpen, triggerTimer } =
    useContext(CurrentTimerContext);

  const handleOpen = () => {
    setDataLoaded(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createTimer = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setTimerOpen(true);
      if (currentTimer?.end_at === null && currentTimer?.id) {
        setTimerOpen(true);
        const modalData = {
          status: true,
          message: `Vous avez déjà un timer en cours`,
          error: true,
        };
        dispatch(showModal(modalData));
      } else {
        const currentTimerData = {
          customer: story.customer,
          customer_id: story.customer?.id,
          project_id: story.project?.id,
          project: story.project,
          missions: story.missions,
          mission_ids: story.missions?.map((mission) => mission.id) || [],
          name: `#${story.id}: ${story.name}`,
          start_at: "",
          story_id: story.id,
        };

        setCurrentTimer(currentTimerData);
        await triggerTimer(currentTimerData);
      }
    },
    [
      setTimerOpen,
      currentTimer?.end_at,
      currentTimer?.id,
      dispatch,
      story.customer,
      story.project,
      story.missions,
      story.name,
      setCurrentTimer,
      triggerTimer,
      story.id,
    ]
  );

  const finishTimer = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (!currentTimer?.id) return;
      await triggerTimer(currentTimer);
    },
    [currentTimer, triggerTimer]
  );

  const renderProjectTags = useMemo(() => {
    return story.missions?.map((mission) => (
      <li key={mission.id}>
        <ProjectTag
          content={mission.name}
          contentColor={mission.color}
          mainTimer={false}
        />
      </li>
    ));
  }, [story]);

  const renderUserPicture = useMemo(() => {
    if (!story.teamUserLink) return null;

    const zIndex = story.storyTasks.length + 1; // Calculate z-index
    if (story.teamUserLink.media) {
      return (
        <div className={styles.picture} style={{ zIndex }}>
          <img
            src={`${process.env.REACT_APP_API}/medias/profil/images/${story.teamUserLink.media.url_id}-96x96.jpg`}
            alt=""
          />
        </div>
      );
    } else {
      return (
        <div className={styles.profilSvg} style={{ zIndex }}>
          <User />
        </div>
      );
    }
  }, [story]);

  const renderStoryTasksPicture = useMemo(() => {
    if (story.storyTasks.length === 0) return null;

    const users = story.storyTasks.map((task) => task.teamUserLink);

    const usersWithoutDuplicates = users.reduce((acc: TeamUserLink[], curr) => {
      const isDuplicate = acc.some((user) => user.id === curr?.id);
      if (!isDuplicate && curr) {
        acc.push(curr);
      }
      return acc;
    }, []);

    const ownerIndex = usersWithoutDuplicates.findIndex(
      (user) => user?.id === story.teamUserLink?.id
    );

    if (ownerIndex > -1) {
      usersWithoutDuplicates.splice(ownerIndex, 1);
    }

    const maxZIndex = usersWithoutDuplicates.length;

    return usersWithoutDuplicates.map((teamUserLink, index) => {
      if (!teamUserLink) return null;
      const zIndex = maxZIndex - +index;
      const style = {
        zIndex,
      };
      if (teamUserLink.media) {
        return (
          <div
            className={styles.pictureGrey}
            style={style}
            key={teamUserLink.id}
          >
            <img
              src={`${process.env.REACT_APP_API}/medias/profil/images/${teamUserLink.media.url_id}-96x96.jpg`}
              alt=""
            />
          </div>
        );
      } else {
        return (
          <div className={styles.profilSvg} style={style} key={teamUserLink.id}>
            <User />
          </div>
        );
      }
    });
  }, [story.storyTasks, story.teamUserLink]);

  const divAttributes = provided
    ? {
        ...provided.draggableProps,
        ...provided.dragHandleProps,
        ref: provided.innerRef,
      }
    : {};

  const totalSubtasks = story.storyTasks.length;
  const totalDoneSubtasks = story.storyTasks.filter(
    (task) => task.type === "done"
  ).length;

  const renderPlay = useMemo(() => {
    let checkMissionsArray = true;
    if (currentTimer?.mission_ids) {
      if (!story.mission_ids && currentTimer.mission_ids.length > 0) {
        checkMissionsArray = false;
      } else {
        checkMissionsArray = areArraysEqual(
          currentTimer.mission_ids,
          story.mission_ids || []
        );
      }
    }

    if (
      showPlay &&
      currentTimer &&
      (currentTimer.name === story.name ||
        currentTimer.name === `#${story.id}: ${story.name}`) &&
      checkMissionsArray
    ) {
      if (
        (currentTimer.project_id === null && story.project_id === null) ||
        currentTimer.project_id === Number(story.project_id)
      ) {
        if (
          (currentTimer.customer_id === null && story.customer_id === null) ||
          currentTimer.customer_id === Number(story.customer_id)
        ) {
          return (
            <button className={`${styles.pause}`} onClick={finishTimer}>
              <span className="sr-only">Arrêter le timer</span>
              <Stop weight="fill" />
            </button>
          );
        }
      }
    }
    if (showPlay && !currentTimer?.id) {
      return (
        <button className={styles.play} onClick={createTimer}>
          <span className="sr-only">Démarrer le timer</span>
          <Play weight="fill" />
        </button>
      );
    }
  }, [createTimer, currentTimer, finishTimer, showPlay, story]);

  return (
    <>
      <li className={styles.hover}>
        <div className={styles.dragContainer} {...divAttributes}>
          <div
            className={styles.container}
            onClick={handleOpen}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleOpen();
              }
            }}
          >
            <p className={styles.title}>{story.name}</p>
            <ul className={styles.tags}>
              {story.project && story.customer ? (
                <li>
                  <ProjectTag
                    content={story.customer.name}
                    contentColor={story.customer.color}
                    project={story.project.name}
                    mainTimer={false}
                  />
                </li>
              ) : null}
              {story.missions?.length ? renderProjectTags : null}
            </ul>
            <div className={styles.footer}>
              <div className={styles.pictures}>
                {renderUserPicture}
                {renderStoryTasksPicture}
              </div>
              {!!story.points && (
                <div className={styles.points}>{story.points}</div>
              )}
              {story.points === 0 && <div className={styles.points}>Bug</div>}
              {totalSubtasks && totalSubtasks > 0 ? (
                <div className={styles.tasks}>
                  {totalDoneSubtasks}/{totalSubtasks}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {renderPlay}
        </div>
        {canEdit && open && typeof sprint_id === "number" && (
          <SidePopup setIsOpen={setOpen} dataLoaded={dataLoaded} modal={true}>
            <CreateStory
              modal={true}
              story_id={story.id}
              sprint_id={sprint_id}
              type={type}
              closePopup={handleClose}
              isEdit={canEdit}
              setDataLoaded={setDataLoaded}
            />
          </SidePopup>
        )}
      </li>
    </>
  );
};

export default CardStory;
