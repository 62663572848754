import styles from "./Menu.module.scss";
import { destroyCookie } from "nookies";
import { Timer } from "@phosphor-icons/react";
import { useLocation, Link, useNavigate } from "react-router-dom";

import PlanificationIcon from "ui/icons/PlanificationIcon";
import SprintIcon from "ui/icons/SprintIcon";
import PlanningIcon from "ui/icons/PlanningIcon";
import ClientIcon from "ui/icons/ClientIcon";
import StatistiquesIcon from "ui/icons/StatistiquesIcon";
import ReglagesIcon from "ui/icons/ReglagesIcon";
import ProfilIcon from "ui/icons/ProfilIcon";
import DeconnexionIcon from "ui/icons/DeconnexionIcon";
import LogoIcon from "ui/icons/LogoIcon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BurgerIcon from "ui/icons/BurgerIcon";
import useWindowSize from "hooks/useWindowSize";
import useOutsideClick from "hooks/useOutsideClick";

const Menu = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const paths = location.pathname.split("/");
  const currentPath = paths[1];
  const ref = useRef<HTMLDivElement | null>(null);

  function logout(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    destroyCookie(null, "token", { path: "/" });
    navigate("/connexion", { replace: true });
  }

  const handleSubmenu = (e: any) => {
    e.stopPropagation();
    console.log(openMenu);
    setOpenMenu(!openMenu);
  };

  const onClickOutside = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const menuPopup = useRef(null);

  useOutsideClick(menuPopup, onClickOutside);

  const renderMobileSubmenu = useMemo(() => {
    if (!openMenu) return null;
    return (
      <div className={styles.openMobileSubmenu}>
        <div className={styles.submenuItem}>
          <Link
            to="/reglages"
            className={`${styles.icon} ${
              currentPath === "reglages" && styles.active
            }`}
          >
            <>
              <ReglagesIcon />
              <span>Réglages</span>
            </>
          </Link>
        </div>
        <div className={styles.submenuItem}>
          <Link
            to="/profil"
            className={`${styles.icon} ${
              currentPath === "profil" && styles.active
            }`}
          >
            <>
              <ProfilIcon />
              <span>Mon profil</span>
            </>
          </Link>
        </div>
        <div className={styles.submenuItem}>
          <button onClick={logout} className={`${styles.icon}`}>
            <DeconnexionIcon />
            <span>Me déconnecter</span>
          </button>
        </div>
      </div>
    );
  }, [openMenu, ref]);

  if (currentPath === "connexion" || currentPath === "rapport") return null;
  return (
    <>
      <div className={styles.container} ref={menuPopup}>
        <button className={styles.logo}>
          <LogoIcon />
        </button>
        <div className={styles.menuItem}>
          <Link
            to="/planification"
            className={`${styles.icon} ${
              currentPath === "planification" && styles.active
            }`}
          >
            <>
              <span className="sr-only">Planification</span>
              <PlanificationIcon />
            </>
          </Link>
          <div className={styles.toolTip}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 14"
            >
              <path
                d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                fill="#000"
              />
            </svg>
            <span>Planification</span>
          </div>
        </div>
        <div className={styles.menuItem}>
          <Link
            to="/sprint"
            className={`${styles.icon} ${
              currentPath === "sprint" && styles.active
            }`}
          >
            <>
              <span className="sr-only">Mon sprint</span>
              <SprintIcon />
            </>
          </Link>
          <div className={styles.toolTip}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 14"
            >
              <path
                d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                fill="#000"
              />
            </svg>
            <span>Mon sprint</span>
          </div>
        </div>
        <div className={styles.menuItem}>
          <Link
            to="/planning"
            className={`${styles.icon} ${
              currentPath === "planning" && styles.active
            }`}
          >
            <>
              <span className="sr-only">Planning</span>
              <PlanningIcon />
            </>
          </Link>
          <div className={styles.toolTip}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 14"
            >
              <path
                d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                fill="#000"
              />
            </svg>
            <span>Planning</span>
          </div>
        </div>
        <div className={styles.menuItem}>
          <Link
            to="/clients"
            className={`${styles.icon} ${
              currentPath === "clients" && styles.active
            }`}
          >
            <>
              <span className="sr-only">Clients et projets</span>
              <ClientIcon />
            </>
          </Link>
          <div className={styles.toolTip}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 14"
            >
              <path
                d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                fill="#000"
              />
            </svg>
            <span>Clients et projets</span>
          </div>
        </div>
        <div className={styles.menuItem}>
          <Link
            to="/timer"
            className={`${styles.icon} ${
              currentPath === "timer" && styles.active
            }`}
          >
            <>
              <span className="sr-only">Timer</span>
              <Timer weight="fill" />
            </>
          </Link>
          <div className={styles.toolTip}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 14"
            >
              <path
                d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                fill="#000"
              />
            </svg>
            <span>Timer</span>
          </div>
        </div>
        <div className={styles.menuItem}>
          <Link
            to="/statistiques"
            className={`${styles.icon} ${
              currentPath === "statistiques" && styles.active
            }`}
          >
            <>
              <span className="sr-only">Statistiques</span>
              <StatistiquesIcon />
            </>
          </Link>
          <div className={styles.toolTip}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 14"
            >
              <path
                d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                fill="#000"
              />
            </svg>
            <span>Statistiques</span>
          </div>
        </div>
        <div className={styles.menuItemMobileButton}>
          <button type="button" onClick={handleSubmenu} className={styles.icon}>
            <span className="sr-only">Plus</span>
            <BurgerIcon />
          </button>
          <div className={styles.toolTip}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 14"
            >
              <path
                d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                fill="#000"
              />
            </svg>
            <span>Plus</span>
          </div>
        </div>
        {width && width < 1024 ? (
          <div>{renderMobileSubmenu}</div>
        ) : (
          <>
            <div className={styles.menuItemFar}>
              <Link
                to="/reglages"
                className={`${styles.icon} ${
                  currentPath === "reglages" && styles.active
                }`}
              >
                <>
                  <span className="sr-only">Réglages</span>
                  <ReglagesIcon />
                </>
              </Link>
              <div className={styles.toolTip}>
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 8 14"
                >
                  <path
                    d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                    fill="#000"
                  />
                </svg>
                <span>Réglages</span>
              </div>
            </div>
            <div className={styles.menuItem}>
              <Link
                to="/profil"
                className={`${styles.icon} ${
                  currentPath === "profil" && styles.active
                }`}
              >
                <>
                  <span className="sr-only">Mon profil</span>
                  <ProfilIcon />
                </>
              </Link>
              <div className={styles.toolTip}>
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 8 14"
                >
                  <path
                    d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                    fill="#000"
                  />
                </svg>
                <span>Mon profil</span>
              </div>
            </div>
            <div className={styles.menuItem}>
              <button onClick={logout} className={`${styles.icon}`}>
                <span className="sr-only">Me déconnecter</span>
                <DeconnexionIcon />
              </button>
              <div className={styles.toolTip}>
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 8 14"
                >
                  <path
                    d="M1.133 9.36 8 14V0L1.133 4.64c-.332.177-.619.498-.822.92A3.36 3.36 0 0 0 0 7c0 .515.109 1.018.311 1.44.203.422.49.743.822.92Z"
                    fill="#000"
                  />
                </svg>
                <span>Me déconnecter</span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Menu;
