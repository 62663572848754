import { handleDeleteMission } from "clients/controllers/missions";
import { Mission } from "clients/interfaces/mission";
import { Trash, X } from "@phosphor-icons/react";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { resetHandleStatusModal } from "app/reducers/generalModal";
import { useState } from "react";
import LoadingIcon from "ui/icons/LoadingIcon";
import { set } from "react-hook-form";

type Props = {
  data: Mission;
  setEditMissions: (value: boolean) => void;
};

const DeleteMissionPopup = ({ data, setEditMissions }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deleteMission = () => {
    setLoading(true);
    handleDeleteMission(data.id)
      .then(() => {
        setEditMissions(true);
        dispatch(resetHandleStatusModal());
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  };

  return (
    <>
      <p className="m-popup__content__text-grey">
        Confirmez-vous la suppression de la mission de ce projet ? Cette action
        est irréversible.
      </p>
      <button onClick={deleteMission} className="m-button m-button--black">
        <Trash weight="fill" />
        {loading ? <LoadingIcon isWhite={true} /> : " Supprimer la mission"}
      </button>
    </>
  );
};

export default DeleteMissionPopup;
