import { Customer } from "clients/interfaces/customer";
import styles from "./AllProjectStory.module.scss";
import { handleGetAllProjects } from "clients/controllers/projects";
import { Project } from "clients/interfaces/project";
import useDebounce from "hooks/useDebounce";
import { ArrowLeft, MagnifyingGlass, X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { StoryType } from "story/types";
import LoadingIcon from "ui/icons/LoadingIcon";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  selectedCustomer: Customer;
  close: () => void;
  setSelectedCustomer: (customer: Customer | null) => void;
  setStory: (story: StoryType) => void;
  story: StoryType;
};

const AllProjectStory = ({
  selectedCustomer,
  close,
  setSelectedCustomer,
  setStory,
  story,
}: Props) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState<Project[]>([]);
  const [searchProjectValue, setSearchProjectValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSearchProjectValue = useDebounce(searchProjectValue, 300);

  const resetSelectedCustomer = () => {
    setSelectedCustomer(null);
  };

  const searchProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProjectValue(e.target.value);
  };

  const selectProject = (project: Project) => {
    setStory({
      ...story,
      project: project,
      customer: selectedCustomer,
    });
    close();
  };

  useEffect(() => {
    setLoading(true);
    if (!selectedCustomer) return () => setLoading(false);
    if (debouncedSearchProjectValue) {
      const filter = {
        search: debouncedSearchProjectValue,
        customerId: selectedCustomer.id,
      };
      handleGetAllProjects(filter)
        .then((res) => {
          setProjects(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          setLoading(false);
        });
    } else {
      const filter = {
        customerId: selectedCustomer.id,
      };
      handleGetAllProjects(filter)
        .then((res) => {
          setProjects(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          setLoading(false);
        });
    }
  }, [debouncedSearchProjectValue, selectedCustomer, dispatch]);

  return (
    <>
      <div className={styles.header}>
        <button
          className={`m-button m-button--grey m-button--rounded ${styles.back}`}
          onClick={resetSelectedCustomer}
        >
          <ArrowLeft weight="bold" />
          <span className="sr-only">Revenir aux clients</span>
        </button>
        <p className={styles.title}>Projets de {selectedCustomer.name}</p>
        <button
          className={`m-button m-button--grey m-button--rounded ${styles.close}`}
          onClick={close}
          type="button"
        >
          <span className="sr-only">Fermer l'ajout de client</span>
          <X weight="bold" />
        </button>
      </div>
      <div className={styles.box}>
        <div className="m-search m-search--white">
          <input
            type={"text"}
            placeholder="Rechercher un projet"
            onChange={searchProject}
            value={searchProjectValue}
          />
          <MagnifyingGlass weight="bold" />
        </div>
        {loading ? (
          <div className={styles.loading}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {projects.length > 0 ? (
              <ul className={styles.customers}>
                {projects
                  .filter((project: Project) => project.active == true)
                  .map((project: Project) => (
                    <li key={project.id} className={styles.customer}>
                      <button
                        type="button"
                        key={project.id}
                        className="m-button m-button--black"
                        onClick={() => selectProject(project)}
                      >
                        <p>{project.name}</p>
                      </button>
                    </li>
                  ))}
              </ul>
            ) : (
              <p className={styles.noProject}>Aucun projet</p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AllProjectStory;
