import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import styles from "./CreateStory.module.scss";
import StepSidePopup from "ui/components/step-side-popup/StepSidePopup";
import CustomerStory from "../customer-story/CustomerStory";
import { CreateStoryType, StoryTask, StoryType } from "story/types";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import MissionsStory from "../mission-story/MissionsStory";
import { Mission } from "clients/interfaces/mission";
import { Plus, X } from "@phosphor-icons/react";
import UserStory from "../user-story/UserStory";
import UserStorySingle from "../user-story/user-story-single/UserStorySingle";
import FileStory from "../file-story/FileStory";
import {
  handleCreateMediaStoryLink,
  handleCreateStory,
  handleGetStoryById,
  handleUpdateStory,
} from "story/controllers/story";
import { handleCreateMedia } from "app/controllers/media";
import CreateStoryOptions from "../create-story-options/CreateStoryOptions";
import TasksStory from "../tasks-story/TasksStory";
import { generateRandom } from "utils/generateRandom";
import LoadingIcon from "ui/icons/LoadingIcon";
import HTMLTextArea from "app/components/html-textarea/HtmlTextarea";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import MissionProjectStory from "../mission-project-story/MissionProjectStory";
import { Asterisk, Bug } from "phosphor-react";
import ProjectTagStory from "ui/components/project-tag/ProjectTagStory";
import { useSelector } from "react-redux";
import { selectTypeCardState } from "sprint/selector";

type Props = {
  story_id?: number;
  sprint_id: number;
  type: "todo" | "doing" | "review" | "done";
  closePopup: () => void;
  newOrder?: number;
  isEdit?: boolean;
  setDataLoaded?: (value: boolean) => void;
  modal?: boolean | null;
  setCreatedStory?: (value: boolean) => void;
};

const CreateStory = ({
  sprint_id,
  type,
  closePopup,
  story_id,
  newOrder,
  modal = false,
  isEdit = false,
  setDataLoaded,
  setCreatedStory,
}: Props) => {
  const dispatch = useDispatch();
  const card = useSelector(selectTypeCardState);

  const kipdevRef = useRef<HTMLTextAreaElement>(null);
  const noteRef = useRef<HTMLTextAreaElement>(null);
  const pointRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [story, setStory] = useState<StoryType>({} as StoryType);
  const [currentMission, setCurrentMission] = useState<Mission | null>(null);
  const [step, setStep] = useState<string>("");
  const [tasksToDelete, setTasksToDelete] = useState<number[]>([]);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [errorStory, setErrorStory] = useState(false);
  const [loading, setLoading] = useState<boolean>(typeof story_id === "number");

  let order = 0;

  if (newOrder) {
    order = newOrder;
  }

  const changeStep = (step: string) => {
    setStep(step);
  };

  const closeStep = () => {
    setStep("");
  };

  const handleAddTask = () => {
    const newTask = {
      name: "",
      type: "todo",
      order: story.storyTasks ? story.storyTasks.length : 0,
      temp_id: generateRandom(1, 100000),
    } as StoryTask;
    if (!story.storyTasks || story.storyTasks.length === 0) {
      setStory({ ...story, storyTasks: [newTask] });
    } else {
      setStory({ ...story, storyTasks: [...story.storyTasks, newTask] });
    }
  };

  const onChangeComplexityForBug = (value: any) => {
    if (!value) {
      setTimeout(() => {
        pointRef.current?.focus();
      }, 100);
    }
    setStory({ ...story, points: value });
  };

  const onChangeComplexity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "0") {
      setStory({ ...story, points: null });
      return;
    }
    if (e.target.value === "") {
      setStory({ ...story, points: null });
      return;
    }
    setStory({ ...story, points: Number(e.target.value) });
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setStory({ ...story, name: value });
  };

  const handleNoteTextareaChange = (text: string) => {
    setStory({ ...story, note: text });
  };

  useEffect(() => {
    resizeTextarea();
  }, [story.name]);

  useEffect(() => {
    resizeNoteTextarea();
  }, [story.note]);

  useEffect(() => {
    if (errorStory && isSubmitClicked)
      var errorTimer = setTimeout(() => {
        setErrorStory(false);
        setIsSubmitClicked(false);
      }, 3000);

    return () => clearTimeout(errorTimer);
  }, [errorStory, isSubmitClicked]);

  const resizeTextarea = () => {
    const textArea = kipdevRef.current;
    if (!textArea) return;
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  };

  const resizeNoteTextarea = () => {
    const textArea = noteRef.current;
    if (!textArea) return;
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  };

  const cleanStoryTasks = (storyTasks: StoryTask[]) => {
    return storyTasks.map((storyTask) => {
      const data = {
        ...storyTask,
      };

      delete data.teamUserLink;
      delete data.temp_id;

      return data;
    });
  };

  const createStory = (createStoryData: CreateStoryType) => {
    const storyTasks = cleanStoryTasks(createStoryData.storyTasks || []);
    createStoryData.storyTasks = storyTasks;
    if (!createStoryData.customer_id || !createStoryData.mission_ids) {
      return;
    }
    handleCreateStory(createStoryData)
      .then((res) => {
        if (setCreatedStory) {
          setCreatedStory(true);
        }
        const media_ids = story.medias?.map((media) => {
          return handleCreateMedia(media).then((res) => {
            return res.data.id;
          });
        });

        if (media_ids && media_ids.length > 0) {
          Promise.all(media_ids).then((resolvedMediaIds) => {
            resolvedMediaIds?.forEach((media_id) => {
              const data = {
                media_id,
                story_id: res.data.id,
              };
              handleCreateMediaStoryLink(data)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                  const toastData = {
                    status: true,
                    message:
                      errorsAPI[
                        err.response.data.message as keyof typeof errorsAPI
                      ],
                    error: true,
                  };
                  dispatch(showModal(toastData));
                });
            });
          });
        }
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  };

  const updateStory = (id: number, createStoryData: CreateStoryType) => {
    const storyTasks = cleanStoryTasks(createStoryData.storyTasks || []);
    createStoryData.storyTasks = storyTasks;
    if (!createStoryData.customer_id || !createStoryData.mission_ids) {
      return;
    }

    handleUpdateStory(id, createStoryData)
      .then((res) => {
        const media_ids: any[] = [];
        story.medias?.forEach((media) => {
          if (!media.id) {
            media_ids.push(
              handleCreateMedia(media).then((res) => {
                return res.data.id;
              })
            );
          }
        });

        if (media_ids && media_ids.length > 0) {
          Promise.all(media_ids).then((resolvedMediaIds) => {
            resolvedMediaIds?.forEach((media_id) => {
              const data = {
                media_id,
                story_id: id,
              };
              handleCreateMediaStoryLink(data)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  const toastData = {
                    status: true,
                    message:
                      errorsAPI[
                        err.response.data.message as keyof typeof errorsAPI
                      ],
                    error: true,
                  };
                  dispatch(showModal(toastData));
                  console.log(err);
                });
            });
          });
        }
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitClicked(true);
    if (!story.customer || (story.missions && story.missions.length === 0)) {
      setErrorStory(true);
    } else {
      const mission_ids = story.missions?.map((mission) => mission.id);
      const createStoryData: CreateStoryType = {
        name: story.name,
        project_id: story.project?.id,
        customer_id: story.customer?.id,
        sprint_id: sprint_id,
        type: type,
        mission_ids,
        user_id: story?.teamUserLink?.user_id,
        note: story.note,
        storyTasks: story.storyTasks,
      };
      if (story.points || story.points === 0) {
        createStoryData.points = story.points;
      }
      if (!createStoryData.customer_id || !createStoryData.mission_ids) {
        return;
      }
      if (!story_id) {
        createStory({ ...createStoryData, order });
      } else {
        updateStory(story.id, {
          ...createStoryData,
          tasksToDelete: tasksToDelete,
        });
      }

      closePopup();
    }
  };

  const renderError = useMemo(() => {
    if (!isSubmitClicked && !errorStory) return null;
    return (
      <div className={styles.errorMessage}>
        <div>Oups, il manque le client ou la mission</div>
      </div>
    );
  }, [errorStory, isSubmitClicked]);

  const handleDeleteMission = useCallback(
    (missionId: number) => {
      console.log("coucou");

      let storyClone: StoryType = { ...story };

      storyClone.missions =
        storyClone.missions &&
        storyClone.missions.filter((x) => x.id !== missionId);
      storyClone.mission_ids =
        storyClone.mission_ids &&
        storyClone.mission_ids.filter((x) => x !== missionId);

      setStory(storyClone);
    },
    [story, setStory]
  );

  const renderMissionsTags = useMemo(() => {
    if (story.missions && story.missions.length > 0) {
      return (
        <>
          {story.missions.map((mission: any) => {
            return (
              <div
                onClick={() => {
                  changeStep("mission");
                  setCurrentMission(mission);
                }}
                key={mission.id}
              >
                <ProjectTagStory
                  onDelete={() => handleDeleteMission(mission.id)}
                  content={mission.name}
                  contentColor={mission.color}
                  size="large"
                  mainTimer={false}
                />
              </div>
            );
          })}
          <button
            type="button"
            className={styles.addMission}
            onClick={() => {
              changeStep("mission");
            }}
          >
            <span className="sr-only">Ajouter une mission à la story</span>
            <Plus />
          </button>
        </>
      );
    } else {
      return (
        <button
          type="button"
          onClick={() => changeStep("mission")}
          className={styles.info}
        >
          Mission
        </button>
      );
    }
  }, [story, handleDeleteMission]);

  useEffect(() => {
    if (!story_id) return;
    handleGetStoryById(story_id)
      .then((res) => {
        setStory(res.data);
        // console.log(res.data);
        setLoading(false);
        if (setDataLoaded) {
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [setDataLoaded, story_id, dispatch]);

  const handleClose = () => {
    closePopup();
  };

  return (
    <div className={card === "modal" && modal ? styles.modal : styles.wrapper}>
      {loading ? (
        <div className={styles.loading}>
          <LoadingIcon />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className={styles.form} ref={formRef}>
          <div className={styles.head}>
            {story && isEdit && (
              <CreateStoryOptions
                storyData={story}
                formRef={formRef}
                closePopup={closePopup}
                card={card}
              />
            )}
            <div className={styles.head__display}>
              <textarea
                placeholder="Entrez le nom de la story"
                name="name"
                data-cy="name-customer"
                className={styles.name}
                onChange={handleTextareaChange}
                value={story.name || ""}
                rows={1}
                ref={kipdevRef}
                required
              ></textarea>
              <div className={styles.displayClose}>
                {isEdit ? null : (
                  <button
                    className="m-button m-button--grey m-button"
                    type="button"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Fermer l'ajout de mission</span>
                    <X weight="bold" />
                  </button>
                )}
              </div>
            </div>

            <div className={styles.infoRow}>
              {story.project && story.customer ? (
                <button onClick={() => changeStep("customer")} type="button">
                  <ProjectTag
                    content={story.customer.name}
                    contentColor={story.customer.color}
                    project={story.project.name}
                    size="large"
                    mainTimer={false}
                  />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => changeStep("customer")}
                  className={styles.info}
                >
                  Client et projet
                </button>
              )}
              {step === "customer" && (
                <StepSidePopup>
                  <CustomerStory
                    setStory={setStory}
                    close={closeStep}
                    story={story}
                  />
                </StepSidePopup>
              )}
              {renderMissionsTags}
              {step === "mission" && (
                <StepSidePopup>
                  <MissionsStory
                    setStory={setStory}
                    close={closeStep}
                    story={story}
                    currentMission={currentMission}
                    setCurrentMission={setCurrentMission}
                  />
                </StepSidePopup>
              )}
            </div>
            <div className={styles.infoRow}>
              {story.teamUserLink ? (
                <button
                  type="button"
                  onClick={() => changeStep("user")}
                  className={styles.user}
                >
                  <UserStorySingle
                    grey={true}
                    teamUserLink={story.teamUserLink}
                  />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => changeStep("user")}
                  className={styles.info}
                >
                  Responsable
                </button>
              )}
              {step === "user" && (
                <StepSidePopup>
                  <UserStory
                    setStory={setStory}
                    close={closeStep}
                    story={story}
                  />
                </StepSidePopup>
              )}
              <div className={styles.complexity}>
                {story.points !== 0 && (
                  <>
                    <button
                      onClick={() => onChangeComplexityForBug(0)}
                      type="button"
                    >
                      <Asterisk weight="bold" />
                    </button>

                    <input
                      ref={pointRef}
                      type="number"
                      placeholder="Complexité"
                      value={story.points ? story.points : ""}
                      onChange={onChangeComplexity}
                      style={
                        story.points
                          ? {
                              width: `calc(${
                                story.points.toString().length
                              } * 1ch)`,
                              textAlign: "center",
                            }
                          : {}
                      }
                    />
                  </>
                )}
                {story.points === 0 && (
                  <>
                    <button
                      onClick={() => onChangeComplexityForBug(null)}
                      type="button"
                    >
                      <Bug weight="bold" />
                    </button>
                    <p>Bug</p>
                  </>
                )}
              </div>
            </div>
          </div>
          {story.project_id && (
            <ul className={styles.missions}>
              {story.missionsProjectsLink?.map((missionProjectLink) => (
                <li key={missionProjectLink.id}>
                  <MissionProjectStory
                    customerId={story.customer_id}
                    missionProjectLink={missionProjectLink}
                  />
                </li>
              ))}
            </ul>
          )}
          <div className={styles.tasks}>
            <div className={styles.tasksTitle}>
              <p className={styles.subtitle}>Tâches</p>
            </div>
            {story.storyTasks ? (
              <TasksStory
                setStory={setStory}
                story={story}
                setTasksToDelete={setTasksToDelete}
                tasksToDelete={tasksToDelete}
                closePopup={closePopup}
                editing={story_id ? true : false}
              />
            ) : null}
            <button
              className={styles.addTask}
              type="button"
              onClick={handleAddTask}
            >
              <Plus weight="bold" />
              Ajouter une tâche
            </button>
          </div>
          <div className={styles.notesBox}>
            <p className={styles.subtitle}>Notes</p>
            <HTMLTextArea
              text={story.note || ""}
              setText={handleNoteTextareaChange}
            />
          </div>
          <p className={styles.subtitle}>Fichiers</p>
          <FileStory story={story} setStory={setStory} />
          <div className={story_id ? styles.bottom : styles.bottomCreate}>
            {renderError}
            <div className={styles.submit}>
              <button
                className="m-button m-button--black m-button--full"
                type="submit"
              >
                {loading ? (
                  <LoadingIcon />
                ) : (
                  `${story_id ? "Mettre à jour la story" : "Créer la story"}`
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateStory;
