import styles from "./Log.module.scss";
import { ArrowUpRight, PencilSimple, Trash, X } from "@phosphor-icons/react";
import { useCallback, useEffect, useState } from "react";
import SidePopup from "ui/components/side-popup/SidePopup";
import AddLog from "../add-log/AddLog";
import { AccountInterface } from "clients/interfaces/account";
import { useDispatch, useSelector } from "react-redux";
import Popup from "ui/components/popup/Popup";
import { useParams } from "react-router-dom";
import { handleUpdateCustomer } from "clients/controllers/customers";
import { Project } from "clients/interfaces/project";
import {
  resetHandleStatusModal,
  setHandleStatusModal,
} from "app/reducers/generalModal";

type Props = {
  setEditClient: (value: boolean) => void;
  accounts: AccountInterface[];
  accountName: string;
  identifier: string;
  password: string;
  link?: string;
  project_id: any;
  projectsList: Project[];
  onUpdateClient?: Function;
};

const Log = ({
  accounts,
  setEditClient,
  accountName,
  identifier,
  password,
  link,
  project_id,
  projectsList,
  onUpdateClient,
}: Props) => {
  const { id } = useParams();
  const user = useSelector((state: any) => state.user.user);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [projectName, setProjectName] = useState<string | null>(null);
  const [isAllowed, setIsAllowed] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.team_user_link?.role_name === "SUPER_ADMIN") {
      setIsAllowed(true);
    }
    if (user?.team_user_link?.role_name === "GIGA_ADMIN") {
      setIsAllowed(true);
    }
  }, [user]);

  // console.log(projectsList);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
  };

  const account: AccountInterface = {
    account: accountName,
    username: identifier,
    password: password,
    link: link,
    project_id: project_id,
  };

  // const deleteLog = () => {
  //   if (!id) return;
  //   const newAccounts = [
  //     ...accounts.filter(
  //       (account) =>
  //         account.account !== accountName ||
  //         account.password !== password ||
  //         account.username !== identifier ||
  //         account.link !== link ||
  //         account.project_id !== project_id
  //     ),
  //   ];
  //   handleUpdateCustomer(Number(id), {
  //     accounts: newAccounts,
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       if (onUpdateClient) {
  //         onUpdateClient(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Update failed:", error);
  //     });
  //   setOpenDelete(false);
  //   setEditClient(true);
  //   dispatch(resetHandleStatusModal());
  // };

  const deleteLog = useCallback(() => {
    if (!id) return;
    const newAccounts = [
      ...accounts.filter(
        (account) =>
          account.account !== accountName ||
          account.password !== password ||
          account.username !== identifier ||
          account.link !== link ||
          account.project_id !== project_id
      ),
    ];
    handleUpdateCustomer(Number(id), {
      accounts: newAccounts,
    })
      .then((response) => {
        console.log(response);
        if (onUpdateClient) {
          onUpdateClient(response.data);
        }
      })
      .catch((error) => {
        console.error("Update failed:", error);
      });
    setOpenDelete(false);
    setEditClient(true);
    dispatch(resetHandleStatusModal());
  }, [
    id,
    accounts,
    accountName,
    password,
    identifier,
    link,
    project_id,
    onUpdateClient,
    setOpenDelete,
    setEditClient,
    dispatch,
  ]);

  useEffect(() => {
    const findProjectById = (id: number) => {
      const project = projectsList.find((p) => p.id === id);
      return project ? project.name : null;
    };

    const projectName = findProjectById(project_id);
    setProjectName(projectName);
  }, [project_id, projectsList]);

  const delSubTask = useCallback(() => {
    dispatch(
      setHandleStatusModal({
        status: true,
        children: (
          <>
            <p className="m-popup__content__text-grey">
              Confirmez-vous la suppression de la sous-tâche ? Cette action est
              irréversible.
            </p>
            <button onClick={deleteLog} className="m-button m-button--black">
              <Trash weight="fill" />
              Supprimer
            </button>
          </>
        ),
        mW: 800,
        title: "Supprimer la sous tâche",
      })
    );
  }, [deleteLog, dispatch]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.cardRow}>
          <p className={styles.accountName}>{accountName}</p>
          <div className={styles.endButtons}>
            {link ? (
              <a
                href={link}
                className={styles.link}
                target="__blank"
                rel="noopener"
              >
                <ArrowUpRight weight="bold" />
                <span className="sr-only">Lien vers l'application</span>
              </a>
            ) : (
              ""
            )}
            {isAllowed && (
              <button onClick={handleOpenEdit} className={styles.editLink}>
                <PencilSimple weight="fill" />
              </button>
            )}
            {openEdit && (
              <SidePopup setIsOpen={setOpenEdit} dataLoaded={true}>
                <AddLog
                  edit={true}
                  accounts={accounts}
                  closePopup={handleCloseEdit}
                  setEditClient={setEditClient}
                  data={account}
                  projectsList={projectsList}
                />
              </SidePopup>
            )}
            {isAllowed && (
              <button onClick={delSubTask} className={styles.editLink}>
                <Trash weight="fill" />
              </button>
            )}
          </div>
        </div>
        <div className={styles.cardRow}>
          <p className={styles.rowName}>Identifiant</p>
          <p className={styles.identifier}>{identifier}</p>
        </div>
        <div className={styles.cardRow}>
          <p className={styles.rowName}>Mot de passe</p>
          <p className={styles.password}>{password}</p>
        </div>
        <div className={styles.cardRow}>
          <p className={styles.rowName}>Projet</p>
          <p className={styles.project}>
            {projectName !== null ? projectName : "Aucun projet selectionné"}
          </p>
        </div>
      </div>
    </>
  );
};

export default Log;
